<template>
    <div class="stack-container">
        <buttonc v-for="(button, index) in buttonProcessed" :key="button.icon + index"
                 :classes="button.classes" :type="button.type" :icon="button.icon" :styleIcon="button.styleIcon" v-tooltip="GetTooltip(index)" @click="button.click"
                 :disabled="button.disabled" :label="button.label"/>
        <buttonc v-if="showCollapsed" class="list" type="block" icon="fa-question"
                 @click="OpenMenuDetails" v-tooltip="'Hilfe'"/>

    </div>
</template>

<script>
    export default {
        name: "headerbuttonstack",
        data(){
            return {
                helperLinks: [],
                buttons: [],
                helpButtons: [],
                collapsedWidth: 0,
                showListIconAlways: true,
            }
        },
        watch: {
            state: {
                immediate: true,
                handler(newVal, oldVal) {
                    for (let x in newVal) this[x] = newVal[x];
                    let view = this;
                    this.helpButtons = [];
                    this.buttons.forEach(button => {
                        let copy = Object.assign({}, button);
                        copy.disabled = false;
                        if(button.helperText){
                            copy.click = () => {
                                view.$helpers.OpenAlert({header: copy.title ? copy.title : (copy.tooltip ? copy.tooltip : "Hilfe"), description: copy.helperText, disableAccept: true, cancelLabel: "Danke", externalLabel: copy.externalLabel != undefined? copy.externalLabel : 'Mehr erfahren', externalUrl: copy.externalUrl, externalUrl: copy.externalUrl, enableExternal: copy.externalUrl? true : false});
                            }
                            this.helpButtons.push(copy);
                        }

                    });
                    this.helperLinks.forEach(hLink => {
                        let copy = Object.assign({}, hLink);
                        copy.disabled = false;
                        if(hLink.helperText){
                            copy.type = "block";
                            copy.disabled = false;
                            copy.icon = copy.icon ?? "fa-question-circle";
                            copy.click = () => {
                                view.$helpers.OpenAlert({header: copy.title ? copy.title : (copy.tooltip ? copy.tooltip : "Hilfe"), description: copy.helperText, disableAccept: true, cancelLabel: copy.cancelLabel != undefined? copy.cancelLabel : "Danke", externalLabel: copy.externalLabel != undefined? copy.externalLabel : 'Mehr erfahren', externalUrl: copy.externalUrl, enableExternal: copy.externalUrl? true : false});
                            }
                            this.helpButtons.push(copy);
                        }
                    });
                }
            }
        },
        computed: {
            showCollapsed(){
                return this.helpButtons.length > 0 && (this.showListIconAlways || window.innerWidth < this.collapsedWidth);
            },
            buttonProcessed(){
                if(this.collapsedWidth != 0 && window.innerWidth < this.collapsedWidth) return [];
                let buttons = this.buttons.filter(b => !b.hasOwnProperty('show') || b.show);
                return buttons;
            },
        },

        props:{
            state: Object,
        },
        methods: {
            GetTooltip(index){
                let view = this;
                return () => {return view.buttonProcessed[index].tooltip};
            },
            OpenMenuDetails($event){
                let view = this;
                this.$helpers.OpenPopup({
                    type: 'listlabel',
                    componentState: {
                        labels: this.helpButtons.slice(),
                    },
                    listStyle: true,
                    noScroll: true,
                    pointerDirection: 0,
                    elRelative: $event.currentTarget,
                    pointerOffset: -15,
                });
            },
        },

    }
</script>

<style scoped>
    .stack-container{
        white-space: nowrap;
    }
</style>
