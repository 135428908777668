<template>
  <div class="has-header">
    <div class="header main">
      <div class="align-left">
        <buttonc
          v-show="false"
          type="rectangle"
          icon="fa-filter"
          v-tooltip="'Anzeige Optionen'"
          @click="OpenDisplayMenuDetails"
        />
        <dateselectionheader v-if="selectedPage == 'wagedata'" :label="dateDisplay" @PrevDate="modDate('sub')" @NextDate="modDate('add')" />
      </div>
      <div class="align-middle">
        <headerbuttonstack :state="headerButtons"/>
      </div>
      <div class="align-right">
        <headerbuttonstack :state="sideButtons"/>
      </div>
    </div>
    <div v-if="loading" class="loading-md bg">
      <i class="fas fa-spinner"></i>
      <br/>
      <div v-if="!$helpers.isEmpty(loadingText)" class="loading-text">
        {{ loadingText }}
      </div>
    </div>
    <div class="scrollbar-container">
      <b-row v-if="!loading">
        <b-col
          md="auto"
          class="employee-list-col col-no-margin"
          v-if="!loading"
          v-bind:class="{ disabled: creatingNewEmployee }"
        >
          <b-row>
            <!--  v-if="showEmployeeNameFilter"> -->
            <b-col>
              <div class="employee-name-filter">
                <div class="name-filter" style="transition: 0.2s">
                  <i
                    class="fas fa-filter"
                    role="button"
                    @click="OpenDisplayMenuDetails"
                  ></i>
                  <i class="fas fa-search"></i>
                  <input v-model="nameFilter"/>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div
                class="data-grid employee-list"
                v-bind:class="{ withfilter: showEmployeeNameFilter }"
                v-bind:style="{
                  opacity: loading ? '0' : '1',
                  border: employees.length < 10 ? '1px solid #cccccc' : '',
                }"
              >
                <div
                  class="content"
                  v-bind:style="{ display: loading ? 'none' : 'inline-block' }"
                >
                  <grid
                    ref="grid"
                    :headerEnabled="false"
                    :header="gridData.header"
                    :data="dataFiltered"
                    :OnSelect="hidingMultipleEmployees ? SelectEmployeeForMassHide : (unhidingMultipleEmployees ? SelectEmployeeForMassUnhide : ChangeEmployee)"
                    :OnSelectRow="hidingMultipleEmployees ? (e) => {} : (unhidingMultipleEmployees ? (e) => {} : ChangeEmployee)"
                  >
                  </grid>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>

        <b-col
          md="auto"
          class="employee-col"
          v-if="!loading && !creatingNewEmployee"
        >
          <b-row style="margin: 0px 10px 10px 10px">
            <b-col class="col-no-margin employee-active">
              <b-row style="height: 255px">
                <b-col class="col-no-margin">
                  <div>
                    <img
                      style="margin: 4px; width: 120px; height: 120px"
                      crossorigin="anonymous"
                      id="employee-picture-img"
                      :src="GetProfilePictureUrl(employee, 'large', true)"
                    />
                  </div>
                  <div
                    v-bind:class="{ disabled: creatingNewEmployee }"
                    id="employee-picture"
                    class="picture"
                    style="cursor: pointer"
                    v-on:mouseover="showImageUploadCircle = true"
                    v-on:mouseleave="showImageUploadCircle = false"
                    v-bind:style="{
                      background: GetProfilePictureUrl(employee, 'large'),
                      opacity: loading ? '0' : '1',
                    }"
                    v-on:click="UploadImage(false)"
                  >
                    <div
                      class="inner-circle"
                      v-if="
                        !employee.employeePicture_fileId ||
                        showImageUploadCircle
                      "
                      style="cursor: pointer"
                    >
                      <i
                        v-if="!employee.employeePicture"
                        class="fas fa-plus"
                      ></i>
                      <i v-else class="fas fa-edit"></i>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row v-if="employee.isHidden">
                <b-col class="col-no-margin">
                  <div class="">
                    <div
                      class=""
                      style="
                        text-align: center;
                        font-size: 14px;
                        padding-top: 5px;
                        padding-bottom: 5px;
                        font-weight: bold;
                        color: #e51550;
                      "
                    >
                      (MitarbeiterIn ist versteckt)
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="col-no-margin">
                  <div class="firstName">
                    <div
                      v-show="
                        employeeWarnings.warnings.length > 0 &&
                        !creatingNewEmployee
                      "
                      class="alert-icon"
                      v-bind:style="{ background: employeeWarnings.color }"
                      @click="
                        selectedPage = $helpers.isEmpty(employeeWarnings.link)
                          ? selectedPage
                          : employeeWarnings.link
                      "
                    >
                      <i class="fas fa-exclamation" style="color: #fff"/>
                      <div class="tooltiptext">
                        {{ employeeWarningsTooltipText() }}
                      </div>
                    </div>
                    <div class="firstNameLabel">{{ employee.firstName }}</div>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="col-no-margin">
                  <div class="lastName">
                    <div class="lastNameLabel">{{ employee.lastName }}</div>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="col-no-margin">
                  <b-row class="split-flat">
                    <b-col>&nbsp;</b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="col-no-margin">
                  <b-row class="split-flat">
                    <b-col>&nbsp;</b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row v-if="showDetails">
                <b-col>
                  <b-row>
                    <b-col class="col-no-margin">
                      <div class="full">
                        <div class="third left">
                          <div class="employee-info label">Geburtsdatum</div>
                        </div>
                        <div class="twothirds right">
                          <div class="employee-info field">
                            {{ dateOfBirth }}
                          </div>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row class="split-flat">
                    <b-col style="padding-top: 4px"></b-col>
                  </b-row>
                  <b-row v-if="!$helpers.isEmpty(employee.email)">
                    <b-col class="col-no-margin">
                      <div class="full">
                        <div class="third left">
                          <div class="employee-info label">E-Mail</div>
                        </div>
                        <div class="twothirds right">
                          <div class="employee-info field">
                            {{ employee.email }}
                          </div>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row v-if="!$helpers.isEmpty(employee.id)">
                    <b-col class="col-no-margin">
                      <div class="full">
                        <div class="third left">
                          <div class="employee-info label">Interne ID</div>
                        </div>
                        <div class="twothirds right">
                          <div class="employee-info field">
                            {{ employee.id }}
                          </div>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row v-if="!$helpers.isEmpty(employee.mobilephone)">
                    <b-col class="col-no-margin">
                      <div class="full">
                        <div class="third left">
                          <div class="employee-info label">Mobil</div>
                        </div>
                        <div class="twothirds right">
                          <div class="employee-info field">
                            {{ employee.mobilephone }}
                          </div>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row v-if="!$helpers.isEmpty(employee.homephone)">
                    <b-col class="col-no-margin">
                      <div class="full">
                        <div class="third left">
                          <div class="employee-info label">Privat</div>
                        </div>
                        <div class="twothirds right">
                          <div class="employee-info field">
                            {{ employee.homephone }}
                          </div>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <!-- EMPLOYEE DATASHEET PRINT BUTTON - TODO: enable when functionality implemented -->
              <!-- <b-row v-if="!creatingNewEmployee">
                        <b-col class="col-no-margin">
                            <div class="full" style="margin-top: 4px;">
                                <b-button style="display: inline; margin-left:8px;" size="sm" pill variant="primary" @click="printEmployee(employee)">
                                    <i class="fas fa-print" style="margin-left: 0.5px;"></i>&nbsp;&nbsp;&nbsp;&nbsp; Personaldatenblatt drucken &nbsp;&nbsp;
                                </b-button>
                            </div>
                        </b-col>
                    </b-row> -->
            </b-col>
          </b-row>
          <b-row class="split-flat">
            <b-col style="padding-top: 12px"></b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-row style="margin: 0px 0px 4px 0px">
                <b-button
                  :disabled="hidingMultipleEmployees || unhidingMultipleEmployees"
                  style="width: 242px; margin: 0px 0px"
                  @click="selectEmployeePage('coredata')"
                  squared
                  :variant="
                    selectedPage == 'coredata'
                      ? buttonVariants.coredata
                      : 'outline-' + buttonVariants.coredata
                  "
                >Persönliche Daten
                </b-button
                >
              </b-row>
              <b-row style="margin: 0px 0px 4px 0px">
                <b-button
                  :disabled="hidingMultipleEmployees || unhidingMultipleEmployees"
                  style="width: 242px; margin: 0px 0px"
                  @click="selectEmployeePage('socialinsuranceregistration')"
                  squared
                  :variant="
                    selectedPage == 'socialinsuranceregistration'
                      ? buttonVariants.socialinsuranceregistration
                      : 'outline-' + buttonVariants.socialinsuranceregistration
                  "
                >ÖGK Meldungen
                </b-button
                >
              </b-row>
              <b-row style="margin: 0px 0px 4px 0px;">
                <b-button
                  :disabled="hidingMultipleEmployees || unhidingMultipleEmployees"
                  style="width: 242px; margin: 0px 0px;"
                  @click="selectEmployeePage('contracts')"
                  squared
                  :variant="
                    selectedPage == 'contracts'
                      ? buttonVariants.contracts
                      : 'outline-' + buttonVariants.contracts
                  "
                >Arbeitsvertrag
                </b-button
                >
              </b-row>
              <b-row style="margin: 0px 0px 4px 0px">
                <b-button
                  :disabled="hidingMultipleEmployees || unhidingMultipleEmployees"
                  style="width: 242px; margin: 0px 0px"
                  @click="selectEmployeePage('datainquiry')"
                  squared
                  :variant="
                    selectedPage == 'datainquiry'
                      ? buttonVariants.datainquiry
                      : 'outline-' + buttonVariants.datainquiry
                  "
                >Datenabfrage
                </b-button
                >
              </b-row>
              <!--
              <b-row style="margin: 0px 0px 4px 0px;">
                <b-button
                  style="width: 242px; margin: 0px 0px;"
                  @click="selectEmployeePage('changelogs')"
                  squared
                  :variant="selectedPage == 'changelogs'? buttonVariants.coredata : 'outline-' + buttonVariants.coredata"
                >Änderungsmeldungen</b-button>
              </b-row>
              -->
              <b-row style="margin: 0px 0px 4px 0px">
                <b-button
                  :disabled="hidingMultipleEmployees || unhidingMultipleEmployees"
                  style="width: 242px; margin: 0px 0px"
                  @click="selectEmployeePage('documents')"
                  squared
                  :variant="
                    selectedPage == 'documents'
                      ? buttonVariants.documents
                      : 'outline-' + buttonVariants.documents
                  "
                >Dokumente
                </b-button
                >
              </b-row>
              <b-row v-show="showWageData"
                     style="margin: 0px 0px 4px 0px">
                <b-button
                  :disabled="hidingMultipleEmployees || unhidingMultipleEmployees"
                  style="width: 242px; margin: 0px 0px"
                  @click="selectEmployeePage('wagedata')"
                  squared
                  :variant="
                    selectedPage == 'wagedata'
                      ? buttonVariants.wagedata
                      : 'outline-' + buttonVariants.wagedata
                  "
                >Lohndaten
                </b-button
                >
              </b-row>
              <!--
                    <b-row style="margin: 0px 0px 4px 0px;">
                        <b-button style="width: 242px; margin: 0px 0px;" @click="selectEmployeePage('contracts')" squared :variant="selectedPage == 'contracts'? buttonVariants.coredata : 'outline-' + buttonVariants.coredata">Verträge</b-button>
                    </b-row>-->
              <!-- <b-row style="margin: 8px 0px 4px 0px;">
                        <b-button style="width: 242px; margin: 0px 0px;" @click="selectEmployeePage('useraccount')" squared :variant="selectedPage == 'useraccount'? buttonVariants.useraccount : 'outline-' + buttonVariants.useraccount">User Account</b-button>
                    </b-row> -->
            </b-col>
          </b-row>
        </b-col>

        <b-col v-if="employees.length > 0 || creatingNewEmployee">
          <b-container fluid class="scrollbar-container-employeedata">
            <div
              class="page-settings w1400 data-input"
              v-bind:style="{ opacity: loading ? '0' : '1' }"
            >
              <b-row>
                <b-col cols="12">
                  <div class="content">
                    <employeecoredata
                      ref="compcoredata"
                      v-show="selectedPage == 'coredata'"
                      :employee="employee"
                      :creatingNewEmployee="creatingNewEmployee"
                      :companyData="companyData"
                      @employee-creation-cancelled="employeeCreationCancelled"
                      @employee-edit-cancelled="editing = false"
                      @create-employee="createdNewEmployee"
                      @select-employee-id="selectEmployeeWithId"
                      @updated="refresh"
                    ></employeecoredata>
                    <employeechangelogs
                      ref="compchangelogs"
                      v-show="selectedPage == 'changelogs'"
                      :employee="employee"
                      @updated="refresh"
                    ></employeechangelogs>
                    <employeesocialinsuranceregistration
                      ref="compsocialinsuranceregistration"
                      v-show="selectedPage == 'socialinsuranceregistration'"
                      :employee="employee"
                      :refreshSIR="refreshSIR"
                      @updated="refresh"
                    ></employeesocialinsuranceregistration>
                    <employeedocuments
                      ref="compdocuments"
                      v-show="selectedPage == 'documents'"
                      :employee="employee"
                      @updated="refresh"
                    ></employeedocuments>
                    <employeecontracts
                      ref="compcontracts"
                      v-show="selectedPage == 'contracts'"
                      :employee="employee" :uiData="uiDataMap"
                      :collectiveContractMap="collectiveContractMap" :allowedContractMap="allowedContractMapFlat"
                      :contracts="contracts[employee.id]" :stores="stores"
                      @updated="refresh"
                    ></employeecontracts>
                    <employeedatainquiry
                      ref="compdatainquiry"
                      v-show="selectedPage == 'datainquiry'"
                      :employee="employee"
                      @updated="refresh"
                    ></employeedatainquiry>
                    <employeewagedata
                      ref="compwagedata"
                      v-show="selectedPage == 'wagedata'"
                      :employee="employee"
                      :refreshWageDataPage="refreshWageDataPage"
                      :importWageData="importWageData"
                      @updated="refresh"
                    ></employeewagedata>

                    <!-- <employeeuseraccount ref="useraccount" v-if="selectedPage == 'useraccount'" :employee="employee" @updated="refresh" @account-info-refreshed="accountInfoRefreshed"></employeeuseraccount> -->
                  </div>
                </b-col>
              </b-row>

              <div v-if="loading" class="loading">
                <i class="fas fa-spinner"></i>
              </div>
              <dropdown
                v-for="(dropdown, i) in dropdowns"
                :dropdown="dropdown"
                :key="i"
              />
            </div>
          </b-container>
        </b-col>
      </b-row>
    </div>
    <br/>
    <b-modal
      class="image-upload-modal"
      id="modal-image-upload"
      v-model="imageUploadModal"
      hide-footer
      hide-header
    >
      <!-- <div v-if="uploading" class="loading">
            <i class="fas fa-spinner"></i>
        </div> -->
      <div style="text-align: center">
        <vue-avatar
          :image="uploadedImage"
          :width="380"
          :height="380"
          :rotation="rotation"
          :scale="scale"
          ref="vueavatar"
          @vue-avatar-editor:image-ready="onImageReady"
        >
        </vue-avatar>
        <br/>
        <div v-if="hasImage && !loading" style="margin: 0px 48px 8px 48px">
          <label>Zoom:</label>
          <input type="range" min="1" max="3" step="0.02" v-model="scaleStr"/>
          <div class="radio">
            <hr/>
            <label for="rotateLeft"
            ><i
              class="fas fa-portrait"
              style="margin: 4px 8px; transform: rotate(-90deg)"
            ></i
            ></label>
            <input
              style="cursor: pointer"
              type="radio"
              :checked="rotation == -90"
              v-model="rotation"
              name="rotationLeft"
              v-bind:value="rotationLeft"
            />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <label for="rotateNone"
            ><i class="fas fa-portrait" style="margin: 4px 8px"></i
            ></label>
            <input
              style="cursor: pointer"
              type="radio"
              :checked="rotation == 0"
              v-model="rotation"
              name="rotationNone"
              v-bind:value="rotationNone"
            />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <label for="rotateRight"
            ><i
              class="fas fa-portrait"
              style="margin: 4px 8px; transform: rotate(90deg)"
            ></i
            ></label>
            <input
              style="cursor: pointer"
              type="radio"
              :checked="rotation == 90"
              v-model="rotation"
              name="rotationRight"
              v-bind:value="rotationRight"
            />
          </div>
          <br/>
          <button class="button" v-on:click="saveClicked">
            Mitarbeiterfoto speichern
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal ref="modal-error" centered title="Fehler">
      <template slot="modal-header">
        <div class="error-modal">
          <div class="headerlabel">{{ errorTitle }}</div>
        </div>
      </template>
      <b-container fluid>
        <b-row class="my-1" style="text-align: left">
          <div>
            <span>{{ errorMessage }}</span>
          </div>
        </b-row>
      </b-container>
      <template slot="modal-footer">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <div class="button green right" @click="hideModal('modal-error')">
          <div class="label">OK</div>
        </div>
      </template>
    </b-modal>
    <b-modal ref="modal-info" centered>
      <template slot="modal-header">
        <div class="error-modal">
          <div class="headerlabel">{{ infoTitle }}</div>
        </div>
      </template>
      <b-container fluid>
        <b-row class="my-1" style="text-align: left">
          <div>
            <span>{{ infoMessage }}</span>
          </div>
        </b-row>
      </b-container>
      <template slot="modal-footer">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <div class="button green right" @click="hideModal('modal-info')">
          <div class="label">OK</div>
        </div>
      </template>
    </b-modal>

    <b-modal ref="modal-employee-active" centered>
      <template slot="modal-header">
        <div class="error-modal">
          <div class="headerlabel">
            {{
              employee.active
                ? "Mitarbeiter ist aktiviert"
                : "Mitarbeiter ist deaktiviert"
            }}
          </div>
        </div>
      </template>
      <b-container fluid>
        <b-row class="my-1" style="text-align: left">
          <div>
            <span v-if="employee.active"
            >Der Mitarbeiter ist aktiviert und kann, sofern Vertrag und
              Planungsgrundlage korrekt angelegt sind, automatisch verplant
              werden.</span
            >
            <span
            >Der Mitarbeiter ist deaktiviert und steht somit nicht zur Planung
              zur Verfügung.</span
            >
          </div>
        </b-row>
        <b-row class="my-1" style="text-align: left" v-if="employee.active">
          <p>
            <b style="color: red">Achtung:</b> Beim Deaktivieren werden alle
            Schichten und Abwesenheiten des Mitarbeiters die nach dem
            Deaktivierungszeitpunkt stattfinden entfernt!
          </p>
        </b-row>
      </b-container>
      <template slot="modal-footer">
        <div class="button right" @click="hideModal('modal-employee-active')">
          <div class="label">Schließen</div>
        </div>

        <div
          v-if="employee.active"
          class="button yellow right"
          @click="startDeactivationProcess(true)"
        >
          <div class="label">Zum Monatsende deaktivieren</div>
        </div>
        <div
          v-if="employee.active"
          class="button red right"
          @click="startDeactivationProcess()"
        >
          <div class="label">Sofort Deaktivieren</div>
        </div>
        <div
          v-if="!employee.active"
          class="button green right"
          @click="activateEmployee()"
        >
          <div class="label">Mitarbeiter aktivieren</div>
        </div>
      </template>
    </b-modal>
    <b-modal ref="modal-employee-delete" centered>
      <template slot="modal-header">
        <div class="error-modal">
          <div class="headerlabel">Mitarbeiter löschen</div>
        </div>
      </template>
      <b-container fluid>
        <b-row class="my-1" style="text-align: left">
          <div>
            <span
            >Sind Sie sicher, dass Sie den Mitarbeiter löschen möchten?<br/><br/>ACHTUNG:
              Das Löschen eines Mitarbeiters ist endgültig. Dieser Vorgang kann
              <strong>NICHT</strong> rückgängig gemacht werden!</span
            >
          </div>
        </b-row>
      </b-container>
      <template slot="modal-footer">
        <div class="button right" @click="hideModal('modal-employee-delete')">
          <div class="label">Abbrechen</div>
        </div>
        <div class="button red right" @click="deleteEmployee()">
          <div class="label">Mitarbeiter löschen</div>
        </div>
      </template>
    </b-modal>

    <b-modal ref="modal-pdfpreview" @close="closePdfPreview()" centered>
      <template slot="modal-header">
        <div class="error-modal">
          <div class="headerlabel">{{ modalPdfType }} erstellt</div>
        </div>
      </template>
      <b-container fluid>
        <b-row class="my-1" style="text-align: left">
          <div>
            <span
            >{{ modalPdfType }} wurde erstellt und steht zum Öffnen oder
              Herunterladen bereit.</span
            >
          </div>
        </b-row>
      </b-container>
      <template slot="modal-footer">
        <div class="button right" @click="closePdfPreview()">
          <div class="label">Schließen</div>
        </div>
        <div class="button green right" @click="openPreviewedPdf()">
          <div class="label">In neuem Tab öffnen</div>
        </div>
        <div class="button green right" @click="savePreviewedPdf()">
          <div class="label">Speichern</div>
        </div>
      </template>
    </b-modal>
    <b-modal
      size="lg"
      class="import-modal"
      ref="modal-import"
      id="modal-import"
      v-model="importModal"
      hide-footer
      hide-header
    >
      <div style="text-align: center">
        <br/>
        <div v-if="importState.stage == 0" style="margin: 0px 48px 8px 48px">
          <div class="import-drop-zone">
            <h4>ÖGK Meldungen hochladen</h4>
            <br/>
            <div
              @click="showFilePicker"
              id="dropzone"
              style="text-align:center; background: var(--contrast-1); padding: 100px 0px; border-radius: 25px; cursor: pointer;"
            >
              <i
                class="fal fa-4x fa-file-download"
                style="color: var(--contrast-5)"
              ></i>
              <br/><br/>
              <small style="color: var(--contrast-5); font-size: 11px"
              >Hier klicken oder Dateien per Drag&Drop ablegen</small
              >
              <input
                id="fileImportInput"
                type="file"
                multiple
                @change="filesSelected($event.target.files)"
                class="input-file"
              />
            </div>
            <div></div>
          </div>
        </div>
        <div
          v-else-if="importState.stage == 2"
          style="margin: 0px 48px 8px 48px"
        >
          <div class="import-drop-zone">
            <h4>ELDA Beitragskonto</h4>
            <br/>
            <div class="full split-bottom">
              <div class="label">
                Wählen Sie aus von welchem ELDA Beitragskonto Sie Daten
                importieren möchten.
              </div>
            </div>
            <div class="full">
              <div class="half left">
                <div class="label">ELDA Beitrags-KtoNr:</div>
              </div>
              <div class="half cd-input">
                <dropdownsearch style="width: 100%;" class="filters" :state="{
                  input: selectedEldaAccountLabelForImport(),
                  items: importState.eldaAccounts,
                  onceSelected: function ($event, item, searchbar){
                    importState.eldaAccount = item.id
                  },
                  selectedItem: importState.eldaAccounts[0],
                  styleLight: true,
                  style: '',
                }"/>
              </div>
            </div>
            <div></div>
          </div>
          <br/>
          <button
            style="margin-top: 24px"
            class="button"
            v-on:click="importNext"
          >
            Dateien prüfen und importieren
          </button>
        </div>
        <!-- loading stages -->
        <div
          v-else-if="[1, 3, 4, 5, 6].includes(importState.stage)"
          style="height: 200px"
        >
          <i
            class="fas fa-spinner"
            style="color: var(--contrast-4); margin-top: 25px"
          ></i>
          <br/>
          <small style="color: var(--contrast-5); font-size: 11px">{{
              importLoadingText
            }}</small>
        </div>
        <div v-else-if="importState.stage == -1" style="height: 250px">
          <i
            class="fal fa-4x fa-file-times"
            style="color: var(--contrast-4); margin-top: 55px"
          ></i>
          <br/><br/>
          <small style="color: var(--contrast-5); font-size: 12px"
          >Die gewählten Dateien enthalten keine importierbaren
            Anmeldungen</small
          >
          <br/>
          <button
            style="margin-top: 24px"
            class="button"
            v-on:click="hideImportModal"
          >
            Schließen
          </button>
        </div>
      </div>
    </b-modal>

    <b-modal ref="modal-employee-datainquiry" centered>
      <template slot="modal-header">
        <div class="error-modal">
          <div class="headerlabel">Datenabfrage erstellen</div>
        </div>
      </template>
      <b-container fluid>
        <b-row class="my-1" style="text-align: left">
          <div
            style="text-align: left; width: 100%; padding: 3px; font-size: 17px"
          >
            <strong>Welche Daten sollen eingegeben/überprüft werden.</strong>
          </div>
          <br/><br/>
          <div
            style="
              text-align: left;
              width: 50%;
              padding-left: 30px;
              margin-bottom: 15px;
            "
          >
            <strong>Persönliche Daten</strong><br/>
            <div style="clear: both">
              <div style="float: left">
                <checkbox
                  :value="datainquiry.requestFirstName"
                  @Update="
                    datainquiry.requestFirstName = !datainquiry.requestFirstName
                  "
                />
              </div>
              Vorname
            </div>
            <div style="clear: both">
              <div style="float: left">
                <checkbox
                  :value="datainquiry.requestLastName"
                  @Update="
                    datainquiry.requestLastName = !datainquiry.requestLastName
                  "
                />
              </div>
              Nachname
            </div>
            <div style="clear: both">
              <div style="float: left">
                <checkbox
                  :value="datainquiry.requestCitizenshipId"
                  @Update="
                    datainquiry.requestCitizenshipId =
                      !datainquiry.requestCitizenshipId
                  "
                />
              </div>
              Staatsbürgerschaft
            </div>
            <div style="clear: both">
              <div style="float: left">
                <checkbox
                  :value="datainquiry.requestCitizenTypeId"
                  @Update="
                    datainquiry.requestCitizenTypeId =
                      !datainquiry.requestCitizenTypeId
                  "
                />
              </div>
              Bürgertyp
            </div>
            <div style="clear: both">
              <div style="float: left">
                <checkbox
                  :value="datainquiry.requestCivilStatusId"
                  @Update="
                    datainquiry.requestCivilStatusId =
                      !datainquiry.requestCivilStatusId
                  "
                />
              </div>
              Familienstand
            </div>
          </div>
          <div
            style="
              text-align: left;
              width: 50%;
              padding-left: 30px;
              margin-bottom: 15px;
            "
          >
            <strong>Adressdaten</strong><br/>

            <div style="clear: both">
              <div style="float: left">
                <checkbox
                  :value="datainquiry.requestAddress"
                  @Update="
                    datainquiry.requestAddress = !datainquiry.requestAddress
                  "
                />
              </div>
              Anschrift
            </div>
            <div style="clear: both">
              <div style="float: left">
                <checkbox
                  :value="datainquiry.requestPostalCode"
                  @Update="
                    datainquiry.requestPostalCode =
                      !datainquiry.requestPostalCode
                  "
                />
              </div>
              Postleitzahl
            </div>
            <div style="clear: both">
              <div style="float: left">
                <checkbox
                  :value="datainquiry.requestCity"
                  @Update="datainquiry.requestCity = !datainquiry.requestCity"
                />
              </div>
              Stadt
            </div>
            <div style="clear: both">
              <div style="float: left">
                <checkbox
                  :value="datainquiry.requestCountryId"
                  @Update="
                    datainquiry.requestCountryId = !datainquiry.requestCountryId
                  "
                />
              </div>
              Land
            </div>
          </div>

          <div
            style="
              text-align: left;
              width: 50%;
              padding-left: 30px;
              margin-bottom: 15px;
            "
          >
            <strong>Bankverbindung</strong>
            <div style="clear: both">
              <div style="float: left">
                <checkbox
                  :value="datainquiry.requestBankAccountHolder"
                  @Update="
                    datainquiry.requestBankAccountHolder =
                      !datainquiry.requestBankAccountHolder
                  "
                />
              </div>
              Konto Inhaber
            </div>
            <div style="clear: both">
              <div style="float: left">
                <checkbox
                  :value="datainquiry.requestIban"
                  @Update="datainquiry.requestIban = !datainquiry.requestIban"
                />
              </div>
              IBAN
            </div>
            <div style="clear: both">
              <div style="float: left">
                <checkbox
                  :value="datainquiry.requestBic"
                  @Update="datainquiry.requestBic = !datainquiry.requestBic"
                />
              </div>
              BIC
            </div>
          </div>

          <div
            style="
              text-align: left;
              width: 50%;
              padding-left: 30px;
              margin-bottom: 15px;
            "
          >
            <strong>Kontakt</strong>
            <div style="clear: both">
              <div style="float: left">
                <checkbox
                  :value="datainquiry.requestHomephone"
                  @Update="
                    datainquiry.requestHomephone = !datainquiry.requestHomephone
                  "
                />
              </div>
              Festnetz Nummer
            </div>
            <div style="clear: both">
              <div style="float: left">
                <checkbox
                  :value="datainquiry.requestMobilephone"
                  @Update="
                    datainquiry.requestMobilephone =
                      !datainquiry.requestMobilephone
                  "
                />
              </div>
              Mobiltelefon Nummer
            </div>
          </div>
          <!--
          <div style="text-align: left; width: 50%; padding-left: 30px; margin-bottom: 15px">
            <strong>Zusätzliche Informationen</strong><br />

            <div style="clear: both">
              <div style="float: left">
                <checkbox
                  :value="datainquiry.requestGenderId"
                  @Update="datainquiry.requestGenderId = !datainquiry.requestGenderId"
                />
              </div>
              Geschlecht
            </div>
            <div style="clear: both">
              <div style="float: left">
                <checkbox
                  :value="datainquiry.requestReligionId"
                  @Update="datainquiry.requestReligionId = !datainquiry.requestReligionId"
                />
              </div>
              Religion
            </div>
            <div style="clear: both">
              <div style="float: left">
                <checkbox
                  :value="datainquiry.requestDriverslicense"
                  @Update="
                    datainquiry.requestDriverslicense = !datainquiry.requestDriverslicense
                  "
                />
              </div>
              Führerschein
            </div>
            <div style="clear: both">
              <div style="float: left">
                <checkbox
                  :value="datainquiry.requestDlExpiry"
                  @Update="datainquiry.requestDlExpiry = !datainquiry.requestDlExpiry"
                />
              </div>
              Führerschein Ablaufdatum
            </div>
            <div style="clear: both">
              <div style="float: left">
                <checkbox
                  :value="datainquiry.requestFirstaidcourse"
                  @Update="
                    datainquiry.requestFirstaidcourse = !datainquiry.requestFirstaidcourse
                  "
                />
              </div>
              Erste Hilfe Kurs
            </div>
            <div style="clear: both">
              <div style="float: left">
                <checkbox
                  :value="datainquiry.requestFaExpiry"
                  @Update="datainquiry.requestFaExpiry = !datainquiry.requestFaExpiry"
                />
              </div>
              EH Kurs Ablaufdatum
            </div>
            <div style="clear: both">
              <div style="float: left">
                <checkbox
                  :value="datainquiry.requestDisability"
                  @Update="datainquiry.requestDisability = !datainquiry.requestDisability"
                />
              </div>
              Behinderung Begünstigtenstatus
            </div>
          </div>

          <div style="text-align: left; width: 50%; padding-left: 30px; margin-bottom: 15px">
            <strong>Erziehungsberechtigte/r</strong><br />

            <div style="clear: both">
              <div style="float: left">
                <checkbox
                  :value="datainquiry.requestGuardianName"
                  @Update="
                    datainquiry.requestGuardianName = !datainquiry.requestGuardianName
                  "
                />
              </div>
              Name
            </div>
            <div style="clear: both">
              <div style="float: left">
                <checkbox
                  :value="datainquiry.requestGuardianAddress"
                  @Update="
                    datainquiry.requestGuardianAddress = !datainquiry.requestGuardianAddress
                  "
                />
              </div>
              Adresse
            </div>
            <div style="clear: both">
              <div style="float: left">
                <checkbox
                  :value="datainquiry.requestGuardianPhone"
                  @Update="
                    datainquiry.requestGuardianPhone = !datainquiry.requestGuardianPhone
                  "
                />
              </div>
              Telefonnummer
            </div>
          </div>
          -->
        </b-row>
      </b-container>
      <template slot="modal-footer">
        <div
          class="button right"
          @click="hideModal('modal-employee-datainquiry')"
        >
          <div class="label">Abbrechen</div>
        </div>
        <div class="button red right" @click="sendDataInquiry()">
          <div class="label">Datenabfrage senden</div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import VueEventBus from "../../vue-event-bus";
import grid from "../uicomponents/grid.vue";
import dropdown from "../uicomponents/dropdown.vue";
import dropdownsearch from "../uicomponents/dropdownsearch.vue";
import employeecoredata from "./EmployeeCoreData.vue";
import employeechangelogs from "./EmployeeChangelogs.vue";
import employeedatainquiry from "./EmployeeDataInquiry";
import employeesocialinsuranceregistration from "./EmployeeSocialInsuranceRegistration.vue";
import employeedocuments from "./EmployeeDocuments.vue";
import employeecontracts from "./EmployeeContracts.vue";
import {mapState, mapGetters} from "vuex";
import Vue from "vue";
import {VueAvatar} from "vue-avatar-editor-improved";
import pdf from "vue-pdf";
import Papa from "papaparse";
import moment from "moment";

export default {
  components: {
    pdf,
    grid,
    dropdown,
    dropdownsearch,
    employeecoredata,
    employeechangelogs,
    employeedatainquiry,
    employeedocuments,
    employeecontracts,
    employeesocialinsuranceregistration,
    VueAvatar,
  },
  name: "employeedata",
  data() {
    return {
      loadingStage: 0,
      showDetails: true,
      selectedTab: 0,
      selectedPage: "coredata",
      uploading: false,
      scaleStr: "",
      scale: 1.0,
      rotation: 0.0,
      rotationLeft: -90,
      rotationNone: 0,
      rotationRight: 90,
      imageUploadModal: false,
      uploadedImage: "",
      hasImage: false,
      employees: [],
      dataFiltered: [],
      loading: true,
      loadingText: "Lade Mitarbeiterdaten...",
      dragWatch: -1,
      datainquiry: {
        requestGenderId: false,
        requestReligionId: false,
        requestCivilStatusId: true,
        requestCitizenshipId: true,
        requestCitizenTypeId: true,
        requestFirstName: true,
        requestLastName: true,
        requestDob: false,
        requestSocialsecurity: false,
        requestEmail: false,
        requestPhone: false,
        requestHomephone: true,
        requestMobilephone: true,
        requestBankAccountHolder: true,
        requestIban: true,
        requestBic: true,
        requestMdPersonnelId: false,
        requestAddress: true,
        requestPostalCode: true,
        requestCity: true,
        requestCountryId: true,
        requestGuardianName: false,
        requestGuardianAddress: false,
        requestGuardianPhone: false,
        requestCoreEmployeeNumber: false,
        requestMiscInfo: false,
        requestDriverslicense: false,
        requestDlExpiry: false,
        requestFirstaidcourse: false,
        requestFaExpiry: false,
        requestDisability: false,
      },
      mouseCurrent: {
        x: 0,
        y: 0,
      },
      mouseDragStart: {
        x: 0,
        y: 0,
      },
      showImageUploadCircle: false,
      showWizardImageUploadCircle: false,
      scrolling: false,
      scrollTop: 150,
      collapseCoredata: true,
      dropdowns: [],
      imagecrops: [],
      nameFilter: "",
      gridData: {
        header: [
          {
            label: "",
            description: "Profile Picture",
            map: (i) => i.employeePicture !== null,
            type: "icon",
            width: "50px",
            enabled: true,
          },
          /* {
                        label: '',
                        description: 'Warning indicator',
                        map: (i) => i.metadata.icon !== null,
                        type: 'fa-icon',
                        width: '30px',
                        enabled: true
                    }, */
          {
            label: "Name",
            map: (i) => i.lastName + " " + i.firstName,
            type: "text",
            width: "220px",
            enabled: true,
          },
        ],
        data: [],
      },
      employee: Object,
      prevEmployee: Object,
      savedEmployee: Object,
      createEmployeeWizardOpened: false,
      wizardPage: 1,
      creatingNewEmployee: false,
      newEmployee: Object,
      newEmployeeProfilePicture: null,
      newEmployeeProfilePictureBase64: null,
      imageForNewEmployee: false,
      errorTitle: "",
      errorMessage: "",
      infoTitle: "",
      infoMessage: "",
      buttonVariants: this.defaultButtonVariants(),
      showOkEmployee: true,
      showEmployeeWithInfo: true,
      showEmployeeWithWarningOrDanger: true,
      showEmployeeNameFilter: false,
      showInactiveEmployees: false,
      hideDocumentsUpload: false,
      modalPdfFilename: "",
      modalPdf: "",
      modalPdfDoc: null,
      modalPdfType: "Report",
      editing: false,
      documentWarnings: {
        hasWarnings: false,
        expired: [],
        expiringSoon: [],
      },

      employeeArray: [],
      stores: [],
      countryNameMap: {},
      userData: {},
      importLoading: false,
      importModal: false,
      importLoadingText: "",
      importFilePickerOpening: false,
      importState: {
        stage: 0,
        eldaAccount: null,
        eldaAccounts: [],
        files: [],
        count: 0,
        checked: 0,
        processed: 0,
        importFiles: [],
        importPayload: {
          registrations: [],
        },
        uploadPayload: {
          files: [],
        },
        uploadFiles: [],
        registrationsEmployeeIdMap: {},
        results: {},
      },
      dataInquiryMode: 0,
      selectedInquirySet: false,

      // socialinsuranceregistration
      creatingEldaRequest: false,
      refreshSIR: 0,

      // wage data
      refreshWageDataPage: false,
      importWageData: false,

      allowedContractMap: {},
      allowedContractMapFlat: {},
      collectiveContractMap: {},
      collectiveContractArray: [],
      contracts: {},

      companyData: null,

      selectedDate: this.$helpers.getMomentTZ().clone(),
      timeframeStart: this.$helpers.getMomentTZ().clone().startOf("month"),
      timeframeEnd: this.$helpers.getMomentTZ().clone().endOf("month"),

      hidingMultipleEmployees: false,
      unhidingMultipleEmployees: false,
      mdPersonnelIdDuplicates: [],
      ibanDuplicates: [],
      svnrDuplicates: [],
    };
  },
  computed: {
    ...mapGetters([
      "imgCacheTimestamp",
      "companyId",
      "storeMap",
      "isSuperUser",
      "globalFilter",
      "uiDataMap",
    ]),
    dateOfBirth: {
      get: function () {
        var dateStr;
        if (this.employee.dob) {
          dateStr = this.$helpers.extractDateString(this.employee.dob);
        }
        return dateStr;
      },
      set: function (val) {
        this.employee.dob = val;
      },
    },
    employeeWarnings: {
      get: function () {
        return this.checkEmployeeData(this.employee, true);
      },
    },
    hbEditEmployeeDisabled() {
      return (
        this.creatingNewEmployee ||
        this.editing ||
        this.selectedPage != "coredata"
      );
    },
    showWageData() {
      let view = this;
      return view.companyData && view.companyData.softwareId === 0;
    },
    headerButtons() {
      let view = this;
      let buttons = [];
      let helperLinks = [];

      switch (this.selectedPage) {
        case "coredata":
          if (this.hidingMultipleEmployees) {
            buttons = [
              {
                classes: "save-hide-all-selected-employeees",
                type: "block green",
                disabled: false,
                icon: "fa-save",
                click: () => {
                  this.hidingMultipleEmployees = false;
                  let employeesToHide = this.gridData.data.filter(e => e.selectedForHide).map(e => e.id);
                  for (var x in this.gridData.data) {
                    this.gridData.data[x].selectedForHide = false;
                  }
                  if (employeesToHide.length > 0) {
                    this.hideMultipleEmployees(employeesToHide);
                  } else {
                    view.filterGridData();
                  }
                },
                show: true,
                tooltip: "Ausgewählte MitarbeiterInnen verstecken",
                title: "Ausgewählte MitarbeiterInnen verstecken",
              },
              {
                classes: "cancel-hide-all-selected-employeees",
                type: "block red",
                disabled: false,
                icon: "fa-minus-circle",
                click: () => {
                  this.hidingMultipleEmployees = false;
                  for (var x in this.gridData.data) {
                    this.gridData.data[x].selectedForHide = false;
                  }
                  this.filterGridData();
                },
                show: true,
                tooltip: "Abbrechen",
                title: "Abbrechen",
              },
            ];
          } else if (this.unhidingMultipleEmployees) {
            buttons = [
              {
                classes: "save-unhide-all-selected-employeees",
                type: "block green",
                disabled: false,
                icon: "fa-save",
                click: () => {
                  this.unhidingMultipleEmployees = false;
                  let employeesToUnhide = this.gridData.data.filter(e => e.selectedForUnhide).map(e => e.id);
                  for (var x in this.gridData.data) {
                    this.gridData.data[x].selectedForUnhide = false;
                  }
                  if (employeesToUnhide.length > 0) {
                    this.unhideMultipleEmployees(employeesToUnhide);
                  } else {
                    view.filterGridData();
                  }
                },
                show: true,
                tooltip: "Ausgewählte MitarbeiterInnen nicht mehr verstecken",
                title: "Ausgewählte MitarbeiterInnen nicht mehr verstecken",
              },
              {
                classes: "cancel-unhide-all-selected-employeees",
                type: "block red",
                disabled: false,
                icon: "fa-minus-circle",
                click: () => {
                  this.unhidingMultipleEmployees = false;
                  for (var x in this.gridData.data) {
                    this.gridData.data[x].selectedForUnhide = false;
                  }
                  this.filterGridData();
                },
                show: true,
                tooltip: "Abbrechen",
                title: "Abbrechen",
              },
            ];
          } else {
            buttons = [
              {
                type: "block",
                icon: "fa-sync-alt",
                click: () => {
                  view.refresh(null, true, true);
                },
                tooltip: "Daten neu laden",
              },
              {
                classes: "add-employee",
                type: "block green border-r",
                disabled: this.creatingNewEmployee || this.editing,
                icon: "fa-user-plus",
                click: () => {
                  this.requestEmailForNewEmployee();
                },
                show: !this.editing && !this.creatingNewEmployee,
                tooltip: "Mitarbeiter erstellen",
                title: "Mitarbeiter erstellen",
                helperText:
                    "Klicken Sie auf “Mitarbeiter erstellen”, füllen Sie zumindest die rot markierten Felder aus und klicken Sie auf “Mitarbeiter erstellen” um den neuen Mitarbeiter zu speichern.",
                externalUrl:
                    "https://meindienstplan.atlassian.net/servicedesk/customer/portal/1/article/1129578499",
              },
              {
                type: "block",
                icon: "fa-file-csv",
                show: true,
                disabled: this.creatingNewEmployee || this.editing,
                click: () => {
                  view.openCSVImportPopup();
                },
                tooltip: "Daten aus CSV-Datei importieren",
                helperText:
                    "Mit “Daten aus CSV importieren” können sie die Daten mehrerer Mitarbeiter aus einer CSV Datei importieren. <br/> Wichtig dabei ist dass die Datei in UTF-8 enkodiert ist und das selbe Schema aufweist wie die Beispiel Datei",
                externalUrl:
                    "https://meindienstplan.atlassian.net/servicedesk/customer/portal/1/topic/43d6a505-b8ac-4215-852a-56fb6e466498/article/1272741889",
              },
              {
                classes: "edit-employee",
                type: "block yellow",
                disabled: this.hbEditEmployeeDisabled,
                icon: "fa-user-edit",
                click: () => {
                  this.editCurrentEmployeeCoredata();
                },
                show: !this.editing && !this.creatingNewEmployee,
                tooltip: "Mitarbeiter bearbeiten",
                title: "Mitarbeiter bearbeiten",
                helperText:
                    "Über die Schaltfläche “Mitarbeiter bearbeiten” können Sie die Stammdaten des derzeit geöffneten Mitarbeiters ändern.",
                externalUrl:
                    "https://meindienstplan.atlassian.net/servicedesk/customer/portal/1/article/1129578499",
              },
              {
                classes: "update-employee",
                type: "block green",
                disabled:
                    !this.creatingNewEmployee &&
                    this.$refs.compcoredata != undefined &&
                    !this.$refs.compcoredata.pendingChanges,
                icon: "fa-save",
                click: () => {
                  let ok = true;
                  this.$refs.compcoredata.mandatoryFields.forEach(field => {
                    ok = ok && !this.$refs.compcoredata.mandatoryAndMissing(field);
                  });
                  if (ok) {
                    if (this.companyData && this.companyData.softwareId === 0
                        && !this.$refs.compcoredata.showBmdIdField) {
                      console.log("updating bmdId as well");
                      this.$refs.compcoredata.savedEmployee.bmdId = this.$refs.compcoredata.savedEmployee.mdPersonnelId;
                    }
                    this.checkEmail(
                        this.$refs.compcoredata.savedEmployee.email,
                        this.$refs.compcoredata.savedEmployee.id,
                        true,
                        this.saveEmployeeClicked
                    );
                  } else {
                    if(this.$store.state.companyId == 116){
                      this.$helpers.error('Fehler', 'Bitte füllen Sie die Pflichtfelder aus! Die Personalnummer muss eine Zahl sein.');
                    }else{
                      this.$helpers.error('Fehler', 'Bitte füllen Sie die Pflichtfelder aus!');
                    }
                  }
                },
                show: this.creatingNewEmployee || this.editing,
                tooltip: "Änderungen speichern",
                title: "Änderungen speichern",
                helperText: "Die getätigten Änderungen werden gespeichert.",
                externalUrl:
                    "https://meindienstplan.atlassian.net/servicedesk/customer/portal/1/article/1129578499",
              },
              {
                classes: "cancel-edit",
                type: "block red",
                disabled: false,
                icon: "fa-minus-circle",
                click: () => {
                  this.$refs.compcoredata.cancelEditMode();
                },
                show: this.creatingNewEmployee || this.editing,
                tooltip: "Abbrechen",
                title: "Abbrechen",
                helperText: "Abbrechen und alle Änderungen verwerfen.",
                externalUrl:
                    "https://meindienstplan.atlassian.net/servicedesk/customer/portal/1/article/1129578499",
              },
              {
                classes: "employee-picture-delete",
                type: "block red",
                disabled:
                    this.employee && this.employee.employeePicture
                        ? this.employee.employeePicture == null
                        : true,
                icon: "fa-portrait",
                click: () => {
                  this.deleteEmployeePicture(this.employee.id);
                },
                show: true,
                tooltip: "Mitarbeiter Bild löschen",
                title: "Mitarbeiter Bild löschen",
                helperText: "Löscht das Bild eines Mitarbeiters",
              },
              {
                classes: "hide-employee",
                type: "block",
                disabled: false,
                icon:
                    this.employee && !this.employee.isHidden
                        ? "fa-eye-slash"
                        : "fa-eye",
                click: () => {
                  if (this.employee && this.employee.isHidden) {
                    this.unhideEmployee(this.employee.id);
                  } else {
                    this.hideEmployee(this.employee.id);
                  }
                },
                show: true,
                tooltip:
                    this.employee && !this.employee.isHidden
                        ? "Mitarbeiter verstecken"
                        : "Mitarbeiter nicht mehr verstecken",
                title:
                    this.employee && !this.employee.isHidden
                        ? "Mitarbeiter verstecken"
                        : "Mitarbeiter nicht mehr verstecken",
                helperText:
                    "Versteckte Mitarbeiter werden nicht mehr in der Mitarbeiterliste links angezeigt.",
              },
            ];
          }
          break;
        case "socialinsuranceregistration":
          buttons = [
            {
              type: "block border-r",
              icon: "fa-chevron-left",
              show: this.creatingEldaRequest,
              click: () => {
                this.creatingEldaRequest = false;
              },
              tooltip: "Meldung abbrechen",
            },
            {
              type: "block",
              icon: "fa-sync-alt",
              show: !this.creatingEldaRequest,
              click: () => {
                view.refreshSIR++;
              },
              tooltip: "Daten neu laden",
            },
            {
              classes: "import",
              type: "block border-r",
              disabled: false,
              icon: "fa-file-import",
              click: () => {
                this.openImportModal();
              },
              show: !this.creatingEldaRequest,
              tooltip: "ÖGK Meldungen importieren",
              title: "ÖGK Meldungen importieren",
              helperText:
                "ÖGK Meldungen aus ELDA können importiert und bei ML hinterlegt werden. Um den Import zu starten klicken Sie in das Popup oder ziehen Sie die Dateien die Sie importieren möchten per Drag&Drop auf das Dateisymbol.",
            },
          ];
          break;
        case "datainquiry":
          if (this.dataInquiryMode == 0) {
            buttons = [
              {
                type: "block",
                icon: "fa-envelope-open-text",
                click: () => {
                  view.createNewDataInquiry();
                },
                tooltip: "Datenabfrage erstellen",
              },
            ];
          } else {
            buttons = [
              {
                type: "block",
                icon: "fa-step-backward",
                click: () => {
                  view.dataInquiryMode = 0;
                  view.selectedInquirySet = false;
                },
                tooltip: "zurück",
              },
              {
                type: "block green",
                icon: "fa-save",
                click: () => {
                  this.saveDataInquiry(
                    this.$refs.compdatainquiry.selectedInquiry
                  );
                },
                tooltip: "Speichern",
              },
            ];
          }
          break;
        case "contracts":
          buttons = [
            {
              type: "block",
              icon: "fa-sync-alt",
              click: () => {
                view.refresh(null, true, true);
              },
              tooltip: "Daten neu laden",
            },
          ];
          break;
        case "wagedata":
          buttons = [
            {
              type: "block",
              icon: "fa-sync-alt",
              click: () => {
                view.refreshWageDataPage = true;
              },
              tooltip: "Daten neu laden",
            },
            {
              classes: "import",
              type: "block border-r",
              icon: "fa-file-import",
              click: () => {
                view.importWageData = true;
              },
              tooltip: "Lohndaten importieren",
              helperText:
                "Mit “Lohndaten importieren” können sie die Lohndaten mehrerer Mitarbeiter aus einer CSV Datei importieren.<br>" +
                  "Exportieren Sie dazu den “Mitarbeiter-Korereport” aus BMD.",
              externalUrl: "https://meindienstplan.atlassian.net/servicedesk/customer/kb/view/2630352897",

            },
          ];
          break;
        case "documents":
          buttons = [
            {
              type: "block",
              icon: "fa-sync-alt",
              click: () => {
                view.refreshDocuments(true);
              },
              tooltip: "Dokumente neu laden",
            },
            {
                classes: "upload-file",
                type: "block green",
                disabled: this.hideDocumentsUpload,
                icon: "fa-file-upload",
                click: () => {
                    this.$refs.compdocuments.showModal('modal-createfile');
                    setTimeout(() => { // give the modal some time to show up :-)
                        view.$refs.compdocuments.selectFileForUpload();
                    }, 100);
                  },
                show: true,
                tooltip: 'Dokument hochladen',
                title: "Dokument hochladen",
                helperText: "Für jeden Mitarbeiter können max. 50 mb an Dokumenten hochgeladen werden. Für eine saubere Organisation der Dokumente wählen Sie beim Hochladen jeweils den Typ des Dokuments (Meldezettel, Ausweis, etc.) und vergeben Sie einen Namen.",
                externalUrl: "https://meindienstplan.atlassian.net/servicedesk/customer/kb/view/1134133272"
            },
            {
                classes: "fill-documents",
                type: "block cyan",
                disabled: false,
                icon: "fa-file-code",
                click: () => {
                    this.$refs.compdocuments.startFillDocuments();
                  },
                show:  true,
                tooltip: 'Dokument ausfüllen',
                title: "Dokument ausfüllen",
                helperText: 'Sie können Vorlagen für Dokumente bereitstellen welche automatisch mit den Daten des Mitarbeiters ausgefüllt und als PDF heruntergeladen werden können. Nähere Informationen zum Erstellen von Vorlagen erhalten Sie mit einem Klick auf "Mehr erfahren"',
                externalUrl: "https://meindienstplan.atlassian.net/servicedesk/customer/portal/1/topic/43d6a505-b8ac-4215-852a-56fb6e466498/article/1665040385"
            },
          ];
          break;
      }

      helperLinks.push({
        title: "Support erhalten",
        helperText:
          "Wenn Sie auf Ihre Frage keine Antwort im Hilfecenter finden, können Sie uns eine Support Anfrage stellen. Klicken Sie dazu einfach auf 'Support kontaktieren', geben Sie Ihre E-Mail Adresse ein, schreiben Sie eine detaillierte Zusammenfassung Ihres Problems inkl. der Daten Ihres Stores, laden Sie etwaige Anhänge (Bilder, etc.) hoch und klicken Sie auf 'Senden'. Wir sehen uns Ihr Problem an und melden uns so schnell wie möglich.",
        externalUrl:
          "https://meindienstplan.atlassian.net/servicedesk/customer/portal/1/group/2/create/11",
        externalLabel: "Support kontaktieren",
        cancelLabel: "Schließen",
      });
      let state = {
        buttons: buttons,
        helperLinks: helperLinks,
        showListIconAlways: true,
      };
      return state;
    },
    sideButtons() {
      let view = this;
      let buttons = [];

      switch (this.selectedPage) {
        case "coredata":
          buttons = [
              {
              type: "block",
              icon: "fa-bars",
              click: ($event) => {
                view.OpenMenuDetails($event, this.burgerMenuState);
              },
            }
          ];
          break;
        default:
          break;
      }

      let state = {
        buttons: buttons,
      };

      return state;
    },
    burgerMenuState(){
      let view = this;
      let state = {
        componentref: 'burgermenustate',
        groups: [],
        items: []
      };
      let group1 = {
        label: "Zusätzliche Einstellungen",
        items: [
          {
            closeOnClick: true,
            icon: "fa-eye-slash",
            text: 'MitarbeiterInnen verstecken Modus aktivieren',
            show:  !view.hidingMultipleEmployees,
            disabled: view.unhidingMultipleEmployees || this.creatingNewEmployee || this.editing,
            click: () => { view.hidingMultipleEmployees = true; view.filterGridData(); }
          },
          {
            closeOnClick: true,
            icon: "fa-minus-circle",
            text: 'MitarbeiterInnen verstecken Modus abbrechen',
            show:  view.hidingMultipleEmployees,
            disabled: false,
            click: () => {
              view.hidingMultipleEmployees = false;
              for (var x in this.gridData.data) {
                view.gridData.data[x].selectedForHide = false;
              }
              view.filterGridData();
            },
          },{
            closeOnClick: true,
            icon: "fa-eye",
            text: 'MitarbeiterInnen nicht mehr verstecken Modus aktivieren',
            show:  !view.unhidingMultipleEmployees,
            disabled: view.hidingMultipleEmployees || this.creatingNewEmployee || this.editing,
            click: () => { view.unhidingMultipleEmployees = true; view.filterGridData(); }
          },
          {
            closeOnClick: true,
            icon: "fa-minus-circle",
            text: 'MitarbeiterInnen nicht mehr verstecken Modus abbrechen',
            show:  view.unhidingMultipleEmployees,
            disabled: false,
            click: () => {
              view.unhidingMultipleEmployees = false;
              for (var x in this.gridData.data) {
                view.gridData.data[x].selectedForUnhide = false;
              }
              view.filterGridData();
            },
          },
        ]
      }

      state.groups.push(group1);

      return state;
    },
    dateDisplay: function () {
      let view = this;
      return view.getMonthName(view.timeframeStart.months()) + " " + view.timeframeStart.format("YYYY");
    },
  },
  watch: {
    companyId: function (val, oldVal) {
      this.nameFilter = "";
      this.refresh(null, true, true);
    },
    loadingStage: function (val, oldVal) {
      console.log("EmployeeData - loading stage: " + val);
      if (val >= 1) {
        console.log("EmployeeData - loading done");
        this.loading = false;
      }
    },
    globalFilter: function (val, oldVal) {
      this.globalFilterChanged();
    },
    importState: {
      deep: true,
      handler: function (val, oldVal) {
        //console.log('Import state updated:');
        //console.log(val);
      },
    },
    scaleStr: function (val, oldVal) {
      this.scale = parseFloat(val);
    },
    nameFilter: function (val, oldVal) {
      this.filterGridData();
    },
    showOkEmployee: function (val, oldVal) {
      this.filterGridData();
    },
    showEmployeeWithInfo: function (val, oldVal) {
      this.filterGridData();
    },
    showEmployeeWithWarningOrDanger: function (val, oldVal) {
      this.filterGridData();
    },
    showInactiveEmployees: function (val, oldVal) {
      this.filterGridData();
    },
    employee: function (val, oldVal) {
      /* this.$helpers.log(this, 'watch()', val); */
      this.updateEmployeeGridDataMetadata();
      this.filterGridData();
    },
  },
  props: {},
  created() {
    window.employeesettings = this;
    var settings = this;
    VueEventBus.$on("EmployeeModifiedEvent:create", this.employeeCreated);
    VueEventBus.$on("EmployeeModifiedEvent:update", this.employeeUpdated);

    VueEventBus.$on(
      "EmployeeSocialInsuranceRegistrationEvent:register",
      this.employeeSocialInsuranceRegistrationRegistered
    );
    VueEventBus.$on(
      "EmployeeSocialInsuranceRegistrationEvent:deregister",
      this.employeeSocialInsuranceRegistrationDeregistered
    );
    VueEventBus.$on(
      "EmployeeSocialInsuranceRegistrationEvent:undo_deregister",
      this.employeeSocialInsuranceRegistrationDeregisterUndone
    );
    VueEventBus.$on(
      "EmployeeSocialInsuranceRegistrationEvent:batchUploadDocument",
      this.employeeSocialInsuranceRegistrationBatchFileUploadCompleted
    );
    VueEventBus.$on(
      "EmployeeSocialInsuranceRegistrationEvent:confirmDocument",
      this.employeeSocialInsuranceRegistrationConfirmed
    );
    VueEventBus.$on(
      "EmployeeSocialInsuranceRegistrationEvent:delete",
      this.employeeSocialInsuranceRegistrationDeleted
    );
  },
  beforeDestroy: function () {
    VueEventBus.$off("EmployeeModifiedEvent:create", null);
    VueEventBus.$off("EmployeeModifiedEvent:update", null);
    VueEventBus.$off("EmployeeSocialInsuranceRegistrationEvent:register", null);
    VueEventBus.$on(
      "EmployeeSocialInsuranceRegistrationEvent:deregister",
      null
    );
    VueEventBus.$on(
      "EmployeeSocialInsuranceRegistrationEvent:batchUploadDocument",
      null
    );
    VueEventBus.$on(
      "EmployeeSocialInsuranceRegistrationEvent:confirmDocument",
      null
    );
    VueEventBus.$on("EmployeeSocialInsuranceRegistrationEvent:delete", null);
  },

  mounted() {
    let view = this;
    this.userData = this.$helpers.getUserDataFromJWT(this.$store.state.jwt);
    let employeeIdToSelect = null;
    this.savedEmployee = Object.assign({}, this.employee);
    this.editing = this.creatingNewEmployee;

    // let url = "/enum/country";
    // this.api.get(url).then((response) => {
    //   for (let x in response.data) {
    //     let elem = response.data[x];
    //     view.countryNameMap[elem.valueGER] = x;
    //   }
    // });

    // url = "/enum/gender";
    // this.api.get(url).then((response) => {
    //   for (let x in response.data) {
    //     let elem = response.data[x];
    //     view.genderArray.push(elem);
    //   }
    // });

    // url = "/enum/citizentype";
    // this.api.get(url).then((response) => {
    //   for (let x in response.data) {
    //     let elem = response.data[x];
    //     view.citizenTypeArray.push(elem);
    //   }
    // });

    // url = "/enum/civilstatus";
    // this.api.get(url).then((response) => {
    //   for (let x in response.data) {
    //     let elem = response.data[x];
    //     view.civilStatusArray.push(elem);
    //   }
    // });
    if (this.$parent.employeeIdToSelect != null) {
      employeeIdToSelect = this.$parent.employeeIdToSelect;
      this.$parent.employeeIdToSelect = null;
    }
    if (this.$route.query.employeeId) {
      employeeIdToSelect = this.$route.query.employeeId;
    }

    VueEventBus.$on("changeEmployee", (payLoad) => {
      console.log(payLoad);
      this.selectEmployeeWithId(payLoad);
    });

    view.refresh(employeeIdToSelect, true, true);
  },
  updated() {
  },
  destroyed() {
    var settings = this;

    if (document.querySelector("#imgupload"))
      document
        .querySelector("#imgupload")
        .removeEventListener("change", settings.ProcessImageUpload);
    if (document.querySelector("#imguploadpublic"))
      document
        .querySelector("#imguploadpublic")
        .removeEventListener("change", settings.ProcessImageUploadPublic);
  },
  methods: {
    refresh(employeeIdToSelect = null, force = false, loadingAnimation = true) {
      let view = this;
      console.log("selected page: " + view.selectedPage);
      //console.log('REFRESHING: ' + employeeIdToSelect + '|' + force);
      this.loading = true;
      this.loadingStage = 0;
      this.editing = false;
      if (this.employeeIdToSelect == null) this.employee = {};
      view.$helpers.GetStoresArray().then((stores) => {
        view.stores = stores;
        Object.values(view.stores).forEach(store => {
          this.getAllowedContractMap(store.id, force);
        });
      });
      view.$helpers
        .GetCompanySettings(force)
        .then((response) => {
          view.updateCompanySettings(response);
        });
      this.getCollectiveContractMap(force);
      this.getEmployeeContracts().then((resp) => {
        this.loadEmployees(force, employeeIdToSelect);
      });
    },
    refreshDocuments(force) {
      this.$refs.compdocuments.refresh(force);
    },

    updateCompanySettings: function (response) {
      let view = this;

      if (response.data.length === 1) {
        view.companyData = response.data[0];
      }
    },
    getEmployeeContracts: function () {
      let view = this;
      let url = '/api/sec/proxy/md/employee/contracts';

      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/sec/proxy/md/employee/contracts');

      return this.axios({
        method: 'get',
        headers: {
          'AUTHORIZATION': "Bearer " + this.$store.state.jwt
        },
        url: url
      }).then(resp => {
        return view.$helpers.GetStores()
          .then(storeResponse => {
            let storeIds = storeResponse.map(s => s.id);
            view.contracts = {};
            Object.values(resp.data).forEach(contract => {
              if (storeIds.includes(contract.storeId)) {
                if (view.contracts.hasOwnProperty(contract.remoteId)) {
                  view.contracts[contract.remoteId].push(contract);
                } else {
                  view.contracts[contract.remoteId] = [];
                  view.contracts[contract.remoteId].push(contract);
                }
              }
            });
            console.log(view.contracts);
          });
      }).catch(error => {
        console.log(error);
      });
    },
    globalFilterChanged() {
      this.filterGridData();
    },
    createNewDataInquiry() {
      this.showModal("modal-employee-datainquiry");
    },
    sendDataInquiry() {
      let view = this;
      let url = "/api/sec/employee/datainquiry";
      const params = {
        companyId: this.$store.state.companyId,
      };
      let payload = {
        employeeId: this.employee.id,
      };
      if (this.datainquiry.requestGenderId) payload.requestGenderId = true;
      if (this.datainquiry.requestReligionId) payload.requestReligionId = true;
      if (this.datainquiry.requestCivilStatusId)
        payload.requestCivilStatusId = true;
      if (this.datainquiry.requestCitizenshipId)
        payload.requestCitizenshipId = true;
      if (this.datainquiry.requestCitizenTypeId)
        payload.requestCitizenTypeId = true;
      if (this.datainquiry.requestFirstName) payload.requestFirstName = true;
      if (this.datainquiry.requestLastName) payload.requestLastName = true;
      if (this.datainquiry.requestDob) payload.requestDob = true;
      if (this.datainquiry.requestSocialsecurity)
        payload.requestSocialsecurity = true;
      //if(this.datainquiry.requestEmail) payload.requestEmail = true; // useless since we need an email to send the inquiry
      if (this.datainquiry.requestPhone) payload.requestPhone = true;
      if (this.datainquiry.requestHomephone) payload.requestHomephone = true;
      if (this.datainquiry.requestMobilephone)
        payload.requestMobilephone = true;
      if (this.datainquiry.requestBankAccountHolder)
        payload.requestBankAccountHolder = true;
      if (this.datainquiry.requestIban) payload.requestIban = true;
      if (this.datainquiry.requestBic) payload.requestBic = true;
      //if(this.datainquiry.requestMdPersonnelId) payload.requestMdPersonnelId = true;
      if (this.datainquiry.requestAddress) payload.requestAddress = true;
      if (this.datainquiry.requestPostalCode) payload.requestPostalCode = true;
      if (this.datainquiry.requestCity) payload.requestCity = true;
      if (this.datainquiry.requestCountryId) payload.requestCountryId = true;
      if (this.datainquiry.requestGuardianName)
        payload.requestGuardianName = true;
      if (this.datainquiry.requestGuardianAddress)
        payload.requestGuardianAddress = true;
      if (this.datainquiry.requestGuardianPhone)
        payload.requestGuardianPhone = true;
      //if(this.datainquiry.requestCoreEmployeeNumber) payload.requestCoreEmployeeNumber = true;
      //if(this.datainquiry.requestMiscInfo) payload.requestMiscInfo = true;
      if (this.datainquiry.requestDriverslicense)
        payload.requestDriverslicense = true;
      if (this.datainquiry.requestDlExpiry) payload.requestDlExpiry = true;
      if (this.datainquiry.requestFirstaidcourse)
        payload.requestFirstaidcourse = true;
      if (this.datainquiry.requestFaExpiry) payload.requestFaExpiry = true;
      if (this.datainquiry.requestDisability) payload.requestDisability = true;

      this.axios({
        method: "post",
        headers: {
          AUTHORIZATION: "Bearer " + view.$store.state.jwt,
        },
        url: url,
        params,
        data: payload,
      })
        .then((response) => {
          view.$helpers.success(
            "Datenabfrage erstellt",
            "Der Mitarbeiter wird in Kürze eine Email erhalten."
          );
          view.hideModal("modal-employee-datainquiry");
          this.$refs.compdatainquiry.refresh();
        })
        .catch(function (error) {
          view.$helpers.error("Fehler", "Konnte nicht erstellt werden.");
        });
    },
    parseCSVImport(file) {
      let view = this;
      return new Promise((resolve, reject) => {
        Papa.parse(file, {
          header: true,
          skipEmptyLines: true,
          transformHeader: (header) => {
            if (header) {
              header = header.trim().toLowerCase();
            }
            if (header == "mitarb-nr" || header == "mitarbeiternummer") {
              return "personnelId";
            }
            if (header == "nachname" || header == "familienname") {
              return "lastName";
            }
            if (header == "vorname") {
              return "firstName";
            }
            if (header == "geburtsdatum") {
              return "dob";
            }
            if (
              header == "svnr" ||
              header == "svnr kurz" ||
              header == "svnr." ||
              header == "svcode_10_stellig"
            ) {
              return "socialsecurity";
            }
            if (
              header == "staatsangehörigkeit" ||
              header == "ländername" ||
              header == "nationalität" ||
              header == "nation"
            ) {
              return "nationality";
            }
            if (
              header == "strasse" ||
              header == "straße" ||
              header == "adresse"
            ) {
              return "address";
            }
            if (header == "ort" || header == "stadt") {
              return "city";
            }
            if (header == "plz") {
              return "postalCode";
            }
            if (
              header == "e-mail" ||
              header == "e-mail-adresse" ||
              header == "email" ||
              header == "verbindung"
            ) {
              return "email";
            }
            if (
              header == "mobil" ||
              header == "mobilephone" ||
              header == "verbindung1"
            ) {
              return "mobilephone";
            }
            if (header == "iban") {
              return "iban";
            }
            if (header == "swift/bic" || header == "bic") {
              return "bic";
            }
            if (header == "lohn" || header == "bruttolaufend_vereinbarung") {
              return "payment"
            }
            if (header == "lohntyp") {
              return "wageType"
            }
            if (header == "eintrittsdatum" || header == "vertragsdatum" || header == "dvp-beginn") {
              return "contractStart"
            }
            if (header == "wochenstunden" || header == "wochenmodell" || header == "gazwoche") {
              return "weekTimeRegular"
            }
            if (header == "wochentage" || header == "tagewoche") {
              return "weekWorkDaysRegular"
            } else {
              return "no_matching_field";
            }
          },
          complete: (result, file) => {
            view.cleanupAndExtendImportData(result.data);
            resolve(result.data);
          },
          error: (error, file) => {
            reject(error);
          },
        });
      });
    },
    getAllowedContractMap(storeId, force = false, asArray = false) {
      if (this.allowedContractMap && this.allowedContractMap[storeId] && !force) {
        return new Promise((resolve, reject) => {
          resolve(this.$helpers.returnObj(this.allowedContractMap[storeId], asArray));
        });
      } else {
        let params = {
          storeId: storeId
        };
        return this.$helpers.GetCall('/api/sec/proxy/md/allowedContracts', params).then((response) => {
          let map = {};
          response.data.forEach(allowedContract => {
            map[allowedContract.id] = allowedContract;
            this.allowedContractMapFlat[allowedContract.id] = allowedContract;
          });
          this.allowedContractMap[storeId] = map;
          return new Promise((resolve, reject) => {
            resolve(this.$helpers.returnObj(map, asArray));
          });
        }).catch((e) => {
          return new Promise((resolve, reject) => {
            reject(e);
          });
        });
      }
    },
    postAllowedContracts(collectiveContractId, missingContracts) {
      let promises = [];
      missingContracts.forEach((mc) => {
        let payload = {
          collectiveContractId: collectiveContractId,
          storeId: mc.storeId,
          weekTimeRegular: mc.weekTimeRegular,
          weekWorkDaysRegular: mc.weekWorkDaysRegular
        };
        promises.push(this.$helpers.CreateCall('/api/sec/proxy/md/allowedContracts', payload));
      });
      return Promise.all(promises)
        .then((values) => {
          console.log(values);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCollectiveContractMap(force = false, asArray = false) {
      if (this.collectiveContractMap && !force) {
        return new Promise((resolve, reject) => {
          resolve(this.$helpers.returnObj(this.collectiveContractMap, asArray));
        });
      } else {
        return this.$helpers.GetCall('/api/sec/proxy/md/collectiveContracts').then((response) => {
          let map = {};
          response.data.forEach(collectiveContract => {
            map[collectiveContract.id] = collectiveContract;
          });
          this.collectiveContractMap = map;
          this.collectiveContractArray = this.$helpers.returnObj(map, true);
          return new Promise((resolve, reject) => {
            resolve(this.$helpers.returnObj(map, asArray));
          });
        }).catch((e) => {
          return new Promise((resolve, reject) => {
            reject(e);
          });
        });
      }
    },
    cleanupAndExtendImportData(data) {
      let view = this;
      data.forEach((entry) => {
        delete entry["no_matching_field"];

        if (entry.postalCode) {
          let pCode = entry.postalCode.trim().split(" ");
          if (pCode.length > 0) {
            entry.postalCode = pCode[0];
          }
          if (pCode.length > 1) {
            let city = "";
            for (let i = 0; i < pCode.length; i++) {
              if (i != 0) {
                city += pCode[i] + " ";
              }
            }
            city = city.trim();
            entry.city = city;
          }
        }

        if (entry.email) {
          entry.email = entry.email.toLowerCase().trim();
        }

        entry.nationality = this.countryNameMap[entry.nationality]
          ? this.countryNameMap[entry.nationality]
          : null;

        if (entry.dob) {
          let split;
          if (entry.dob.includes("/")) {
            split = entry.dob.trim().split("/");
          } else if (entry.dob.includes("-")) {
            split = entry.dob.trim().split("-");
          } else {
            split = entry.dob.trim().split(".");
          }

          let fixedDob = "";
          split.forEach((str) => {
            if (str.length < 2) {
              fixedDob += "0" + str + ".";
            } else {
              fixedDob += str + ".";
            }
          });
          if (fixedDob.length > 0) {
            fixedDob = fixedDob.substring(0, fixedDob.length - 1);
          }
          if (entry.dob.includes("/")) {
            entry.dob = this.$helpers
              .getMomentFromStringUTC(fixedDob, "MM.DD.YYYY")
              .format();
          } else if (entry.dob.includes("-")) {
            entry.dob = this.$helpers
              .getMomentFromStringUTC(fixedDob, "YYYY.MM.DD")
              .format();
          } else {
            entry.dob = this.$helpers
              .getMomentFromStringUTC(fixedDob, "DD.MM.YYYY")
              .format();
          }
          if (entry.dob == "Invalid date") {
            entry.dob = null;
          }
        }
        entry.dob = entry.dob ? entry.dob : null;

        if (entry.payment) {
          if (entry.payment.includes(",") && entry.payment.includes(",")) {
            entry.payment = entry.payment.replace(".", "");
          }
          entry.payment = Math.round(parseFloat(entry.payment) * 100);
        }
        if (entry.wageType == null) {
          entry.wageType = 4;
        }
        if (entry.wageType > 4 || entry.wageType < 1) {
          entry.wageType = 4;
        }

        if (entry.contractStart) {
          let split;
          if (entry.contractStart.includes("/")) {
            split = entry.contractStart.trim().split("/");
          } else if (entry.contractStart.includes("-")) {
            split = entry.contractStart.trim().split("-");
          } else {
            split = entry.contractStart.trim().split(".");
          }

          let fixedContractStart = "";
          split.forEach(str => {
            if (str.length < 2) {
              fixedContractStart += "0" + str + ".";
            } else {
              fixedContractStart += str + ".";
            }
          })
          if (fixedContractStart.length > 0) {
            fixedContractStart = fixedContractStart.substring(0, fixedContractStart.length - 1);
          }
          if (entry.contractStart.includes("/")) {
            entry.contractStart = this.$helpers.getMomentFromStringTZ(fixedContractStart, "MM.DD.YYYY").format();
          } else if (entry.contractStart.includes("-")) {
            entry.contractStart = this.$helpers.getMomentFromStringTZ(fixedContractStart, "YYYY.MM.DD").format();
          } else {
            entry.contractStart = this.$helpers.getMomentFromStringTZ(fixedContractStart, "DD.MM.YYYY").format();
          }
          if (entry.contractStart == "Invalid date") {
            entry.contractStart = null;
          }
        }
        entry.contractStart = entry.contractStart ? entry.contractStart : null;
        if (entry.contractStart) {

          entry.weekTimeRegular = entry.weekTimeRegular ? Math.round(parseFloat(entry.weekTimeRegular) * 60) : 40 * 60;
          entry.weekWorkDaysRegular = entry.weekWorkDaysRegular ? Math.round(parseFloat(entry.weekWorkDaysRegular)) : 5;
        } else {
          entry.weekTimeRegular = null;
          entry.weekWorkDaysRegular = null;
        }

      });
    },
    openCSVImportPopup() {
      console.log("Open CSV Import popup, possible stores for import are:");
      console.log(this.stores);
      let view = this;
      let storeItems = this.stores.map((i) => {
        return {
          label: i.name + (i.description ? "(" + i.description + ")" : ""),
          value: i.id,
        };
      });
      let collectiveContracts = this.collectiveContractArray.map((cc) => {
        return {
          label: cc.name + (cc.country == 2 ? " (Deutschland)" : ""),
          value: cc.id,
        };
      });
      let dataEntries = [
        {
          label: "CSV Datei",
          accept: ".csv",
          key: "file",
          hidden: false,
          type: "file",
          value: null,
        },
        {
          label: "Store",
          key: "storeId",
          hidden: false,
          type: "dropdownsearch",
          value: null,
          flexGroup: false,
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: "",
            items: storeItems,
            selectMultiple: false,
            selectedItems: [],
            selectedItem: null,
            onceSelected: function ($event, item, searchbar) {
            },
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
        },
        {
          label: "Neue Mitarbeiter anlegen",
          key: "acceptNewEmployees",
          hidden: false,
          type: "bool",
          value: false,
        },
        {
          label: "Bereits angelegte Mitarbeiter sofort übernehmen",
          key: "acceptExistingImmediately",
          hidden: !view.isSuperUser,
          type: "bool",
          value: false,
        },
        {
          label: "Verträge aktualisieren",
          key: "updateContracts",
          hidden: false,
          type: "bool",
          value: false,
        },
        {
          label: "Fehlende KV Modelle anlegen",
          key: "createAllowedContracts",
          hidden: false,
          type: "bool",
          value: false,
        },
        {
          label: "Kollektivvertrag",
          key: "collectiveContract",
          hidden: this.$helpers.CheckDatafillKeysForHidden("createAllowedContracts"),
          type: "dropdownsearch",
          value: null,
          flexGroup: false,
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: "",
            items: collectiveContracts,
            selectMultiple: false,
            selectedItems: [],
            selectedItem: null,
            onceSelected: function ($event, item, searchbar) {
            },
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
        },
      ];

      this.$helpers.OpenPopup({
        elRelative: null,
        type: "datafill",
        componentState: {
          entries: dataEntries,
          selectCallback: function (importf) {
            if (importf.file.length > 0) {
              view
                .parseCSVImport(importf.file[0], importf.externalServiceId)
                .then((parsedEmployeeArray) => {
                  let uniqueEmails = [];

                  parsedEmployeeArray.forEach((e) => {
                    if (e.storeId == null) {
                      e.storeId = importf.storeId;
                    }
                    e.companyId = view.companyId;
                    if (uniqueEmails.includes(e.email)) {
                      console.log("Unique email already present: " + e.email);
                    } else {
                      uniqueEmails.push(e.email);
                    }
                  });

                  let resultArray = [];
                  let employeeArray = [];
                  parsedEmployeeArray.forEach((e) => {
                    if (
                      e.firstName != null &&
                      e.lastName != null &&
                      e.firstName.length > 0 &&
                      e.lastName.length > 0
                    ) {
                      employeeArray.push(e);
                    }
                  });
                  if (employeeArray.length > 0) {
                    return view
                      .getImportCandidates(employeeArray, true)
                      .then((candidatesResponse) => {
                        console.log(candidatesResponse);
                        let candidateArray = candidatesResponse.data.data;
                        //candidateArray.forEach(ca => console.log(ca));
                        return view.handleEmployeeImportStep(
                          employeeArray,
                          candidateArray,
                          resultArray,
                          importf.acceptExistingImmediately
                        );
                      })
                      .then((finalResultArray) => {
                        let checkContracts = false;
                        finalResultArray.forEach((d) => {
                          if (d.contractStart != null
                            && d.weekTimeRegular != null
                            && d.weekWorkDaysRegular != null) {
                            checkContracts = true;
                          }
                        });
                        if (checkContracts) {
                          return view.getAllowedContractMap(importf.storeId, true)
                            .then((res => {
                              let workWeekArray = [];
                              Object.values(res).forEach((r) => {
                                if (!(r.weekWorkDaysRegular in workWeekArray)) {
                                  workWeekArray[r.weekWorkDaysRegular] = [];
                                }
                                if (!(r.weekTimeRegular in workWeekArray[r.weekWorkDaysRegular])) {
                                  workWeekArray[r.weekWorkDaysRegular][r.weekTimeRegular] = true;
                                }
                              });

                              let missingContracts = [];
                              finalResultArray.forEach((d) => {
                                if (d.contractStart != null
                                  && d.weekTimeRegular != null
                                  && d.weekWorkDaysRegular != null) {
                                  if (!(d.weekWorkDaysRegular in workWeekArray)) {
                                    missingContracts.push(d);
                                  } else if (!(d.weekTimeRegular in workWeekArray[d.weekWorkDaysRegular])) {
                                    missingContracts.push(d);
                                  }
                                }
                              });

                              if (missingContracts.length > 0) {
                                if (importf.createAllowedContracts) {
                                  return view.postAllowedContracts(importf.collectiveContract, missingContracts)
                                    .then((response) => {
                                      return new Promise((resolve, reject) => {
                                        resolve(finalResultArray);
                                      });
                                    });
                                } else {
                                  view.displayMissingAllowedContractsMessage(missingContracts);
                                  return new Promise((resolve, reject) => {
                                    reject("Missing contracts!")
                                  })
                                }
                              } else {
                                return new Promise((resolve, reject) => {
                                  resolve(finalResultArray);
                                });
                              }
                            }))
                            .then((finalResultArray) => {
                              return view.importEmployeeData(
                                finalResultArray,
                                importf.acceptNewEmployees,
                                importf.updateContracts
                              );
                            });
                        } else {
                          return view.importEmployeeData(
                            finalResultArray,
                            importf.acceptNewEmployees
                          );
                        }
                      });
                  } else {
                    return view.importEmployeeData(
                      resultArray,
                      importf.acceptNewEmployees,
                      importf.updateContracts
                    );
                  }
                })
                .then((res) => {
                  view.refresh(null, true, true);
                  view.$helpers.success(
                    "Erfolg",
                    "Mitarbeiter wurden erfolgreich importiert"
                  );
                })
                .catch((error) => {
                  console.log(error);
                  view.$helpers.error(
                    "Fehler",
                    "Fehler beim Importieren der Daten"
                  );
                });
            } else {
              view.$helpers.error(
                "Fehler",
                "Es wurde keine Import Datei ausgewählt"
              );
            }
          },
        },
        header: "Mitarbeiter Daten importieren",
        remainAfterSelect: false,
        expanded: true,
        centered: true,
      });
    },
    importEmployeeData(data, acceptNewEmployees = false, updateContracts = false) {
      console.log("Employee data to import:");
      data.forEach((d) => {
        if (d.dob != null) d.dob = this.$helpers.extractDateString(d.dob);
      });
      console.log(data);
      let payload = {
        authuser_id: this.userData.id,
        data: data,
        create: acceptNewEmployees,
        updateContracts: updateContracts ? "true" : "false"
      };
      console.log(payload);
      let url = "/sec/employee/import";
      this.api.post(url, payload).then((response) => {
        console.log("Import response:");
        console.log(response);
      });
    },
    getImportCandidates(employees) {
      let payload = {employees: []};
      employees.forEach((employee) => {
        let cur = {};
        cur.firstName = employee.firstName;
        cur.lastName = employee.lastName;
        if (employee.dob != null)
          cur.dob = this.$helpers.extractDateString(employee.dob);
        if (employee.socialsecurity != null)
          cur.socialsecurity = employee.socialsecurity;
        payload.employees.push(cur);
      });
      let url = "/sec/employee/proxyMDCoreCandidate/multi";

      console.log(url);
      return this.api.post(url, payload);
    },
    displayMissingAllowedContractsMessage(missingContracts) {
      let view = this;
      let description = "";

      description += "<p><b style='color: red'>ACHTUNG!</b></p>"
      description += "<p>Der Import konnte nicht durchgeführt werden, da folgende KV Modelle nicht in den MD Betriebseinstellungen angelegt sind:</p>"
      description += "<ul>";
      missingContracts.forEach(c => {
        description += "<li><b>Wochenstunden:</b> " + c.weekTimeRegular / 60.0 + "  <b>Wochentage:</b> " + c.weekWorkDaysRegular + "</li>";
      })
      description += "</ul>";

      view.$helpers.OpenPopup({
        type: 'datafill',
        componentState: {
          entries: [],
          description: description,

          selectCallback: function () {
          },

        },
        header: '<div class="label" style="font-size: 22px;font-weight:200;">IMPORT</div><div class="label" style="font-size: 22px;font-family: DistrictProBold;font-weight:800;">FEHLER</div>',
        expanded: true,
        width: "700px",
        centered: true
      });
    },
    handleEmployeeImportStep(
      employeeImportArray,
      candidateArray,
      resultArray,
      acceptExistingImmediately = false
    ) {
      let view = this;
      console.log("IMPORT STEP candidates array:");
      candidateArray.forEach((ca) => console.log(ca));
      if (employeeImportArray.length != candidateArray.length) {
        return new Promise((resolve, reject) =>
          reject("Import and candidate array of different size")
        );
      }
      if (employeeImportArray.length == 0) {
        return new Promise((resolve, reject) => resolve(resultArray));
      } else {
        let employee = employeeImportArray.pop();
        let candidates = candidateArray.pop();
        candidates = this.transformCandidateResult(candidates);

        if (candidates.length == 0) {
          resultArray.push(employee);
          return this.handleEmployeeImportStep(
            employeeImportArray,
            candidateArray,
            resultArray,
            acceptExistingImmediately
          );
        } else {
          let match = candidates.find((e) => e.score == 100);
          if (match != null && match.value && acceptExistingImmediately) {
            employee.id = match.value;
            resultArray.push(employee);
            return this.handleEmployeeImportStep(
              employeeImportArray,
              candidateArray,
              resultArray,
              acceptExistingImmediately
            );
          } else {
            return this.openCandidateChooserPopup(employee, candidates)
              .then((e) => {
                resultArray.push(e);
                return this.handleEmployeeImportStep(
                  employeeImportArray,
                  candidateArray,
                  resultArray,
                  acceptExistingImmediately
                );
              })
              .catch(function (error) {
                view.$helpers.error(
                  "Fehler",
                  "Fehler beim Erstellen des Mitarbeiters"
                );
                console.log(error);
              });
          }
        }
      }
    },
    transformCandidateResult(candidateResult) {
      let candidates = candidateResult.result.candidates;
      let matches = candidateResult.result.matches;
      let selectableItems = [];
      candidates.forEach((candidate) => {
        let bd =
          candidate.date_of_birth != null
            ? this.$helpers
              .getMomentFromStringTZ(candidate.date_of_birth)
              .format("DD.MM.YYYY")
            : "";
        let item = {
          label:
            candidate.firstname +
            " " +
            candidate.lastname +
            " " +
            bd +
            " (Übereinstimmung: " +
            candidate.score +
            "%)",
          value: candidate.id,
          score: candidate.score,
          firstName: candidate.firstname,
          lastName: candidate.lastname,
        };
        selectableItems.push(item);
      });
      matches.forEach((candidate) => {
        let bd =
          candidate.date_of_birth != null
            ? this.$helpers
              .getMomentFromStringTZ(candidate.date_of_birth)
              .format("DD.MM.YYYY")
            : "";
        let item = {
          label:
            candidate.firstname + " " + candidate.lastname + " " + bd + " 100%",
          value: candidate.id,
          score: 100,
          firstName: candidate.firstname,
          lastName: candidate.lastname,
        };
        selectableItems.push(item);
      });
      selectableItems.sort((a, b) => {
        return -(a.score - b.score);
      });
      return selectableItems;
    },
    openCandidateChooserPopup(newEmployee, selectableItems) {
      let view = this;
      let entries = [];

      selectableItems.unshift({
        label: "Mitarbeiter neu anlegen",
        value: null,
      });

      let description =
        "Es wurden Mitarbeiter in ihrer Firma mit ähnlichen Daten gefunden. <br/> Sie können den Mitarbeiter neu anlegen oder zu einem bereits bestehenden Mitarbeiter wechseln:";

      entries.push({
        label: "Bitte auswählen",
        key: "id",
        hidden: false,
        type: "dropdownsearch",
        value: null,
        selectMultiple: false,
        dropdownSearchState: {
          toggled: false,
          displayedItems: [],
          input: "",
          items: selectableItems,
          selectMultiple: false,
          selectedItems: [],
          selectFirst: true,
          styleLight: true,
        },
      });

      return new Promise((resolve, reject) => {
        view.$helpers.OpenPopup({
          type: "datafill",
          componentState: {
            entries: entries,
            description: description,
            selectCallback: function (item) {
              if (item.id == null) {
                reject("Es muss eine Option ausgewählt werden!");
              } else {
                if (!item.createWithoutId && item.id != "createNew") {
                  newEmployee.id = item.id;
                } else if (item.id == "createNew") {
                  delete newEmployee["id"];
                }
                resolve(newEmployee);
              }
            },
          },
          header:
            "Mitarbeiter anlegen: " +
            newEmployee.firstName +
            " " +
            newEmployee.lastName,
          centered: true,
          expanded: true,
        });
      });
    },
    employeeCreated: function () {
      console.log("RECEIVED EVENT EMPLOYEE CREATED");
      this.$helpers.success(
        "Event Getriggert",
        "Neuer Mitarbeiter wurde erstellt"
      );
    },
    employeeUpdated: function () {
      console.log("RECEIVED EVENT EMPLOYEE UPDATED");
      this.$helpers.success("Event Getriggert", "Mitarbeiter wurde verändert");
    },
    employeeSocialInsuranceRegistrationRegistered: function () {
      console.log(
        "RECEIVED EVENT EmployeeSocialInsuranceRegistrationEvent REGISTER"
      );
      this.$helpers.success(
        "Event Getriggert",
        "EmployeeSocialInsuranceRegistrationEvent wurde verändert"
      );

      let view = this;
      let employeeIdToSelect = null;
      if (this.$parent.employeeIdToSelect != null) {
        employeeIdToSelect = this.$parent.employeeIdToSelect;
        this.$parent.employeeIdToSelect = null;
      }
      //view.refresh(employeeIdToSelect, true, false);
    },
    employeeSocialInsuranceRegistrationDeregistered: function () {
      console.log(
        "RECEIVED EVENT EmployeeSocialInsuranceRegistrationEvent DE-REGISTER"
      );
      this.$helpers.success(
        "Event Getriggert",
        "EmployeeSocialInsuranceRegistrationEvent wurde verändert"
      );

      let view = this;
      let employeeIdToSelect = null;
      if (this.$parent.employeeIdToSelect != null) {
        employeeIdToSelect = this.$parent.employeeIdToSelect;
        this.$parent.employeeIdToSelect = null;
      }
      //view.refresh(employeeIdToSelect, true, false);
    },
    employeeSocialInsuranceRegistrationDeregisterUndone: function () {
      console.log(
        "RECEIVED EVENT EmployeeSocialInsuranceRegistrationEvent UNDO-DE-REGISTER"
      );
      this.$helpers.success(
        "Event Getriggert",
        "EmployeeSocialInsuranceRegistrationEvent wurde verändert"
      );

      let view = this;
      let employeeIdToSelect = null;
      if (this.$parent.employeeIdToSelect != null) {
        employeeIdToSelect = this.$parent.employeeIdToSelect;
        this.$parent.employeeIdToSelect = null;
      }
      //view.refresh(employeeIdToSelect, true, false);
    },
    employeeSocialInsuranceRegistrationBatchFileUploadCompleted: function () {
      let keys = Object.keys(this.importState.results);
      keys.forEach((protocolNumber) => {
        if (
          this.importState.results[protocolNumber]["Datei Status"] ==
          "Upload gestartet"
        ) {
          this.importState.results[protocolNumber]["Datei Status"] =
            "hochgeladen";
        }
      });
      if (this.importState.stage > 0) this.createImportStateResultsCSV();
      console.log("Batch upload for insurance registration import completed");
      this.$helpers.success("Import erfolgreich", "");
      this.resetImportState();
      this.hideImportModal();
      this.$helpers.OpenAlert({
        header: "Import fertig",
        description:
          "Der Import ist abgeschlossen, ein CSV mit Details wurde automatisch erstellt und heruntergeladen.",
        acceptLabel: "OK",
        disableCancel: true,
      });

      let view = this;
      let employeeIdToSelect = null;
      if (this.$parent.employeeIdToSelect != null) {
        employeeIdToSelect = this.$parent.employeeIdToSelect;
        this.$parent.employeeIdToSelect = null;
      }
      view.refresh(employeeIdToSelect, true, true);
    },
    createImportStateResultsCSV() {
      let csvPre = "data:text/csv;charset=utf-8,";

      let protocolNumbers = Object.keys(this.importState.results);
      if (protocolNumbers.length > 0) {
        let headers = Object.keys(this.importState.results[protocolNumbers[0]]);
        let csv = headers.join(";");
        csv += "\n";
        protocolNumbers.forEach((protocolNumber) => {
          let fields = [];
          headers.forEach((header) => {
            fields.push(this.importState.results[protocolNumber][header]);
          });

          csv += fields.join(";");
          csv += "\n";
        });

        let encodedUri = encodeURI(csvPre) + "\uFEFF" + encodeURIComponent(csv);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute(
          "download",
          "Import_" + this.$helpers.getMomentTZ().format("YYYY-MM-DD") + ".csv"
        );
        document.body.appendChild(link); // Required for FF

        link.click();
      }
    },
    employeeSocialInsuranceRegistrationConfirmed: function () {
      console.log(
        "RECEIVED EVENT EmployeeSocialInsuranceRegistrationEvent Confirmed Document"
      );
      this.$helpers.success(
        "Event Getriggert",
        "Mitarbeiter Dokument wurde bestätigt"
      );

      let view = this;
      let employeeIdToSelect = null;
      if (this.$parent.employeeIdToSelect != null) {
        employeeIdToSelect = this.$parent.employeeIdToSelect;
        this.$parent.employeeIdToSelect = null;
      }
      view.refresh(employeeIdToSelect, true, true);
    },
    employeeSocialInsuranceRegistrationDeleted: function () {
      console.log(
        "RECEIVED EVENT EmployeeSocialInsuranceRegistrationEvent DELETE"
      );
      this.$helpers.success(
        "Event Getriggert",
        "EmployeeSocialInsuranceRegistrationEvent wurde gelöscht"
      );

      let view = this;
      let employeeIdToSelect = null;
      if (this.$parent.employeeIdToSelect != null) {
        employeeIdToSelect = this.$parent.employeeIdToSelect;
        this.$parent.employeeIdToSelect = null;
      }
      //view.refresh(employeeIdToSelect, true, false);
    },

    selectEmployeePage(page) {
      if (this.selectedPage == "coredata") {
        // check if currently editing before navigating to other employeepage
        this.checkEditingMode(() => (this.selectedPage = page));
      } else {
        this.selectedPage = page;
      }
    },
    editCurrentEmployeeCoredata() {
      this.$refs.compcoredata.toggleEditMode();
      this.editing = true;
    },
    saveCurrentEmployeeCoredata(generateChangePdf = false) {
      this.$refs.compcoredata.updateChangedFields(generateChangePdf);
    },
    loadEmployees: function (force = false, employeeIdToSelect = null) {
      console.log("employees loaded");
      let view = this;
      return view.$helpers.GetEmployeeMap(force, true)
      .then((response) => {
          let empls = response;
          this.employeeArray = empls;
          this.prepareStoreEmployees(empls, employeeIdToSelect, force);

          this.loadingStage++;
      });
    },
    filterGridData() {
      // filter by namefilter
      var filtered = this.gridData.data.slice();

      // Global filter store id
      if (
        this.globalFilter &&
        this.globalFilter.stores &&
        this.globalFilter.companyId == this.$store.state.companyId
      ) {
        let selectedStoreIds = [];
        for (let i in this.globalFilter.stores) {
          if (this.globalFilter.stores[i].selected) {
            selectedStoreIds.push(this.globalFilter.stores[i].id);
          }
        }
        filtered = filtered.filter((e) => selectedStoreIds.includes(e.storeId));
      }

      if (this.nameFilter !== "") {
        filtered = filtered.filter(
          (e) =>
            (
              e.lastName.toLowerCase() +
              " " +
              e.firstName.toLowerCase()
            ).indexOf(this.nameFilter.toLowerCase()) > -1
        );
      }

      if (this.unhidingMultipleEmployees) {
        filtered = filtered.filter((e) => e.isHidden);
      } else {
        // no errors
        if (!this.showOkEmployee) {
          filtered = filtered.filter(
              (e) => (e.metadata && e.metadata.warnings.length > 0) || e.isHidden
          ); // Wenn OK Mitarbeiter nicht gezeigt werden sollen, dann zeige alle wo Warnungen existieren oder die versteckt sind
        }
        // infos
        if (!this.showEmployeeWithInfo) {
          filtered = filtered.filter(
              (e) => (e.metadata && e.metadata.severity !== "info") || e.isHidden
          ); // Wenn Info Mitarbeiter nicht angezeigt werden sollen, dann erlaube alle und die versteckten
        }
        // warnings
        if (!this.showEmployeeWithWarningOrDanger) {
          filtered = filtered.filter(
              (e) =>
                  (e.metadata &&
                      e.metadata.severity !== "warning" &&
                      e.metadata.severity !== "danger") ||
                  e.isHidden
          );
        }
        if (!this.showInactiveEmployees || this.hidingMultipleEmployees) {
          filtered = filtered.filter((e) => !e.isHidden);
        }
      }
      console.log("WIRD GEFILTERT");

      this.dataFiltered = filtered;
      //this.dataFiltered.sort((a, b) => (a.firstName > b.firstName) ? 1 : -1)
      this.dataFiltered.sort((a, b) => {
        var levels = {
          none: 0,
          ok: 0,
          info: 1,
          warning: 2,
          danger: 3,
        };
        var levelA = a.metadata != undefined ? levels[a.metadata.severity] : 0;
        var levelB = b.metadata != undefined ? levels[b.metadata.severity] : 0;
        if (levelA > levelB) {
          return 1;
        } else if (levelA < levelB) {
          return -1;
        }
        return 0;
      });
      return this.dataFiltered;
    },
    ToggleDropDown: function (
      $event,
      selection,
      offsetx,
      offsety,
      pointerOffset
    ) {
      if (!selection || selection.length === 0) return;
      var event = $event;
      var box = event.currentTarget.getBoundingClientRect();
      var dropdowns = this.dropdowns;
      /* if (selection === this.iconsNav && window.innerWidth <= 750) {
                    selection = this.iconsNavCollapsed;
                } */
      this.dropdowns.push({
        items: selection,
        el: event.currentTarget,
        remainAfterSelect: true,
        toggled: true,
        offsetx: offsetx,
        offsety: offsety,
        posx: box.left + offsetx,
        posy: box.bottom + offsety,
        pointerOffset: pointerOffset,
        onceSelected: function ($eventInner, item, dropdown) {
          if (item.items && item.items.length > 0) {
            var eventInner = $eventInner;
            var boxInner = eventInner.currentTarget.getBoundingClientRect();
            var temp = {
              items: item.items,
              el: eventInner.currentTarget,
              offsetx: offsetx + 130,
              offsety: offsety - 30,
              posx: box.left + offsetx + 130,
              toggled: true,
              posy: boxInner.bottom - 30,
              pointerDirection: 3,
              pointerOffset: pointerOffset,
              parentCallback: function () {
                dropdown.toggled = false;
              },
            };
            dropdowns.push(temp);
          } else {
            console.log("SELECTED:");
            console.log(item);
            item.callback();
          }
        },
      });
    },
    onImageReady() {
      this.scaleStr = "1";
      this.rotation = 0;
      this.hasImage = true;
    },
    commitCurrentEmployeeToStore() {
      this.$store.commit("setEmployeeMapEntry", this.employee);
    },
    saveClicked() {
      var view = this;
      var img = this.$refs.vueavatar.getImageScaled();
      var urlString = img.toDataURL();
      var seconds = new Date().getTime() / 1000;
      var imgFile = this.$helpers.dataURLtoFile(
        urlString,
        "profile_picture_" + seconds + ".jpg"
      );

      this.uploading = true;
      this.setEmployeePicture(this.employee.id, imgFile)
        .then((result) => {
          // TODO: check if data (in component and vuex) is properly updated for frontend
          if (view.employee != undefined) {
            view.employee.employeePicture = result.data.data;
            view.commitCurrentEmployeeToStore();
          }
          for (var i = 0; i < view.employees.length; i++) {
            if (view.employees[i].id == view.employee.id) {
              view.employees[i].employeePicture = result.data.data;
            }
          }
          for (var i = 0; i < view.dataFiltered.length; i++) {
            if (view.dataFiltered[i].id == view.employee.id) {
              view.dataFiltered[i].employeePicture = result.data.data;
            }
          }
          view.$store.commit("setImgCacheTimestamp", {
            imgCacheTimestamp: Date.now(),
          });
          view.uploading = false;
          view.hasImage = false;
          view.imageUploadModal = false;
          view.uploadedImage = null;
          requestAnimationFrame(function () {
            view.$forceUpdate();
          });
        })
        .catch((error2) => {
          view.uploading = false;
          view.hasImage = false;
          view.imageUploadModal = false;
          view.uploadedImage = null;
          console.log(error2);
        });
    },
    prepareStoreEmployees(empls, employeeIdToSelect = null, force = false) {
      var app = this;
      var processed = [];
      let mdPersonnelIds = [];
      app.mdPersonnelIdDuplicates = [];
      let ibans = [];
      app.ibanDuplicates = [];
      let svnrs = [];
      app.svnrDuplicates = [];
      empls.forEach((e) => {
        if (employeeIdToSelect != null) {
          if (employeeIdToSelect == e.id) e.selected = true;
          if (employeeIdToSelect != e.id) e.selected = false;
        } else {
          e.selected = false;
        }

        if (e.mdPersonnelId) {
          if (!mdPersonnelIds.includes(e.mdPersonnelId)) {
            mdPersonnelIds.push(e.mdPersonnelId);
          } else if (!app.mdPersonnelIdDuplicates.includes(e.mdPersonnelId)) {
            app.mdPersonnelIdDuplicates.push(e.mdPersonnelId);
          } else {
            // do nothing
          }
        }

        if (e.iban) {
          let iban = e.iban.replace(/\s/g, "");
          if (!ibans.includes(iban)) {
            ibans.push(iban);
          } else if (!app.ibanDuplicates.includes(iban)) {
            app.ibanDuplicates.push(iban);
          } else {
            // do nothing
          }
        }

        if(e.socialsecurity) {
          let svnr = e.socialsecurity.replace(/\s/g, "");
          if (!svnrs.includes(svnr)) {
            svnrs.push(svnr);
          } else if (!app.svnrDuplicates.includes(svnr)) {
            app.svnrDuplicates.push(svnr);
          } else {
            // do nothing
          }
        }
      });
      app.employees = empls;
      app.employees.sort((a, b) => {
        if (a.lastName > b.lastName) return 1;
        if (b.lastName > a.lastName) return -1;
        if (a.lastName == b.lastName) {
          if (a.firstName > b.firstName) return 1;
          if (b.firstName > a.firstName) return -1;
        }
        return 0;
      });
      app.gridData.data = app.employees;
      app.dataFiltered = app.gridData.data.slice();
      app.dataFiltered.sort((a, b) => {
        if (a.lastName > b.lastName) return 1;
        if (b.lastName > a.lastName) return -1;
        if (a.lastName == b.lastName) {
          if (a.firstName > b.firstName) return 1;
          if (b.firstName > a.firstName) return -1;
        }
        return 0;
      });
      this.updateEmployeeGridDataMetadata();
      var filtered = this.filterGridData();
      if (app.employees.length > 0) {
        if (
          employeeIdToSelect != null &&
          app.employee &&
          app.employee.id != undefined &&
          employeeIdToSelect != app.employee.id
        )
          app.employee.selected = false;
        if (employeeIdToSelect) {
          for (var i = 0; i < filtered.length; i++) {
            if (filtered[i].id == employeeIdToSelect) {
              console.log("Employee ID found and selected!");
              app.employee = filtered[i];
            }
          }
        } else {
          console.log(
            "Employee ID to select not set or not found, selecting first employee"
          );
          console.log(filtered);
          app.employee = filtered[0];
        }
        app.savedEmployee = Object.assign({}, app.employee);
        app.employee.selected = true;
        this.$forceUpdate();
      }
    },
    getEmployeeBySocialInsuranceNumber(snnr) {
      let empl;
      for (let e of this.employees) {
        let sn = e.socialsecurity;
        if (!sn) sn = "";
        sn = sn.trim();
        sn = sn.replaceAll(' ', '');
        if (sn.length == 4) {
          // get 10digit sn number
          let dob = e.dob;
          if (dob) {
            sn += this.$helpers.extractDateString(dob, "DDMMYY");
          }
        }
        // if all needed data is present sn should be 10 digit now, if not a exact match can not be done!
        if (sn.length == 10) {
          if (snnr === sn) {
            empl = e;
            break;
          }
        }
      }
      return empl;
    },
    updateEmployeeGridDataMetadata() {
      if (this.dataFiltered) {
        this.dataFiltered.forEach((empl) => {
          empl["metadata"] = this.checkEmployeeData(empl, false);
        });
      }
      this.filterGridData();
      if (this.$refs.grid != undefined) this.$refs.grid.$forceUpdate();
    },
    UploadImage(forNewEmployee) {
      this.imageForNewEmployee = forNewEmployee;
      this.imageUploadModal = true;
    },
    GetProfilePictureUrl(employee, size = null, urlOnly = false) {
      // TODO: create proper URL when profile pic data is present on ML
      if (employee.employeePicture_fileId == null) {
        var port = location.port;
        var url =
          location.protocol +
          "//" +
          location.hostname +
          "/img/avatar_white.png";
        if (port != "80" && port != "443") {
          url =
            location.protocol +
            "//" +
            location.hostname +
            ":" +
            location.port +
            "/img/avatar_white.png";
        }

        if (urlOnly) return url;
        return "var(--contrast-1) no-repeat center/cover url(/img/avatar.png)";
      } else {
        var imgUrl =
          employee.employeePicture_server +
          "/api/file?id=" +
          employee.employeePicture_fileId +
          "&accessToken=" +
          employee.employeePicture_accessToken +
          "&s=" +
          this.imgCacheTimestamp;
        if (size) imgUrl = imgUrl + "&thumb=" + size;
        if (urlOnly) return imgUrl;
        return "var(--contrast-1) no-repeat center/cover  url(" + imgUrl + ")";
      }
    },
    setEmployeePicture(employeeId, profilePictureFile) {
      console.log("SET EMPLOYEE PICTURE CALL");

      let view = this;
      let url = "/api/sec/employee/picture/uploadProfilePicture";
      url = this.$helpers.appendParam(
        "companyId",
        this.$store.state.companyId,
        url,
        "/api/sec/employee/picture/uploadProfilePicture"
      );
      let formData = new FormData();
      formData.append("employeeId", employeeId);
      formData.append("file", profilePictureFile);

      return this.axios({
        method: "post",
        headers: {
          AUTHORIZATION: "Bearer " + this.$store.state.jwt,
          "Content-Type": "multipart/form-data",
        },
        url: url,
        data: formData,
      }).catch((error) =>
        Promise.reject({
          msg: "Fehler beim Setzen des Profilbildes",
        })
      );
    },
    hideEmployee(employeeId) {
      let view = this;
      let url = "/api/sec/employee/hide";
      url = this.$helpers.appendParam(
        "companyId",
        this.$store.state.companyId,
        url,
        "/api/sec/employee/hide"
      );
      let params = {
        employeeId: employeeId,
      };
      view
        .axios({
          method: "put",
          params: params,
          headers: {
            AUTHORIZATION: "Bearer " + view.$store.state.jwt,
            "Content-Type": "multipart/form-data",
          },
          url: url,
        })
        .then((response) => {
          view.$helpers.success("Erfolg", "Mitarbeiter wurde versteckt");
          for (let i in view.employees) {
            if (view.employees[i].id == employeeId) {
              view.employees[i].isHidden = true;
            }
          }
          for (let i in view.employeeArray) {
            if (view.employeeArray[i].id == employeeId) {
              view.employeeArray[i].isHidden = true;
            }
          }
          view.filterGridData();
        })
        .catch((error) => {
          console.log(error);
          view.$helpers.error(
            "Fehler",
            "Fehler beim Verstecken des Mitarbeiters"
          );
        });
    },
    unhideEmployee(employeeId) {
      let view = this;
      let url = "/api/sec/employee/unhide";
      url = this.$helpers.appendParam(
        "companyId",
        this.$store.state.companyId,
        url,
        "/api/sec/employee/unhide"
      );
      let params = {
        employeeId: employeeId,
      };
      view
        .axios({
          method: "put",
          params: params,
          headers: {
            AUTHORIZATION: "Bearer " + view.$store.state.jwt,
            "Content-Type": "multipart/form-data",
          },
          url: url,
        })
        .then((response) => {
          view.$helpers.success(
            "Erfolg",
            "Mitarbeiter wird nicht mehr versteckt"
          );

          for (let i in view.employees) {
            if (view.employees[i].id == employeeId) {
              view.employees[i].isHidden = false;
            }
          }
          for (let i in view.employeeArray) {
            if (view.employeeArray[i].id == employeeId) {
              view.employeeArray[i].isHidden = false;
            }
          }
          view.filterGridData();
        })
        .catch((error) => {
          console.log(error);
          view.$helpers.error(
            "Fehler",
            "Fehler beim Anzeigen des Mitarbeiters"
          );
        });
    },
    hideMultipleEmployees(employeeIds) {
      let view = this;
      let url = "/api/sec/employee/hide/multiple";
      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, "/api/sec/employee/hide/multiple");
      let params = {
        employeeIds: employeeIds,
      };
      view
        .axios({
          method: "put",
          params: params,
          headers: {
            AUTHORIZATION: "Bearer " + view.$store.state.jwt,
            "Content-Type": "multipart/form-data",
          },
          url: url,
        })
        .then((response) => {
          view.$helpers.success("Erfolg", "Mitarbeiter wurden versteckt");
          for (let i in view.employees) {
            if (employeeIds.includes(view.employees[i].id)) {
              view.employees[i].isHidden = true;
            }
          }
          for (let i in view.employeeArray) {
            if (employeeIds.includes(view.employeeArray[i].id)) {
              view.employeeArray[i].isHidden = true;
            }
          }
          view.filterGridData();
        })
        .catch((error) => {
          console.log(error);
          view.$helpers.error(
            "Fehler",
            "Fehler beim Verstecken der Mitarbeiter"
          );
        });
    },
    unhideMultipleEmployees(employeeIds) {
      let view = this;
      let url = "/api/sec/employee/unhide/multiple";
      url = this.$helpers.appendParam(
        "companyId",
        this.$store.state.companyId,
        url,
        "/api/sec/employee/unhide/multiple"
      );
      let params = {
        employeeIds: employeeIds,
      };
      view
        .axios({
          method: "put",
          params: params,
          headers: {
            AUTHORIZATION: "Bearer " + view.$store.state.jwt,
            "Content-Type": "multipart/form-data",
          },
          url: url,
        })
        .then((response) => {
          view.$helpers.success(
            "Erfolg",
            "Mitarbeiter werden nicht mehr versteckt"
          );

          for (let i in view.employees) {
            if (employeeIds.includes(view.employees[i].id)) {
              view.employees[i].isHidden = false;
            }
          }
          for (let i in view.employeeArray) {
            if (employeeIds.includes(view.employeeArray[i].id)) {
              view.employeeArray[i].isHidden = false;
            }
          }
          view.filterGridData();
        })
        .catch((error) => {
          console.log(error);
          view.$helpers.error(
            "Fehler",
            "Fehler beim Anzeigen der Mitarbeiter"
          );
        });
    },

    deleteEmployeePicture(employeeId) {
      let view = this;
      window.app.popupalerts.push({
        header: "Mitarbeiter Bild löschen",
        description: "Soll das Bild wirklich gelöscht werden?",
        hidden: false,
        callbackAccept: function (popup) {
          popup.loading = true;

          let url = "/api/sec/employee/picture";

          let params = {
            storeId: view.$store.state.storeId,
            id: employeeId,
          };

          view
            .axios({
              method: "delete",
              params: params,
              headers: {
                AUTHORIZATION: "bearer " + view.$store.state.jwt,
                "Content-Type": "multipart/form-data",
              },
              url: url,
            })
            .then((response) => {
              popup.loading = false;
              popup.hidden = true;
              view.employee.employeePicture = null;
              view.commitCurrentEmployeeToStore();

              view.$helpers.success(
                "Erfolg",
                "Bild wurde erfolgreich gelöscht"
              );
            })
            .catch((error) => {
              popup.loading = false;
              popup.hidden = true;
              view.$helpers.error("Fehler", "Fehler beim Löschen des Bildes");
            });
        },
        callbackCancel: function (popup) {
          popup.loading = false;
          popup.hidden = true;
        },
      });
    },
    ChangeEmployee(employee) {
      for (var x in this.gridData.data) {
        this.gridData.data[x].selected = false;
      }

      this.employee = employee;
      this.savedEmployee = Object.assign({}, this.employee);
      this.employee.selected = true;
      this.checkEmployeeData(this.employee, true);
      //this.selectedPage = 'coredata';
    },
    SelectEmployeeForMassUnhide(employee) {
      employee.selectedForUnhide = employee.selectedForUnhide ? false : true;
      this.ChangeEmployee(employee);
    },
    SelectEmployeeForMassHide(employee) {
      employee.selectedForHide = employee.selectedForHide ? false : true;
      this.ChangeEmployee(employee);
    },
    selectEmployeeWithId(id) {
      let empl = this.employeeWithId(id);
      this.ChangeEmployee(empl);
      this.creatingNewEmployee = false;
    },
    employeeWithId(id) {
      let empl;
      this.employees.forEach((e) => {
        if (e.id == id) {
          empl = e;
        }
      });
      return empl;
    },
    checkEmployeeData(employee, updateUi) {
      var active = employee.active;
      var warnings = [];
      var severity = "none";
      var link = "";
      var color = "var(--col-ok)";
      var buttonVariants = this.defaultButtonVariants();

      buttonVariants.socialinsuranceregistration = "success";
      severity = "success";

      // TODO: do any checks for employee warnings here
      if (!this.isIbanValid(employee.iban)) {
        severity = "info";
        buttonVariants.coredata = "info";
        warnings.push('Die IBAN des Mitarbeiters ist nicht korrekt.');
      }
      if (this.ibanDuplicates.includes(employee.iban ? employee.iban.replace(/\s/g, '') : "")) {
        severity = "info";
        buttonVariants.coredata = "info";
        warnings.push('Die IBAN des Mitarbeiters ist ein Duplikat.');
      }
      if (!this.isSvnrValid(employee)) {
        severity = "info";
        buttonVariants.coredata = "info";
        warnings.push('Die SVNR des Mitarbeiters ist nicht korrekt.');
      }
      if (this.svnrDuplicates.includes(employee.socialsecurity ? employee.socialsecurity.replace(/\s/g, '') : "")) {
        severity = "info";
        buttonVariants.coredata = "info";
        warnings.push('Die SV Nummer des Mitarbeiters ist ein Duplikat.');
      }
      if (this.mdPersonnelIdDuplicates.includes(employee.mdPersonnelId)) {
        severity = "info";
        buttonVariants.coredata = "info";
        warnings.push('Die Personalnummer des Mitarbeiters ist ein Duplikat.');
      }
      if (!employee.storeId) {
        severity = "info";
        buttonVariants.coredata = "info";
        warnings.push('Der Mitarbeiter ist keiner Kostenstelle zugeordnet.');
      }
      if (!employee.minimalDatafieldsNotNull) {
        severity = "info";
        buttonVariants.coredata = 'info';
        warnings.push('Der Mitarbeiter benötigt Daten.');
      }
      if (!this.employeeHasCurrentOrFutureContract(employee)) {
        severity = "warning";
        buttonVariants.contracts = "warning";
        warnings.push('Der Mitarbeiter hat keine aktuellen oder zukünftigen Arbeitsvertrag.');
      }
      if (!employee.socialInsuranceRegistered) {
        severity = "warning";
        buttonVariants.socialinsuranceregistration = "warning";
        warnings.push('Der Mitarbeiter hat keine aktuelle ÖGK Meldung.');
      }
      if (employee.isHidden) {
        severity = "inactive";
        //buttonVariants.socialinsuranceregistration = 'info';
      }

      var icon = "";
      switch (severity) {
        case "info":
          color = "var(--col-info)";
          icon = "info-circle";
          break;
        case "warning":
          color = "var(--col-warning)";
          icon = "exclamation-circle";
          break;
        case "danger":
          color = "var(--col-danger)";
          icon = "exclamation";
          break;
        case "inactive":
          color = "var(--contrast-1)";
          icon = "info-circle";
      }
      var data = {
        severity: severity,
        warnings: warnings,
        icon: icon,
        link: link,
        color: color,
        buttonVariants: buttonVariants,
      };

      if (updateUi) {
        this.buttonVariants = this.defaultButtonVariants();
        this.buttonVariants = buttonVariants;
      }
      return data;
    },
    defaultButtonVariants() {
      return {
        coredata: "success",
        socialinsuranceregistration: "success",
        contracts: "success",
        datainquiry: "success",
        documents: "success",
        wagedata: "success",
      };
    },
    updateSeverity(curVal, newVal) {
      var sev = "info";
      if (curVal == "none" || curVal == "info") return newVal;
      if (curVal == "warning" && newVal == "danger") return newVal;
      if (curVal == "danger") return curVal;
      return newVal;
    },
    employeeWarningsTooltipText() {
      var warnings = this.employeeWarnings.warnings;
      var txt = "";
      for (var i = 0; i < warnings.length; i++) {
        if (i > 0) txt += " | ";
        txt += warnings[i];
      }
      return txt;
    },
    Init(items, config) {
    },
    hideModal(ref) {
      this.$refs[ref].hide();
    },
    showModal(ref) {
      this.$refs[ref].show();
    },
    requestEmailForNewEmployee() {
      let view = this;
      let dataEntries = [
        {
          label: "E-Mail Adresse des Mitarbeiters: ",
          key: "email",
          hidden: false,
          type: "text",
          value: "",
          length: 128,
          styles: {width: "500px"},
        },
        {flexDivider: true, styles: {"margin-bottom": "23px"}},
      ];

      this.$helpers.OpenPopup({
        elRelative: event.currentTarget,
        type: "datafill",
        pointerDirection: 0,
        componentState: {
          entries: dataEntries,
          selectCallback: function (data) {
            view.checkEmail(data.email);
          },
        },
        header: "E-Mail Adresse prüfen",
        centered: true,
        remainAfterSelect: false,
        expanded: true,
      });
    },
    saveEmployeeClicked(generateChangePdf = false) {
      if (this.creatingNewEmployee) {
        this.$refs.compcoredata.createEmployee();
      } else if (this.editing) {
        this.saveCurrentEmployeeCoredata(generateChangePdf);
      }
    },
    checkEditingMode(callback) {
      if (this.editing || this.creatingNewEmployee) {
        if (
          this.$refs.compcoredata != undefined &&
          this.$refs.compcoredata.pendingChanges
        ) {
          let desc =
            "Sie haben Ihre Änderungen noch nicht gespeichert. Möchten Sie die Änderungen speichern oder verwerfen?";
          let view = this;
          window.app.popupalerts.push({
            header: "Daten noch nicht gespeichert",
            number: null,
            description: desc,
            hidden: false,
            callbackAccept: function (popup) {
              popup.hidden = true;
              view.checkEmail(
                view.$refs.compcoredata.savedEmployee.email,
                view.$refs.compcoredata.savedEmployee.id,
                true,
                view.saveEmployeeClicked
              );
              callback();
            },
            acceptLabel: "Speichern",
            callbackCancel: function (popup) {
              popup.hidden = true;
              view.$refs.compcoredata.cancelEditMode();
              callback();
            },
            cancelLabel: "Verwerfen",
            disableCancel: false,
          });
        } else {
          this.$refs.compcoredata.cancelEditMode();
          callback();
        }
      } else {
        callback();
      }
    },
    saveDataInquiry(inquiry) {
      let view = this;
      let url = "/sec/employee/datainquiry/update";
      url = this.$helpers.appendParam(
        "companyId",
        this.$store.state.companyId,
        url,
        "/sec/employee/datainquiry/update"
      );
      url = this.$helpers.appendParam(
        "employeeId",
        this.employee.id,
        url,
        "/sec/employee/datainquiry/update"
      );

      this.api
        .post(url, inquiry)
        .then((response) => {
          //this.refresh(inquiry.employeeId, true, true);
          view.dataInquiryMode = 0;
          view.selectedInquirySet = false;
          view.$helpers.success(
            "Daten gespeichert",
            "Die persönlichen Mitarbeiterdaten wurden gespeichert"
          );
          view.$refs.compdatainquiry.refresh();
        })
        .catch((error) => {
          console.log("ERROR");
        });
    },
    checkEmail(
      email,
      emplId = null,
      onSave = false,
      callback = null,
      callbackParam = null
    ) {
      let empl = null;
      if (
        email != null &&
        email != undefined &&
        email != "" &&
        !this.$helpers.isBlank(email)
      ) {
        let email_lower = email.toLowerCase();
        this.employeeArray.forEach((e) => {
          let e_email = e.email ? e.email.toLowerCase() : e.email;
          if (e_email == email_lower && e.id != emplId) empl = e;
        });
      }
      if (onSave) {
        if (empl == null && callback != null) {
          if (callbackParam) {
            callback(callbackParam);
          } else {
            callback();
          }
        } else {
          let desc =
            'Die E-Mail Adresse ist bereits für Mitarbeiter/in "' +
            empl.lastName +
            " " +
            empl.firstName +
            " vergeben. Mehrere Mitarbeiter mit der gleichen E-Mail Adresse sind nicht erlaubt.";
          let view = this;
          window.app.popupalerts.push({
            header: "E-Mail Adresse bereits vergeben",
            number: null,
            description: desc,
            hidden: false,
            callbackAccept: function (popup) {
              popup.hidden = true;
            },
            disableCancel: true,
          });
        }
      } else {
        if (empl == null) {
          this.initNewEmployee(email);
        } else {
          let desc =
            'Die E-Mail Adresse ist bereits für "' +
            empl.lastName +
            " " +
            empl.firstName +
            '" vergeben. Mehrere Mitarbeiter mit der gleichen E-Mail Adresse sind nicht erlaubt.';
          let view = this;
          window.app.popupalerts.push({
            header: "E-Mail Adresse bereits vergeben",
            number: null,
            description: desc,
            hidden: false,
            callbackAccept: function (popup) {
              view.ChangeEmployee(empl);
              popup.hidden = true;
            },
            disableCancel: true,
          });
        }
      }
    },
    initNewEmployee(email = null) {
      this.selectedPage = "coredata";
      this.newEmployee = {
        firstName: "",
        lastName: "",
        dob: null,
        socialsecurity: null,
      };

      this.creatingNewEmployee = true;
      this.prevEmployee = this.employee;
      this.employee = this.newEmployee;
    },
    resetEmployee() {
      this.employee = this.prevEmployee;
      this.newEmployee = {
        firstName: "",
        lastName: "",
        dob: null,
        socialsecurity: null,
      };
    },
    employeeCreationCancelled() {
      this.resetEmployee();
      this.creatingNewEmployee = false;
      this.editing = false;

      this.$forceUpdate();
    },
    createdNewEmployee(newEmployee) {
      let view = this;
      this.creatingNewEmployee = false;
      this.refresh(newEmployee.id, true, true);
    },
    openDeleteEmployeeModal(employee) {
      let view = this;
      let description =
        "Soll der Mitarbeiter <b>" +
        employee.firstName +
        " " +
        employee.lastName +
        "</b> wirklich gelöscht werden? <br/>" +
        "<b>Achtung:</b> <ul>" +
        "<li>Es werden alle Daten, Schichten, Dokumente und Stempelzeiten des Mitarbeiters gelöscht</li>" +
        "<li>Dieser Prozess kann <b>nicht rückgängig</b> gemacht werden!</li></ul><br>" +
        "Bitte bestätigen Sie den Lösch Vorgang durch die Eingabe von '<b>DELETE</b>':";
      let dataEntries = [];
      dataEntries.push({
        label: "Löschen bestätigen",
        key: "confirmDeletion",
        hidden: false,
        type: "text",
        value: "",
      });

      view.$helpers.OpenPopup({
        type: "datafill",
        componentState: {
          entries: dataEntries,
          description: description,
          selectCallback: function (data) {
            if (data.confirmDeletion == "DELETE") {
              view.deleteEmployee(employee);
            } else {
              view.$helpers.error(
                "Fehler",
                "Löschen wurde nicht korrekt bestätigt"
              );
            }
          },
        },
        header: "Mitarbeiter endgültig löschen",
        expanded: true,
        centered: true,
      });
    },
    deleteEmployee(employee) {
      // TODO: delete employee
    },
    openEmployeeDeleteModal(event) {
      this.showModal("modal-employee-delete");
    },
    infoPopup(title, message) {
      this.infoTitle = title;
      this.infoMessage = message;
      this.showModal("modal-info");
    },
    labelForId(list, id) {
      list.forEach((c) => {
        if (c.id == id) return c.label_de;
      });
      return "";
    },
    previewPdf(doc, filename) {
      var data = doc.output("datauristring");
      this.modalPdfFilename = filename;
      this.modalPdf = data;
      this.modalPdfDoc = doc;
      this.showModal("modal-pdfpreview");
    },
    closePdfPreview() {
      this.hideModal("modal-pdfpreview");
      console.log("cleaning up after pdf preview close");
      this.modalPdf = "";
    },
    savePreviewedPdf() {
      this.modalPdfDoc.save(this.modalPdfFilename);
    },
    openPreviewedPdf() {
      this.modalPdfDoc.output("dataurlnewwindow");
    },
    OpenDisplayMenuDetails($event) {
      let view = this;
      this.$helpers.OpenPopup({
        type: "employeedisplayoptions",
        componentState: {
          showOkEmployee: view.showOkEmployee,
          toggleShowOkEmployee: () => {
            view.showOkEmployee = !view.showOkEmployee;
          },
          showEmployeeWithInfo: view.showEmployeeWithInfo,
          toggleShowEmployeeWithInfo: () => {
            view.showEmployeeWithInfo = !view.showEmployeeWithInfo;
          },
          showEmployeeWithWarningOrDanger: view.showEmployeeWithWarningOrDanger,
          toggleShowEmployeeWithWarningOrDanger: () => {
            view.showEmployeeWithWarningOrDanger =
              !view.showEmployeeWithWarningOrDanger;
          },
          showInactiveEmployees: view.showInactiveEmployees,
          toggleShowInactiveEmployees: () => {
            view.showInactiveEmployees = !view.showInactiveEmployees;
          },
        },
        listStyle: true,
        noScroll: true,
        pointerDirection: 1,
        elRelative: $event.currentTarget,
        pointerOffset: -14,
      });
    },
    accountInfoRefreshed(account) {
      this.useraccount = account;
    },
    resetImportState() {
      this.importState = {
        stage: 0,
        eldaAccount: null,
        eldaAccounts: [],
        files: [],
        count: 0,
        checked: 0,
        processed: 0,
        importFiles: [],
        importPayload: {
          registrations: [],
        },
        uploadPayload: {
          files: [],
        },
        uploadFiles: [],
        registrationsEmployeeIdMap: {},
        results: {},
      };
    },
    openImportModal() {
      this.resetImportState();
      this.importLoading = false;
      this.importModal = true;
      this.$nextTick(function () {
        var dropZone = document.getElementById("dropzone");

        if (dropZone) {
          console.log(
            "Drop zone found, adding event listener for drag and drop"
          );
          dropZone.addEventListener("dragover", this.handleDragOver, false);
          dropZone.addEventListener("drop", this.filesDropped, false);
        }
      });
    },
    hideImportModal() {
      this.resetImportState();
      this.importLoading = false;
      this.importModal = false;
    },
    showFilePicker() {
      if (!this.importFilePickerOpening) {
        console.log("Pick files with filepicker");
        this.importFilePickerOpening = true;
        setTimeout(() => {
          // debounce multiple clicks to avoid multiple subsequent file input dialogs
          this.importFilePickerOpening = false;
        }, 1000);
        document.getElementById("fileImportInput").click();
      }
    },
    importNext() {
      if (
        this.importState.stage == 2 &&
        this.importState.eldaAccount.length > 0
      ) {
        console.log(this.importState);
        console.log("Preparing import stage 3");

        let ifiles = this.importState.importFiles.filter(
          (i) => i.eldaAccount === this.importState.eldaAccount
        );
        if (!Array.isArray(ifiles)) ifiles = [ifiles];
        this.importState.importFiles = ifiles;
        this.importState.stage = 3;
        console.log(this.importState);
        this.$forceUpdate();
        this.processImportFiles();
      }
    },
    filesSelected(files) {
      this.importFilePickerOpening = false;
      this.checkImportFiles(files);
    },
    filesDropped(evt) {
      evt.stopPropagation();
      evt.preventDefault();
      var dropZone = document.getElementById("dropzone");
      dropZone.removeEventListener("dragover", this.handleDragOver, false);
      dropZone.removeEventListener("drop", this.filesDropped, false);

      var droppedFiles = evt.dataTransfer.files; // FileList Objekt
      // console.log(droppedFiles);
      this.checkImportFiles(droppedFiles);
    },
    checkImportFiles(selectedFiles) {
      // console.log(selectedFiles);
      this.importLoading = true;
      this.importState.stage = 1;
      this.importState.files = selectedFiles;
      this.importState.count = selectedFiles.length;
      for (var i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        let filename = file.name;
        let type = file.type;
        // mdb files from elda software do not have a filetype, lets hope we can detect them properly with this check
        if (filename.startsWith("mbd_") && !filename.includes(".") && type === "") {
          const reader = new FileReader();
          reader.onload = (event) => {
            const fileContent = event.target.result;
            const allLines = fileContent.split(/\r\n|\n/);
            // console.log(allLines);
            let firstLine = allLines[0].trim();
            // console.log(firstLine);
            firstLine = firstLine.replace(/  +/g, " ");
            let flParts = firstLine.split(" ");
            // console.log(flParts);
            if (flParts.length >= 3 && flParts[0] === "***") {
              // get elda account of current file
              let eldaAcc = null;
              let employer = null;
              let protocolNum = null;
              for (var j = 0; (!eldaAcc || !employer || !protocolNum) && j < allLines.length; j++) {
                let line = allLines[j].trim();
                // console.log(line);
                line = line.replace(/  +/g, " ");
                if (line.includes("Beitrags-KtoNr:")) {
                  let accParts = line.split(":");
                  eldaAcc = accParts[1].trim();
                } else if (line.includes("Dienstgeber:")) {
                  employer = allLines[j + 1].trim();
                } else if (line.startsWith("Protokollnr")) {
                  protocolNum = line.split(" ")[1];
                }
              }
              if (eldaAcc && employer && protocolNum) {
                if (!this.importState.eldaAccounts.find((ea) => ea.id == eldaAcc)) {
                  this.importState.eldaAccounts.push({
                    id: eldaAcc,
                    key: eldaAcc,
                    value: eldaAcc,
                    label: eldaAcc + " (" + employer + ")",
                    valueENG: eldaAcc + " (" + employer + ")",
                    valueGER: eldaAcc + " (" + employer + ")",
                  });
                }
                if (!this.importState.eldaAccount) {
                  this.importState.eldaAccount = eldaAcc;
                }
                let cur = {code: flParts[1], protocolNumber: protocolNum, employer: employer, eldaAccount: eldaAcc, content: allLines, file: file, };
                this.importState.importFiles.push(cur);
              }
            }
            this.importState.checked++;
            this.checkFileCheckStatus();
          };

          reader.onerror = (event) => {
            alert(event.target.error.name);
          };

          reader.readAsText(file);
        } else {
          this.importState.checked++;
          this.checkFileCheckStatus();
        }
      }
    },
    selectedEldaAccountLabelForImport() {
      let label = "";
      if (this.importState.eldaAccount) {
        this.importState.eldaAccounts.forEach((ac) => {
          if (ac.id === this.importState.eldaAccount) label = ac.valueGER;
        });
      }
      return label;
    },
    checkFileCheckStatus() {
      if (this.importState.checked == this.importState.files.length) {
        // all files checked, importFiles list should be ready to proceed
        console.log("Candidate files for import:");
        console.log(this.importState.importFiles);
        this.importState.stage++;
      }
    },
    processImportFiles() {
      let files = this.importState.importFiles;
      let count = files.length;
      if (count > 0) {
        //registrationsEmployeeIdMap
        this.importLoadingText = "Verarbeite " + this.importState.importFiles.length + " Dateien...";

        files.forEach((f) => {
          switch (f.code) {
            case "M3":
              this.processM3(f);
              break;
            case "M4":
              this.processM4(f);
              break;
          }
        });
        console.log(this.importState);
        this.mergeAndCleanupRegistrationsAndDeregistrations();
        this.performSocialInsuranceRegistrationsImport();
      } else {
        this.importModal = false;
        this.resetImportState;
        alert(
          "Keine gültigen ELDA Dateien (Dateiname mbd_****** ohne Endung) ausgewählt."
        );
      }
    },
    mergeAndCleanupRegistrationsAndDeregistrations() {
      let importRegistrations = [];
      Object.keys(this.importState.registrationsEmployeeIdMap).forEach(
        (employeeId) => {
          let data = this.importState.registrationsEmployeeIdMap[employeeId];
          let importRegs = [];
          let type1 = [];
          let all = [];
          all = all.concat(data.deregistrations);
          all = all.concat(data.registrations);
          all.sort((a, b) => {
            if (a.date < b.date) return -1;
            if (a.date > b.date) return 1;
            return 0;
          });
          if (all.length > 0) {
            while (all.length > 0 && all[0].type == 2) {
              this.importState.results[all[0].protocolNumber]["Info"] =
                "Abmeldung ohne vorhergehende Anmeldung kann nicht automatisiert importiert werden.";
              // deregistration can not be imported without leading registration, thus first element can only be a registration
              all.splice(0, 1);
            }
            for (var i = 0; i < all.length; i++) {
              let cur = all[i];
              if (cur.type == 2) {
                // deregistration
                all[i - 1].deregistrationDate = cur.date;
                this.importState.results[cur.protocolNumber]["Info"] =
                  "Abmeldedatum wurde bei Anmeldung mit Protokollnummer " +
                  all[i - 1].protocolNumber +
                  " angehängt.";
                this.importState.results[cur.protocolNumber]["Status"] =
                  all[i - 1].protocolNumber;
              } else if (cur.type == 1) {
                cur.registrationDate = cur.date;
              }
            }
            type1 = all.filter((a) => a.type == 1); // deregistrations should now be saved along with the belonging registration, thus filter for type 1
          }

          type1.forEach((r) => {
            console.log(r);
            let payload = {
              mode: "register",
              employeeId: data.employee.id,
              registrationDate: r.registrationDate.format("YYYY-MM-DD"),
              protocolNumber: r.protocolNumber,
            };
            if (r.deregistrationDate) {
              payload.deregistrationDate =
                r.deregistrationDate.format("YYYY-MM-DD");
            }
            importRegs.push(payload);
          });
          importRegistrations = importRegistrations.concat(importRegs);
          data.importRegistrations = importRegs;
        }
      );
      this.importState.importPayload.registrations = importRegistrations;
    },
    performSocialInsuranceRegistrationsImport() {
      if (this.importState.importPayload.registrations.length > 0) {
        this.importLoadingText = "Anmeldungen werden gespeichert...";
        this.importState.stage = 4; // create registrations via API
        let payload = this.importState.importPayload;
        let url = "/sec/employee/socialinsuranceregistration/multi";
        url = this.$helpers.appendParam(
          "companyId",
          this.$store.state.companyId,
          url,
          "/sec/employee/socialinsuranceregistration/multi"
        );
        this.api
          .post(url, payload)
          .then((response) => {
            console.log(response);
            let mapping = response.data;
            if (mapping.length == 0) {
              console.log("Files include no importable registrations");
              this.importLoading = false;
              this.importState.stage = -1;
            } else {
              let keys = Object.keys(mapping);
              let payload = {};
              keys.forEach((protocolNumber) => {
                if (this.importState.results[protocolNumber] === undefined) {
                  this.importState.results[protocolNumber] = [];
                }
                this.importState.results[protocolNumber]["Status"] =
                  "importiert";
                this.importState.results[protocolNumber]["Datei Status"] =
                  "Upload gestartet";
                let socialInsuranceRegistrationId = mapping[protocolNumber];
                let file = this.importState.importFiles.find(
                  (f) => f.protocolNumber == protocolNumber
                );
                if (file) {
                  file.socialInsuranceRegistrationId =
                    socialInsuranceRegistrationId;
                  this.importState.uploadFiles.push(file);
                } else {
                  this.importState.results[protocolNumber]["Datei Status"] =
                    "Upload nicht möglich";
                  this.importState.results[protocolNumber]["Info"] =
                    "Datei für Upload nicht gefunden";
                }
              });
              this.prepareUploadFilesPayload();
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        console.log("Files include no importable registrations");
        this.importLoading = false;
        this.importState.stage = -1;
      }
    },
    batchUploadImportedSocialInsuranceRegistrationFiles() {
      this.importLoadingText = "Lade Anmeldedateien hoch...";
      this.importState.stage = 5;
      let url = "/sec/employee/socialinsuranceregistration/batchprocess/registrationfiles";
      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, "/sec/employee/socialinsuranceregistration/batchprocess/registrationfiles");
      let payload = this.importState.uploadPayload;
      this.api.post(url, payload).then((response) => {
        console.log(response);
        this.importLoadingText =
          "Anmeldedateien werden am Server verarbeitet, bitte warten...";
        this.importState.stage = 6;
      });
    },
    prepareUploadFilesPayload() {
      console.log(
        "Reading " +
        this.importState.uploadFiles.length +
        " files as Base64 for upload..."
      );
      this.importState.uploadFiles.forEach((uf) => {
        const file = uf.file;
        let filename = file.name;
        let type = file.type;
        let resultFilename = filename + ".txt";
        const reader = new FileReader();
        reader.onload = (event) => {
          const fb64 = reader.result;
          let cur = {
            filename: resultFilename,
            base64: fb64,
            socialInsuranceRegistrationId: uf.socialInsuranceRegistrationId,
          };
          this.importState.uploadPayload.files.push(cur);
          this.checkIfUploadFilesPayloadIsReady();
        };

        reader.onerror = (event) => {
          alert(event.target.error.name);
        };

        reader.readAsDataURL(file);
      });
    },
    checkIfUploadFilesPayloadIsReady() {
      if (
        this.importState.uploadPayload.files.length ==
        this.importState.uploadFiles.length
      ) {
        // all files have been read as Base64 --> proceed with batch upload
        console.log("Batch file upload payload ready:");
        console.log(this.importState.uploadPayload);
        this.batchUploadImportedSocialInsuranceRegistrationFiles();
      }
    },
    processM3(fileInfo) {
      let result = {
        Datei: fileInfo.file.name,
        Protokollnummer: "",
        Code: "M3",
        Typ: "Anmeldung",
        SVNR: "",
        Mitarbeiter: "",
        Datum: "",
        Status: "nicht importiert",
        "Datei Status": "nicht hochgeladen",
        Info: "",
      };
      let data = {type: 1, protocolNumber: fileInfo.protocolNumber};
      for (var i = 0; i < fileInfo.content.length; i++) {
        let line = fileInfo.content[i].trim();
        line = line.replace(/  +/g, " ");
        if (line.startsWith("Protokollnr")) {
          data.protocolNumber = line.split(" ")[1].trim();
        }
        if (line.startsWith("VSNR")) {
          data.socialinsurance = line.split(" ")[1].trim();
          data.lastName = fileInfo.content[i + 1].trim();
          data.firstName = fileInfo.content[i + 2].trim();
        }
        if (line.startsWith("Beschaeftigt ab:")) {
          data.date = this.$helpers.getMomentFromString(
            line.split(" ")[2].trim(),
            "DD.MM.YYYY"
          );
        }
      }
      if (data.socialinsurance && data.date && data.protocolNumber) {
        result["SVNR"] = data.socialinsurance;
        result["Protokollnummer"] = data.protocolNumber;
        result["Datum"] = data.date.format("DD.MM.YYYY");
        let employee = this.getEmployeeBySocialInsuranceNumber(
          data.socialinsurance
        );
        if (employee) {
          if (!this.importState.registrationsEmployeeIdMap[employee.id]) {
            this.importState.registrationsEmployeeIdMap[employee.id] = {
              employee: employee,
              registrations: [],
              deregistrations: [],
              importRegistrations: [],
            };
          }
          result["Mitarbeiter"] = employee.lastName + " " + employee.firstName;
          this.importState.registrationsEmployeeIdMap[
            employee.id
            ].registrations.push(data);
        } else {
          result["Status"] = "nicht importiert";
          result["Info"] = "Mitarbeiter kann nicht eindeutig zugeordnet werden";
        }
      }
      // console.log(result);
      this.importState.results[fileInfo.protocolNumber] = result;
    },
    processM4(fileInfo) {
      let result = {
        Datei: fileInfo.file.name,
        Protokollnummer: "",
        Code: "M4",
        Typ: "Abmeldung",
        SVNR: "",
        Mitarbeiter: "",
        Datum: "",
        Status: "nicht importiert",
        "Datei Status": "nicht hochgeladen",
        Info: "",
      };
      let data = {type: 2, protocolNumber: fileInfo.protocolNumber};
      for (var i = 0; i < fileInfo.content.length; i++) {
        let line = fileInfo.content[i].trim();
        line = line.replace(/  +/g, " ");
        if (line.startsWith("Protokollnr")) {
          data.protocolNumber = line.split(" ")[1].trim();
        }
        if (line.startsWith("VSNR")) {
          data.socialinsurance = line.split(" ")[1].trim();
          data.lastName = fileInfo.content[i + 1].trim();
          data.firstName = fileInfo.content[i + 2].trim();
        }
        if (line.startsWith("Ende Beschaeftigung:")) {
          data.date = this.$helpers.getMomentFromString(
            line.split(" ")[2].trim(),
            "DD.MM.YYYY"
          );
        }
      }
      if (data.socialinsurance && data.date && data.protocolNumber) {
        result["SVNR"] = data.socialinsurance;
        result["Protokollnummer"] = data.protocolNumber;
        result["Datum"] = data.date.format("DD.MM.YYYY");
        let employee = this.getEmployeeBySocialInsuranceNumber(
          data.socialinsurance
        );
        if (employee) {
          if (!this.importState.registrationsEmployeeIdMap[employee.id]) {
            this.importState.registrationsEmployeeIdMap[employee.id] = {
              employee: employee,
              registrations: [],
              deregistrations: [],
              importRegistrations: [],
            };
          }
          result["Mitarbeiter"] = employee.lastName + " " + employee.firstName;
          this.importState.registrationsEmployeeIdMap[
            employee.id
            ].deregistrations.push(data);
        } else {
          result["Status"] = "nicht importiert";
          result["Info"] = "Mitarbeiter kann nicht eindeutig zugeordnet werden";
        }
      }
      this.importState.results[fileInfo.protocolNumber] = result;
    },
    handleDragOver(evt) {
      evt.stopPropagation();
      evt.preventDefault();
      evt.dataTransfer.dropEffect = "copy";
    },

    OpenMenuDetails($event, state){
      let view = this;

      this.$helpers.OpenPopup({
        type: 'burgermenu',
        componentState: state,
        listStyle: true,
        noScroll: true,
        pointerDirection: 0,
        elRelative: $event.currentTarget,
        pointerOffset: -15,
      });
    },

    getMonthName(monthNumber) {
      const date = new Date();
      date.setMonth(monthNumber);

      return date.toLocaleString('de-at',{ month: 'short' });
    },
    modDate: function (method) {
      let view = this;

      if (method == "add") {
        this.selectedDate.add(1, "months");
        this.timeframeStart = this.selectedDate.clone().startOf("month");
        this.timeframeEnd = this.selectedDate.clone().endOf("month");
        this.$forceUpdate();
      } else if (method == "sub") {
        this.selectedDate.subtract(1, "months");
        this.timeframeStart = this.selectedDate.clone().startOf("month");
        this.timeframeEnd = this.selectedDate.clone().endOf("month");
        this.$forceUpdate();
      }
    },

    isIbanValid(input) {
      var CODE_LENGTHS = {
        AD: 24,
        AE: 23,
        AL: 28,
        AO: 25,
        AT: 20,
        AZ: 28,
        BA: 20,
        BE: 16,
        BF: 27,
        BG: 22,
        BH: 22,
        BI: 27,
        BJ: 28,
        BR: 29,
        BY: 28,
        CF: 27,
        CG: 27,
        CH: 21,
        CI: 28,
        CM: 27,
        CR: 22,
        CV: 25,
        CY: 28,
        CZ: 24,
        DE: 22,
        DK: 18,
        DO: 28,
        DZ: 24,
        EE: 20,
        EG: 29,
        ES: 24,
        FI: 18,
        FO: 18,
        FR: 27,
        GA: 27,
        GB: 22,
        GE: 22,
        GI: 23,
        GL: 18,
        GR: 27,
        GT: 28,
        HR: 21,
        HU: 28,
        IE: 22,
        IL: 23,
        IQ: 23,
        IR: 26,
        IS: 26,
        IT: 27,
        JO: 30,
        KW: 30,
        KZ: 20,
        LB: 28,
        LC: 32,
        LI: 21,
        LT: 20,
        LU: 20,
        LV: 21,
        LY: 25,
        MC: 27,
        MD: 24,
        ME: 22,
        MG: 27,
        MK: 19,
        ML: 28,
        MN: 20,
        MR: 27,
        MT: 31,
        MU: 30,
        MZ: 25,
        NI: 28,
        NL: 18,
        NO: 15,
        PK: 24,
        PL: 28,
        PS: 29,
        PT: 25,
        QA: 29,
        RO: 24,
        RS: 22,
        RU: 33,
        SA: 24,
        SC: 31,
        SD: 18,
        SE: 24,
        SI: 19,
        SK: 24,
        SM: 27,
        SN: 28,
        ST: 25,
        SV: 28,
        TL: 23,
        TN: 24,
        TR: 26,
        UA: 29,
        VA: 22,
        VG: 24,
        XK: 20,
      };
      if (!input) {
        return true;
      }
      var iban = String(input)
          .toUpperCase()
          .replace(/[^A-Z0-9]/g, ""), // keep only alphanumeric characters
          code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
          digits;
      // check syntax and length
      if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
        return false;
      }
      // rearrange country code and check digits, and convert chars to ints
      digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
        return letter.charCodeAt(0) - 55;
      });
      // final check
      return this.mod97(digits);
    },
    mod97(string) {
      var checksum = string.slice(0, 2),
          fragment;
      for (var offset = 2; offset < string.length; offset += 7) {
        fragment = String(checksum) + string.substring(offset, offset + 7);
        checksum = parseInt(fragment, 10) % 97;
      }
      return checksum === 1;
    },

    isSvnrValid(employee) {
      let view = this;
      // svnr available?
      if (!employee.socialsecurity) {
        return true;
      }
      // check store country
      let employeeStore = view.storeMap.find(s => s.id === employee.storeId);
      if (!employeeStore) {
        return true;
      }
      switch (employeeStore.country) {
        case 1:
          return view.isAustrianSvnrValid(employee.socialsecurity, employee.dob);
        default:
          return true;
      }
    },
    isAustrianSvnrValid(socialsecurity, dob) {
      socialsecurity = socialsecurity.replaceAll(" ", "");
      let formattedDob = new moment(dob).format("DDMMYY");

      if (socialsecurity.length !== 4 && socialsecurity.length !== 10) {
        return false;
      }
      if (socialsecurity.length == 4) {
        socialsecurity = socialsecurity + formattedDob;
      } else if (socialsecurity.length == 10) {
        // add dob compare check in case of 10 digit svnr
        let dateMoment = new moment(dob);
        let day = dateMoment.format("DD");
        let month = dateMoment.format("MM");
        let year = dateMoment.format("YY");
        if (socialsecurity.substring(4, 6) !== day) {
          return false;
        } else if (socialsecurity.substring(6, 8) !== month) {
          return socialsecurity.substring(6, 8) == "13" || socialsecurity.substring(6, 8) == "14" || socialsecurity.substring(6, 8) == "15";
        } else if (socialsecurity.substring(8, 10) !== year) {
          return false;
        }
      }

      // 1. do cross sum of positional multiplications
      //                            123X010170
      // positional multiplication: 379X584216
      // 2. sum mod 11
      // 3. compare result with check digit on position 4
      let val = 0;
      val += parseInt(socialsecurity[0]) * 3;
      val += parseInt(socialsecurity[1]) * 7;
      val += parseInt(socialsecurity[2]) * 9;
      val += parseInt(socialsecurity[4]) * 5;
      val += parseInt(socialsecurity[5]) * 8;
      val += parseInt(socialsecurity[6]) * 4;
      val += parseInt(socialsecurity[7]) * 2;
      val += parseInt(socialsecurity[8]) * 1;
      val += parseInt(socialsecurity[9]) * 6;
      val %= 11;

      return val === parseInt(socialsecurity[3]);
    },

    employeeHasCurrentOrFutureContract(employee) {
      var keys = this.contracts !== undefined && this.contracts[employee.id] !== undefined ? Object.keys(this.contracts[employee.id]) : [];
      for (var i = 0; i < keys.length; i++) {
        var key = keys[i];
        if (this.contracts[employee.id][key].remoteId == employee.id) {
          if ([1,2].includes(this.contractType(this.contracts[employee.id][key]))) {
            return true;
          }
        }
      }
      return false;
    },
    contractType(c) {
      var now = new Date();
      let fromDate = new Date(c.from);
      if (c.to) {
        let toDate = new Date(c.to);
        if (toDate < now) {
          return 0;
        }
      }
      if (fromDate < now) return 1;
      if (fromDate > now) return 2;
      return -1;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.dropdown-search.filters {
  height: 18px !important;
  display: block;
  margin-top: -4px;
  width: 100%;
}

.has-header > .scrollbar-container {
  height: calc(
    100% - var(--header-height) - var(--tab-menu-height) + 35px
  ) !important;
  overflow-x: hidden;
  top: var(--header-height);
  padding: 0px 14px 0px 0px;
}

.scrollbar-container > .row {
  height: 100%;
}

.btn-success {
  color: #fff;
  background-color: var(--ml);
  border-color: var(--ml);
}

.btn-success:focus,
.btn-success.focus {
  color: #fff;
  background-color: var(--ml);
  border-color: var(--ml);
  box-shadow: 0 0 0 0.2rem rgba(162, 16, 57, 0.5);
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: var(--ml);
  border-color: rgba(162, 16, 57, 0.5);
}

.btn-outline-success {
  color: var(--ml);
  border-color: var(--ml);
}

.btn-outline-success:hover {
  color: #fff;
  background-color: var(--ml);
  border-color: var(--ml);
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(162, 16, 57, 0.5);
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: var(--ml);
  border-color: rgba(162, 16, 57, 0.5);
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.import-modal {
  filter: none !important;
  position: absolute;
  box-sizing: content-box;
  top: 50;
  left: 50;
  width: 100%;
  height: 400px;
  border-radius: 5px;
  background: var(--bg);
}

.fas.fa-spinner {
  /* position: absolute;
    top: calc(100vh / 2 - 120px);
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto; */
  width: 60px;
  height: 60px;
  font-size: 60px;
  color: var(--ml) !important;
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  overflow: hidden !important;
}
</style>
