<template>
  <div class="input-component"  v-bind:class="{reversed: reversed}" v-bind:style="styleLabel">
    <div class="background" v-if="focused" v-on:click="ToggleItems(false)"></div>
    <buttonc v-if="!focused" type="input-dummy" v-bind:class="{placeholder: !input}" :label="input ? (DisplayLabel ? DisplayLabel(input) : input) : placeholder" @click="FocusInput(true)" :styleLabel="{color: textColor}"></buttonc>
    <input v-if="focused" v-model="input" v-bind:placeholder="placeholder" v-bind="inputOptions ? inputOptions : {}" @blur="ToggleItems($event, toggleFlag != null ? toggleFlag : false)" @focus="Focus" @keydown="CheckNavigation" @keyup="KeyUp" v-bind:class="{unfolded: hasItems}" v-bind:style="{color: textColor}"/>
    <div class="item-container" v-if="hasItems"  v-bind:style="{width: itemsWidth + 'px', top: itemsPosY + 'px', left: itemsPosX + 'px', height: itemsHeight > -1 ? itemsHeight + 'px' : '' }">
      <div class="item" v-for="(item, index) in itemsFiltered" :key="item.label + index" v-bind:class="{'item-with-desc': item.description, 'item-no-desc': !item.description, hover: selectedItem === item, selected: selectedItems.find(i => i === item)}" @click="SelectItem(item)" >
        <labelc :text="DisplayItemLabel(item)" :iconLeft="item.iconLeft" :iconRight="item.iconRight" class="item-label" />
        <labelc v-if="item.description" :text="DisplayItemDescription(item)" class="description"/>
      </div>
    </div>
    <div class="fa-stack clear" v-if="items && items.length > 0 && !noNull" v-on:click="ClearSelection">
      <i class="fas fa-circle fa-stack-2x"></i>
      <i class="fas fa-times fa-stack-2x"></i>
    </div>
    <div class="fa-stack" v-if="items && items.length > 0" v-on:click="FocusInput(true)">
      <i class="fas fa-circle fa-stack-2x"></i>
      <i class="fas fa-angle-down fa-stack-2x"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "inputc",
  data(){
    return {
      input: '',
      inputFilter: '',
      inputPrev: '',
      itemsWidth: 0,
      itemsPosX: 0,
      itemsPosY: 0,
      showItems: false,
      selectedTemp: null,
      selectedItems: [],
      selectedItem: null,
      focused: false,
      focusedEnterDelay: false,
      reversed: '',
      zIndex: '',
      itemsHeight: -1,
    }
  },
  props: {
    inputProp: [String, Number],
    placeholder: [String, Number],
    items: [Array],
    selectionInit: [Number, Object, Function],
    OnSelect: [Function],
    UpdateFixed: [Function],
    DisplayLabel: [Function],
    remainAfterSelect: [Boolean],
    selectMultiple: [Boolean],
    noNull: [Boolean],
    textColor: [String],
    styleLabel: [Object, String],
    maxItems:  [Number],
    inputOptions: [Object],
    toggleFlag: [Boolean],

  },
  watch: {
    inputProp: {
      immediate: true,
      handler(newVal, oldVal){

        if(!this.focused){
          this.input = newVal;
          this.inputPrev = newVal;
          this.Init(this.selectionInit);
        }
      }
    },
    selectionInit(selectionInit, oldVal) {
      this.Init(selectionInit);
    },
    items(newVal, oldVal) {
      this.Init(this.selectionInit);
    },
  },
  mounted(){
    this.Init(this.selectionInit);
  },
  computed: {
    inputFromSelected(){
      let view = this;
      let input = '';
      if(this.selectedItem) {
        input = this.DisplayItemLabel(this.selectedItem);
      } else {
        this.selectedItems.forEach(i => {
          input += view.DisplayItemLabel(i) + ' ';
        });
      }

      return input;
    },
    hasItems(){
      return this.focused && this.items && this.items.length > 0;
    },
    itemsFiltered(){
      let view = this;
      let items = this.items;
      if(!items) return [];
      if(!items.length) items = Object.values(items);
      if(this.selectMultiple) return items;
      return items.filter(i => view.DisplayItemLabel(i).toLowerCase()
          .indexOf(view.inputFilter.toLowerCase()) > -1 ||
          view.DisplayItemDescription(i).toLowerCase()
          .indexOf(view.inputFilter.toLowerCase()) > -1);
    }
  },
  updated(){
    let view = this;
    requestAnimationFrame(function(){
      if(view.selectMultiple) view.input = view.inputFromSelected;
    });
  },
  methods: {
    CheckLimits($event){
      if(!this.inputOptions) return;
      if(this.inputOptions.type === 'number'){
        let value = $event.target.value;
        if(value.length > 2 && value.substr(0, 1) === '0') $event.target.value = value.substr(1, value.length);
        if(this.inputOptions.min && value < this.inputOptions.min) $event.target.value = this.inputOptions.min;
        if(this.inputOptions.max && value > this.inputOptions.max) $event.target.value = this.inputOptions.max;
      }
    },
    ClearSelection(){
      this.items.forEach(i => i.selected = false);
      this.selectedItem = null;
    },
    Focus($event){
      this.$emit('focus', $event);
      this.ToggleItems(true);
    },
    KeyUp($event){

      this.$emit('keyup', $event);
      this.CheckLimits($event);
      let view = this;
      let keyName = $event.key;
      if(!view.focusedEnterDelay) {
        view.focusedEnterDelay = true;
        return;
      }
      if (keyName === "Enter" ||  keyName === "Tab") {
        if(view.selectMultiple) {
          view.input = '';
          view.selectedItems.forEach(i => {
            view.input += view.DisplayItemLabel(i) + ' ';
          });
        }
        if(view.items && view.items.length) view.SelectItem(view.selectedItem);
        if(view.UpdateFixed) view.UpdateFixed(view.input);
        view.$emit('Update', view.input, view.inputPrev);

        view.inputPrev = view.input;
        if(!this.remainAfterSelect){
          view.focused = false;
          if(!view.hasItems) this.$el.querySelector('input').blur();
          requestAnimationFrame(function(){
            requestAnimationFrame(function(){
              view.selectedItem = null;
              requestAnimationFrame(function(){
                if(!view.hasItems) view.$el.querySelector('button').focus();
              });
            });
          });
        }
        if(keyName === 'Enter') $event.preventDefault();
        return;
      }

    },
    FocusInput(selectAll){
      this.focused = true;
      let view = this;
      requestAnimationFrame(function(){
        requestAnimationFrame(function(){
          let input = view.$el.querySelector('input');
          input.focus();
          view.$el.querySelector('input').select();
          view.focusedEnterDelay = false;
          if(input.select) {
            if(selectAll) input.select();
            else
              input.selectionStart = input.selectionEnd = input.value.length;
          }
        });
      });
    },
    Init(selectionInit){
      if(this.inputProp) this.input = this.inputProp;
      if(selectionInit) {
        if(typeof selectionInit === 'number'){
          if(this.items.length > selectionInit) this.selectedItem = this.items[selectionInit];
        } else if(this.$helpers.isFunction(selectionInit)){
          this.selectedItem = selectionInit(this.items);
        } else {
          this.selectedItem = selectionInit;
        }
        if(this.selectedItem) this.input = this.DisplayItemLabel(this.selectedItem);
      } else {
        if(this.items) this.selectedItems = this.items.filter(i => i.selected);
      }
    },
    FocusSelected(){
      let selectedItem = this.selectedItem ? this.selectedItem : this.itemsFiltered[0];
      let index = this.itemsFiltered.indexOf(selectedItem);
      let itemEl = this.$el.querySelectorAll('.item');
      if (itemEl.length > 0) {
        let itemContainer = this.$el.querySelector('.item-container');
        itemEl = itemEl[index];
        if(itemEl != undefined){
          let itemHeight = itemEl.getBoundingClientRect().height;
          let itemContainerHeight = itemContainer.getBoundingClientRect().height;
          if (itemEl.offsetTop < itemContainer.scrollTop) {
            itemContainer.scrollTop = itemEl.offsetTop;
          } else if (itemEl.offsetTop + itemHeight > itemContainer.scrollTop + itemContainerHeight) {
            itemContainer.scrollTop = itemEl.offsetTop + itemHeight - itemContainerHeight;
          }
        }
      }
    },
    DisplayItemLabel(item){
      if(!item) return '';
      if(this.DispayLabel) return this.DispayLabel(item);
      return item.label ? item.label : '';
    },
    DisplayItemDescription(item){
      if(!item) return '';
      if(this.DisplayDescription) return this.DisplayDescription(item);
      return item.description ? item.description : '';
    },
    ToggleItems($event, toggle){

      let view = this;
      let items = this.$el.querySelector('.item-container');
      if(items){
        let itemsBox = items.getBoundingClientRect();
        if(window.mouse.x > itemsBox.left && window.mouse.x < itemsBox.right && window.mouse.y < itemsBox.bottom && window.mouse.y > itemsBox.top){
          return;
        }
      }
      if($event === true || $event === false) toggle = $event;
      else if(this.remainAfterSelect && $event.currentTarget.closest('.input-component')){
        this.$el.querySelector('input').focus();
        /*$event.preventDefault();*/
        return;
      }
      if(!toggle) {
        this.focused = false;
        this.selectedItem = null;
        this.Init(this.selectionInit);

        /*this.input = '';
        this.selectedItems.forEach(i => {
            view.input += view.DisplayItemLabel(i) + ' ';
        });
        this.inputPrev = this.input;*/
      }
      if(!toggle) {
        this.input = this.inputPrev;
        /*if(this.UpdateFixed) this.UpdateFixed(this.input);
        this.$emit('Update', this.input);*/
      } else {

        if(view.input != view.inputPrev){
          if(view.UpdateFixed) view.UpdateFixed(view.input);
          view.$emit('Update', view.input, view.inputPrev);
          view.inputPrev = view.input;
        }

        this.inputFilter = '';
        if(this.items) this.selectedTemp = this.items.filter(i => i.selected);
      }
      if(!this.hasItems) return;
      this.showItems = toggle;
      if(this.showItems) {
        this.inputFilter = '';
        if(!this.selectedItem) this.selectedItem = this.itemsFiltered[0];
        this.RefreshItemsWidthPos();
        requestAnimationFrame(this.FocusSelected);
      } else {
        this.selectedItem = this.selectedTemp;
        if(this.selectedItem && !this.selectMultiple) this.input = this.DisplayItemLabel(this.selectedItem);
      }
    },
    RefreshItemsWidthPos(){
      let items = this.$el.querySelector('.item-container').getBoundingClientRect();;
      let box = this.$el.getBoundingClientRect();
      this.itemsWidth = box.width;
      if(box.bottom + items.height < window.innerHeight) {
        this.reversed = false;
        this.itemsPosY = box.bottom;
      } else { this.itemsPosY = box.top - items.height;
        this.reversed = true;
      }

      if(this.maxItems){
        this.itemsHeight = (26 * this.maxItems) + 10;
        if(this.reversed) this.itemsPosY = box.top - this.itemsHeight;
      }

      this.itemsPosX = box.left;
      if(!this.noBorder) this.itemsWidth += 0//2; //2 to account for 1 pixel border on both sides
    },
    SelectItem(item){
      let view = this;
      if(item.onceSelected) item.onceSelected(null, item, this);
      if(this.selectMultiple) {
        this.items.forEach(i => i.selected = false);
        if(this.selectedItems.find(i => i === item))  this.selectedItems = this.selectedItems.filter(i => i !== item);
        else this.selectedItems.push(item);

        this.selectedItem = item;
        this.input = '';
        this.selectedItems.forEach(i => {
          view.input += view.DisplayItemLabel(i) + ' ';
          i.selected = true;
        });
        this.inputPrev = this.input;
        if(view.UpdateFixed) view.UpdateFixed(this.selectedItems.map(i => i.value));
        view.$emit('Update', this.selectedItems.map(i => i.value));
        this.$emit('item-selected', this.selectedItems.map(i => i.value));

      } else {
        if(this.items){
          this.items.forEach(i => {
            i.selected = false;
          });
          item.selected = true;
          this.selectedItems = [];
          this.selectedItems.push(item);
          this.input = this.DisplayItemLabel(item);
          this.inputPrev = this.input;
          if(view.UpdateFixed) view.UpdateFixed(item);
          view.$emit('Update', item);
          this.$emit('item-selected', this.selectedItems.length > 0 ? this.selectedItems[0] : null);
        }
      }

      this.$emit('OnSelect', item);
      if(this.OnSelect) this.OnSelect(item);
      this.selectedTemp = item;
      //  if(this.selectMultiple) this.selectedItems = item;
      if(!this.remainAfterSelect)     {
        this.focused = false;
        requestAnimationFrame(function(){
          requestAnimationFrame(function(){
            view.$el.querySelector('button').focus();
          });
        });
      }

    },
    CheckNavigation($event){
      let view = this;
      let keyName = $event.key;

      if(keyName !== 'ArrowUp' && keyName !== 'ArrowDown' && keyName !== 'ArrowLeft' && keyName !== 'ArrowRight'){
        if(!this.selectMultiple) requestAnimationFrame(function(){view.inputFilter = view.input;});
      }

      if (keyName === "Escape") {
        this.$el.querySelector('input').blur();
        if(this.items && this.items.length) {
          this.items.forEach(i => i.selected = false);
          this.selectedTemp.forEach(i => i.selected = true);
          this.input = this.selectedTemp.length > 0 ? this.DisplayItemLabel(this.selectedTemp[0]) : '';
        } else {
          this.input = this.inputPrev;
        }
        if (keyName === "Escape"){
          requestAnimationFrame(function(){
            requestAnimationFrame(function(){
              requestAnimationFrame(function(){
                if(view.$el.querySelector('button')) view.$el.querySelector('button').focus();
              });
            });
          });
        }
        //$event.preventDefault();
        return;
      }


      if(this.itemsFiltered.length > 0) {
        let selectedItem = this.selectedItem ? this.selectedItem : this.itemsFiltered[0];
        let index = this.itemsFiltered.indexOf(selectedItem);
        if (keyName === "ArrowUp") {
          index = (this.itemsFiltered.length + index - 1) % this.itemsFiltered.length; //how to cycle array without overflow
          this.selectedItem = this.itemsFiltered[index];
          view.input = view.DisplayItemLabel(view.selectedItem);
          $event.preventDefault();
        }
        if (keyName === "ArrowDown") {
          index = (this.itemsFiltered.length + index + 1) % this.itemsFiltered.length; //how to cycle array without overflow
          this.selectedItem = this.itemsFiltered[index];

          view.input = view.DisplayItemLabel(view.selectedItem);
          $event.preventDefault();
        }

        //this.input = this.DisplayItemLabel(this.selectedItem);
        this.FocusSelected();

        if(keyName === "Tab"){

        }
      } else {
        this.$emit('OnSelect', this.selectedItem);
      }
    },
  },
}
</script>

<style scoped>
.input-component{
  position: relative;
  display: inline-block;
  width: inherit;
  /*z-index: 10;*/
}

input{
  width: 100%;
  font-size: 11px;
  font-weight: 600;
  padding: 4px 10px;
  height: 25px;
  border-radius: 0;
  background: none;
}

input.unfolded{
  box-shadow: -1px 0 0px 0px var(--contrast-5),
  1px 0 0px 0px var(--contrast-5),
  0px -1px 0px 0px var(--contrast-5);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.reversed input.unfolded{
  box-shadow: -1px 0 0px 0px var(--contrast-5),
  1px 0 0px 0px var(--contrast-5),
  0px 1px 0px 0px var(--contrast-5);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.item-container{
  padding-bottom: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  background: var(--contrast-2);
  box-shadow: -1px 0 0px 0px var(--contrast-5), 1px 0 0px 0px var(--contrast-5), 0px 1px 0px 0px var(--contrast-5);
  z-index: 1000;
  position: fixed;
  max-height: 300px;
  overflow: auto;
}

.reversed .item-container{
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: -1px 0 0px 0px var(--contrast-5), 1px 0 0px 0px var(--contrast-5), 0px -1px 0px 0px var(--contrast-5);
}

.item{
  position: relative;
  padding: 4px 0;
  padding-left: 10px;
  text-align: left;
}

.item:nth-child(2n){
  background: var(--contrast-1);
}

.item:hover, .item.selected{
  background: var(--ml);
  cursor: pointer;
}

.item:hover *, .item.selected *{
  color: white !important;
}
.item.hover{
  filter: brightness(120%);
}



.input-component input{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 5px 10px;
  font-size: inherit;
  font-family: DistrictProLight;
  text-align: left;
  font-weight: 200;
  display: inline-block;
  margin: initial;
  /*height: calc(100% - 10px);*/
}

.background{
  position: fixed;
  height: 2000px;
  width: 2000px;
  left: 0;
  right: 0;
  z-index: 0;
  top: 0;
}

/*.description{
    pointer-events: none;
    min-height: 15px;
    margin-top: -3px;
    overflow: hidden;
    float: none;
    font-size: 10px;
    color: #fff !important;
}

.item-label{

}*/

.item-container  .item-with-desc:first-child{
  //margin-top: -5px;
}
.item-container  .item-with-desc{
  background: var(--contrast-3);
  box-shadow: -1px 0 0px 0px var(--contrast-5),
  1px 0 0px 0px var(--contrast-5);
  font-weight: 600;
  text-align: left;
  padding: 4px 0;
  padding-left: 10px;
  height: 35px;
  cursor: pointer;
  color:var(--contrast-4);
}
.item-container  .item-with-desc *{
  pointer-events: none;
  float: left;
  min-height: 15px;
  margin-top: -3px;
  overflow: hidden;
}
.item-container  .item-with-desc:nth-child(odd){
  background: var(--contrast-2);
}

.item-container  .item-with-desc.current{
  background: var(--ml);
}
.item-container  .item-with-desc:hover{
  background: var(--ml) !important;
}
.item-container  .item-with-desc.selected{
  background: var(--ml) !important;
}
.item-container  .item-with-desc:hover .item-label{
  color: var(--bg) !important;
}
.item-container  .item-with-desc.current .item-label{
  color: var(--bg) !important;
}
.item-container  .item-with-desc .fas{
  font-size: 10px;
  margin-top: 4px;
  margin-left: 1px;
  overflow: visible;
}
.item-container  .item-with-desc .item-label{
  white-space: nowrap;
  max-width: calc(100% - 10px);
  line-height: 18px;
}
.item-container  .item-with-desc .description{
  float: none;
  font-size: 10px;
  position: absolute;
  color: var(--contrast-5);
  white-space: nowrap;
  bottom: 3px;
  left: 10px;
}
.item-container  .item-with-desc:hover .description{
  color: var(--bg) !important;
}
.item-container  .item-with-desc.current .description{
  color: var(--bg) !important;
}

.item-container  .item-no-desc:first-child{
  //margin-top: -5px;
}
.item-container  .item-no-desc{
  background: var(--contrast-3);
  box-shadow: -1px 0 0px 0px var(--contrast-5),
  1px 0 0px 0px var(--contrast-5);
  font-weight: 600;
  text-align: left;
  padding: 4px 0;
  padding-left: 10px;
  height: 22px;
  cursor: pointer;
  color:var(--contrast-4);
}
.item-container  .item-no-desc *{
  pointer-events: none;
  float: left;
  min-height: 15px;
  margin-top: -3px;
  overflow: hidden;
}
.item-container  .item-no-desc:nth-child(odd){
  background: var(--contrast-2);
}

.item-container  .item-no-desc.current{
  background: var(--ml);
}
.item-container  .item-no-desc:hover{
  background: var(--ml) !important;
}
.item-container  .item-no-desc.selected{
  background: var(--ml) !important;
}
.item-container  .item-no-desc:hover .item-label{
  color: var(--bg) !important;
}
.item-container  .item-no-desc.current .item-label{
  color: var(--bg) !important;
}
.item-container  .item-no-desc .fas{
  font-size: 10px;
  margin-top: 4px;
  margin-left: 1px;
  overflow: visible;
}
.item-container  .item-no-desc .item-label{
  white-space: nowrap;
  max-width: calc(100% - 10px);
  line-height: 18px;
}
.item-container  .item-no-desc .description{
  float: none;
  font-size: 10px;
  position: absolute;
  color: var(--contrast-5);
  white-space: nowrap;
  bottom: 3px;
  left: 10px;
}
.item-container  .item-no-desc:hover .description{
  color: var(--bg) !important;
}
.item-container  .item-no-desc.current .description{
  color: var(--bg) !important;
}

.item-container  *:last-child{
}


.fa-stack{

  position: absolute;
  right: 5px;
  top: 6px;
  border-radius: 100px;
  height: 15px;
  width: 15px;
  cursor: pointer;
  margin: 0;
}
.fa-stack.clear{
  right: 22px;
}
.fa-stack.clear .fa-times{
  transform: scale(.8);
  color: var(--contrast-3);
}
.dropdown-search.light > .fa-stack.clear .fa-times{
  color: var(--bg);
}
.fa-stack .fas{

  color: var(--bg);
  font-size: 15px;
}
.menu-bar .fa-stack .fas{
  color: var(--contrast-3);
}
.fa-stack .fas.fa-circle{

  color: var(--contrast-8);
}
.menu-bar .fa-stack .fas.fa-circle{
  color: var(--contrast-4);
}

.datetime input{
  padding: 0;
  border: none;
  box-shadow: none;
  margin: 0;
}

</style>
