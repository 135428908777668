<template>
<div>
    <div class="full">
        <div class="full">
            <div class="mainheader">
                <div class="half">
                    ÖGK Meldungen
                </div>
            </div>
        </div>
        <div v-if="loading" class="loading-md bg">
          <i class="fas fa-spinner inner"></i>
          <div v-if="!$helpers.isEmpty(loadingText)" class="loading-text" >
            {{ loadingText }}
          </div>
        </div>
        <div v-if="loadingBackendCall" class="loading-md bg">
          <i class="fas fa-spinner inner"></i>
          <div v-if="!$helpers.isEmpty(loadingText)" class="loading-text" >
            {{ loadingTextBackendCall }}
          </div>
        </div>
        <div v-if="!loading && !loadingBackendCall">
            <div v-if="!getCreatingEldaRequest()" class="full split-bottom">
                <div v-if="companySettings && companySettings.socialInsuranceRegistrationMode != 0 && employee.socialInsuranceRegistered == 0">
                  <div class="alert alert-danger" role="alert">
                    <strong>{{employee.firstName}} {{employee.lastName}}</strong> ist NICHT bei der ÖGK angemeldet!
                  </div>
                  <!-- <div v-if="employee.socialInsuranceRegistered == 1" class="alert alert-success" role="alert">
                      <strong>{{employee.firstName}} {{employee.lastName}}</strong> ist bei der ÖGK angemeldet!
                  </div> -->
                </div>
                <div>
                  <div class="header">
                      <div class="label">An/Abmeldungen</div>
                      <div class="header-right">
                        <div v-tooltip="'Neue Anmeldung'" class="button small" v-on:click="startRegister">
                            <div class="label">
                                Neue Anmeldung
                            </div>
                        </div>
                      </div>
                  </div>
                  <table class="table table-striped table-borderless">
                      <tr>
                          <th class="documentsTh">Anmeldedatum</th>
                          <th class="documentsTh">Abmeldedatum</th>
                          <th class="documentsTh">Status Dokument</th>
                          <th class="documentsTh">Aktualisert am</th>
                          <th class="documentsTh"></th>
                      </tr>
                      <tr v-for="row in getRegistrations" :key="'employeeSocialInsuranceRegistrationId_'+row.id">

                          <td class="documentsTd">{{ $helpers.extractDateString(row.registrationDate, 'DD.MM.YYYY') }}</td>

                          <td class="documentsTd" v-if="(row.deregistrationDate)">{{ $helpers.extractDateString(row.deregistrationDate, 'DD.MM.YYYY') }}</td>
                          <td v-else/>


                          <!-- <td class="documentsTd" v-if="(row.registrationDocument)">
                              <buttonc label="Öffnen" type="button" v-on:click="openDocument(row)"/>
                          </td> -->

                          <!-- ELDA -->
                          <td class="documentsTd" v-if="row.elda">
                            <p v-for="elda_status in row.elda_regs" :key="'elda_status_reg_'+elda_status.id">
                              Anmeldung: {{ getEldaStatus(elda_status.status, elda_status.api_state) }}
                            </p>
                            <p v-for="elda_status in row.elda_deregs" :key="'elda_status_dereg_'+elda_status.id">
                              Abmeldung: {{ getEldaStatus(elda_status.status, elda_status.api_state) }}
                            </p>
                          </td>
                          <!-- MANUAL REGISTRATIONS -->
                          <td class="documentsTd" v-else-if="!(row.registrationDocument)">
                              <FileUploadBoxCopy @upload-success="onUploadSuccess" :employee="employee" :sirid="row.id" ></FileUploadBoxCopy>
                          </td>
                          <td class="documentsTd" v-else-if="row.registrationDocument && !authUsersMap[row.registrationDocumentConfirmedBy]">
                              Dokument nicht bestätigt
                          </td>
                          <td class="documentsTd" v-else>
                              Dokument bestätigt
                          </td>

                          <td class="documentsTd">
                              <div v-if="row.elda">
                                <p v-for="elda_status in row.elda_regs" :key="'elda_status_reg_'+elda_status.id">
                                  {{ $helpers.getMomentFromString(elda_status.updated_at, "YYYY-MM-DDTHH:mm:ssZ").format('DD.MM.YYYY HH:mm:ss') }}
                                </p>
                                <p v-for="elda_status in row.elda_deregs" :key="'elda_status_dereg_'+elda_status.id">
                                  {{ $helpers.getMomentFromString(elda_status.updated_at, "YYYY-MM-DDTHH:mm:ssZ").format('DD.MM.YYYY HH:mm:ss') }}
                                </p>
                              </div>
                              <div v-else>
                                {{ $helpers.getMomentFromString(row.updatedAt, "YYYY-MM-DDTHH:mm:ssZ").format('DD.MM.YYYY HH:mm:ss') }}
                              </div>
                          </td>

                          <td class="documentsTd">
                            <div v-if="row.elda">
                              <p v-for="elda_status in row.elda_regs" :key="'elda_status_reg_'+elda_status.id" class="elda-icon-paragraph">
                                <buttonc v-if="!(row.deregistrationDate)" icon="fa-user-minus" v-tooltip="'Abmelden'" type="button" v-on:click="startDeregister(row.id)"/>
                                <buttonc v-if="hasEldaRetryStatus(elda_status.status, elda_status.api_state)" v-tooltip="'Anmeldung löschen'" icon="fa-trash" v-on:click="deleteSIR(row.id)"/>
                                <buttonc v-if="hasEldaCheckStatus(elda_status.status, elda_status.api_state)" v-tooltip="'Status abfragen'" icon="fa-seal-question" v-on:click="proxyEldaRequestStatus(elda_status)" />
                                <buttonc v-if="elda_status.file" v-tooltip="'Dokument öffnen'" icon="fa-file" v-on:click="openEldaDocument(elda_status)"/>
                                <buttonc v-else style="height:20.6px; width: 0px; padding: 0px;"/>
                              </p>
                              <p v-for="elda_status in row.elda_deregs" :key="'elda_status_dereg_'+elda_status.id" class="elda-icon-paragraph">
                                <buttonc v-if="row.deregistrationDate && hasEldaRetryStatus(elda_status.status, elda_status.api_state) && isLatestEldaStatus(row.elda_deregs, elda_status)" icon="fa-calendar-minus" v-tooltip="'Abmeldedatum entfernen'" type="button" v-on:click="startUndoDeregister(row.id)"/>
                                <buttonc v-if="hasEldaCheckStatus(elda_status.status, elda_status.api_state)" v-tooltip="'Status abfragen'" icon="fa-seal-question" v-on:click="proxyEldaRequestStatus(elda_status)" />
                                <buttonc v-if="elda_status.file" v-tooltip="'Dokument öffnen'" icon="fa-file" v-on:click="openEldaDocument(elda_status)"/>
                                <buttonc v-else style="height:20.6px; width: 0px; padding: 0px;"/>
                              </p>
                            </div>
                            <div v-else>
                              <buttonc v-if="row.registrationDocument && !authUsersMap[row.registrationDocumentConfirmedBy]" v-tooltip="'Dokument bestätigen'" icon="fa-file-check" type="button" v-on:click="confirmDocument(row.id)"/>
                              <buttonc v-if="row.registrationDocument" v-tooltip="'Dokument öffnen'" icon="fa-file" v-on:click="openDocument(row)"/>
                              <buttonc v-if="!(row.deregistrationDate)" icon="fa-user-minus" v-tooltip="'Abmelden'" type="button" v-on:click="startDeregister(row.id)"/>
                              <buttonc v-if="(!row.registrationDocument || !authUsersMap[row.registrationDocumentConfirmedBy])" v-tooltip="'Anmeldung löschen'" icon="fa-trash" v-on:click="deleteSIR(row.id)"/>
                              <!-- <buttonc v-if="(!row.registrationDocument || !authUsersMap[row.registrationDocumentConfirmedBy])" v-tooltip="'Anmeldung löschen'" icon="fa-trash" v-on:click="deleteSIR(row.id)"/> -->
                            </div>
                          </td>
                      </tr>

                  </table>

                </div>

                <b-button
                  style="width: 242px; margin: 0px 0px;"
                  @click="setCreatingEldaRequest(true)"
                  squared
                  variant="success"
                >Elda Meldung durchführen</b-button>
            </div>

            <div v-if="getCreatingEldaRequest() && creatingEldaRequestPhase === 1" class="full split-bottom">
                <div class="header">
                    <div class="label">Elda Meldung durchführen</div>
                </div>
                <div v-for="superType in Object.keys(requestTypes)" v-bind:key="superType" style="margin-bottom:4px">
                  <div class="header" style="font-weight: 700; color: var(--col-label-default);">{{ superType }}</div>
                  <div v-for="key in Object.keys(requestTypes[superType])" v-bind:key="key" style="margin-bottom:4px">
                    <b-button
                      style="width: 400px; margin: 0px 0px;"
                      @click="setEldaStateDataEntries(key, requestTypes[superType][key])"
                      squared
                      variant="outline-success"
                    >{{ requestTypes[superType][key].label_de }}</b-button>
                  </div>
                  <br>
                </div>
            </div>

            <div v-if="getCreatingEldaRequest() && creatingEldaRequestPhase === 2" class="full split-bottom">
                <div class="header">
                    <div class="label">{{ requestTitle }}</div>
                </div>
                <datafill :state=getEldaState() />
            </div>
        </div>
    </div>

    <b-modal size="md" ref="modal-loadingEldaDocument" :no-close-on-backdrop="true" :no-close-on-esc="true" centered>
      <template slot="modal-header">
        <div></div>
      </template>
      <b-container fluid>
        <div class="full">
          <div class="label left" style="text-align: center">
            <i class="fas fa-spinner fa-spin" style="font-size: xxx-large"></i> <br/><br/>
            Bitte warten<br />
            Dokument wird geladen...<br />
          </div>
        </div>
      </b-container>
      <template slot="modal-footer">
        <div></div>
      </template>
    </b-modal>

</div>
</template>

<script>
import VueEventBus from '../../vue-event-bus';
import FileUploadBoxCopy from '@/components/employeecontext/FileUploadBoxCopy';
import axios from "axios";
import Datafill from "@/components/uicomponents/datafill";

export default {
    components: {
      Datafill,
        FileUploadBoxCopy
    },
    name: 'employeesocialinsuranceregistration',
    data() {
        return {
            loading: true,
            loadingText: "ÖGK Meldungen werden geladen...",
            loadingBackendCall: false,
            loadingTextBackendCall: "Meldung wird verarbeitet...",
            registrations: [],

            authUsers: [],
            authUsersMap: {},

            countriesISO3: [],
            countriesKFZ: [],
            genders: [
              {
                value: "1",
                label: "männlich"
              },
              {
                value: "2",
                label: "weiblich"
              },
              {
                value: "3",
                label: "divers"
              },
              {
                value: "4",
                label: "offen"
              }
            ],

            requestTypes: {},
            requestFields: {},
            requestTitle: '',
            currentRequest: {},
            creatingEldaRequestPhase: 1,
            eldaStateDataEntries: {},

            employeeContractFromDate: undefined,
            employeeContragsCount: 0,

            eldaEmployerName: "",
            eldaEmployerPhoneNumber: "",
            eldaEmployerEmail: "",
            eldaEmployerInsurer: [],
            eldaCreator: [],
            missingCreatorIdText: "Bitte erstellen Sie einen Eintrag in Ihren Elda Hersteller Einstellungen.",
            projekt: "",

            register: '',
            deregister: '',
            companySettings: null,

            displayOnly: false
        }
    },
    watch: {
        employee: function (val, oldVal){
            this.refresh();
        },
        refreshSIR: function (val, oldVal){
            this.refresh();
        }
    },
    props: [
        "employee",
        "refreshSIR"
    ],
    computed: {
        getRegistrations() {
            let view = this;
            view.registrations.sort((a, b) => {
              return new Date(a.registrationDate) - new Date(b.registrationDate);
            });
            view.registrations.forEach((reg) => {
                //console.log(reg);
            });
            return view.registrations;
        }
    },
    mounted(){
        let view = this;

        let url = '/enum/country';
        this.api.get(url).then((response) => {
          for (let x in response.data) {
            let elem = response.data[x];
            this.countriesISO3.push({id: elem.id, value: elem.key, label: elem.valueGER});
            if(elem.kfz !== null) {
              this.countriesKFZ.push({id: elem.id, value: elem.kfz, label: elem.valueGER});
            }
          }
        });

        this.refresh();
    },
    created: function () {
        //console.log("CREATED");
        VueEventBus.$on("EmployeeSocialInsuranceRegistrationEvent:uploadDocument", this.documentUploaded);
        VueEventBus.$on("EmployeeSocialInsuranceRegistrationEvent:confirmDocument", this.documentConfirmed);
        VueEventBus.$on("EldaCallbackEvent:update", this.eldaCallBack);
        //VueEventBus.$on("JobEmployeeImportEvent:jobStarted", this.jobStarted);
        //VueEventBus.$on("JobEmployeeImportEvent:jobEnded", this.jobEnded);
    },
    beforeDestroy: function(){
        VueEventBus.$off("EmployeeSocialInsuranceRegistrationEvent:uploadDocument", null);
        VueEventBus.$off("EmployeeSocialInsuranceRegistrationEvent:confirmDocument", null);
        VueEventBus.$off("EldaCallbackEvent:update", null);
        //VueEventBus.$off("JobEmployeeImportEvent:jobStarted", null);
        //VueEventBus.$off("JobEmployeeImportEvent:jobEnded", null);
    },
    destroyed() {
    },
    methods: {
        refresh: function(){
          let view = this;
          view.loading = true;
          view.loadingBackendCall = false;

          view.creatingEldaRequestPhase = 1;
          view.$parent.creatingEldaRequest = false;
          view.displayOnly = false;

          view.$helpers.GetCompanySettings().then(response => {
            console.log('Company settings:');
            console.log(response);
            this.companySettings = response.data[0];
          });
          view.getEmployeeSocialInsuranceRegistrations().then((response) => {
            view.registrations = [];

            let registrations = response.data.data.employeeSocialInsuranceRegistrations;
            view.authUsers = response.data.data.authUsers;

            let usersM = {};
            let data = view.authUsers;
            var i;
            for (i = 0; i < data.length; i++) {
              usersM[data[i].id] = data[i];
            }
            view.authUsersMap = usersM;

            let promises = [];
            registrations.forEach(reg => {
              promises.push(view.loadSocialInsuranceRegistrationEldaStatus(reg.id));
            });

            Promise.all(promises).then((responses) => {
              responses.forEach((response) => {

                console.log(response);

                if(response.data.data[0] !== undefined) {
                  let reg = registrations.find((reg) => reg.id === response.data.data[0].socialInsuranceRegistrationsId);

                  reg.elda = true;
                  reg.elda_regs = [];
                  reg.elda_deregs = [];

                  response.data.data.forEach((elda_status) => {
                    if (elda_status.type == 'registration') {
                      reg.elda_regs.push(elda_status);
                    } else if (elda_status.type == 'deregistration') {
                      reg.elda_deregs.push(elda_status);
                    }
                  });
                }
              });

              view.registrations = registrations;
              this.loading = false;
            });

          }).catch(function (error){
            console.log(error);
          });
          view.loadEldaEmployerSettings();
          view.loadEldaCreatorSettings();
          view.loadEldaRequestTypes();
          view.loadEmployeeContracts();
        },

        documentUploaded: function(){
            this.refresh();
        },
        documentConfirmed: function(){
            this.refresh();
        },
        openDocument(row){
            let docUrl = docUrl = 'https://fileserver1.meindienstplan.de/api/file?id='+row.registrationDocument.id+'&accessToken='+row.registrationDocument.accessToken;
            window.open(docUrl, '_blank').focus();
        },
        confirmDocument(sirid){
            let view = this;
            let url = '/api/sec/employee/socialinsuranceregistration/confirm';

            url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/sec/employee/socialinsuranceregistration/confirm');

          console.log("sending");
            return this.axios({
                method: 'post',
                headers: {
                    'AUTHORIZATION': "Bearer "+this.$store.state.jwt
                },
                url: url,
                data: {socialInsuranceRegistrationId: sirid}
            });

        },
        onUploadSuccess(file, response) {
            console.log(`Uploaded new file`);
        },
        startRegister(){
            let view = this;
            let initialValue = this.$helpers.getMoment();
            let dataEntries = [
                    {label: 'Anmeldedatum', key: 'registrationDate', hidden: false, type: 'date', value: initialValue }
            ];
            this.$helpers.OpenPopup({
                type: 'datafill',
                componentState: {
                    entries: dataEntries,

                    description: "",
                    selectCallback: function (item) {
                        console.log('Deregister date popup select callback');
                        console.log(item);
                        view.loadingBackendCall = true;
                        view.register = item.registrationDate.format("YYYY-MM-DD");
                        view.sendRegister()
                          .then((response) => {
                              view.refresh();
                          })
                          .catch((error) => {
                            console.log(error);
                            view.$helpers.error("Error", "Fehler beim Anmelden des Mitarbeiters");
                          });
                    },
                },
                header: 'Mitarbeiter anmelden',
                remainAfterSelect: false,
                expanded: true,
                centered: true
            });
        },
        startDeregister(sirid){
            let view = this;
            let initialValue = this.$helpers.getMoment();
            let dataEntries = [
                    {label: 'Abmeldedatum', key: 'deregistrationDate', hidden: false, type: 'date', value: initialValue }
            ];
            this.$helpers.OpenPopup({
                type: 'datafill',
                componentState: {
                    entries: dataEntries,

                    description: "",
                    selectCallback: function (item) {
                        console.log('Deregister date popup select callback');
                        console.log(item);
                        view.loadingBackendCall = true;
                        view.deregister = item.deregistrationDate.format("YYYY-MM-DD");
                        view.sendDeregister(sirid)
                          .then((response) => {
                              view.refresh();
                          })
                          .catch((error) => {
                            console.log(error);
                            view.$helpers.error("Error", "Fehler beim Abmelden des Mitarbeiters");
                          });
                    },
                },
                header: 'Mitarbeiter abmelden',
                remainAfterSelect: false,
                expanded: true,
                centered: true
            });
        },
        startUndoDeregister(sirid){
            let view = this;
            let dataEntries = [];
            this.$helpers.OpenPopup({
                type: 'datafill',
                componentState: {
                    entries: dataEntries,

                    description: "Möchten Sie das Abmeldedatum wirklich entfernen?",
                    selectCallback: function (item) {
                        console.log('Undo Deregister date popup select callback');
                        console.log(item);
                        view.loadingBackendCall = true;
                        view.sendUndoDeregister(sirid)
                          .then((response) => {
                              view.refresh();
                          });
                    },
                },
                header: 'Abmeldung entfernen',
                remainAfterSelect: false,
                expanded: true,
                centered: true
            });
        },
        askDeleteSIR(sirid){
            this.$helpers.OpenAlert({
                header: "Anmeldung löschen?",
                description: "Sind Sie sicher, dass Sie die Anmeldung löschen möchten? Dies kann NICHT rückgängig gemacht werden!",
                callbackAccept: ()=>{
                    this.deleteSIR(sirid);
                },
                acceptLabel: "Anmeldung löschen"
            });
        },
        deleteSIR(sirid){
            console.log('Deleting SIR ID: ' + sirid);
            let view = this;
            let url = '/api/sec/employee/socialinsuranceregistration/delete';

            url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/sec/employee/socialinsuranceregistration/delete');

            return this.axios({
                method: 'delete',
                headers: {
                    'AUTHORIZATION': "Bearer "+this.$store.state.jwt
                },
                url: url,
                data: {socialInsuranceRegistrationId: sirid}
            }).then((response) => {
              this.refresh();
            }).catch((error) => {
              console.log(error);
              this.refresh();
            });
        },
        getEmployeeSocialInsuranceRegistrations: function(){
            let view = this;
            let url = '/api/sec/employee/socialinsuranceregistration';

            url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/sec/employee/socialinsuranceregistration');
            url = this.$helpers.appendParam("employeeId", view.employee.id, url, '/api/sec/employee/socialinsuranceregistration');

            return this.axios({
                method: 'get',
                headers: {
                    'AUTHORIZATION': "Bearer "+this.$store.state.jwt
                },
                url: url
            });
        },
        sendDeregister: function(sirid){
            console.log('Sending deregistration for SIR ID: ' + sirid);
            console.log(this.deregister);
            let view = this;
            let url = '/api/sec/employee/socialinsuranceregistration/deregister';
            url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/sec/employee/socialinsuranceregistration/deregister');
            return this.axios({
                method: 'post',
                headers: {
                    'AUTHORIZATION': "Bearer "+this.$store.state.jwt
                },
                url: url,
                data: {employeeId: view.employee.id,deregistrationDate: view.deregister,socialInsuranceRegistrationId: sirid}
            });

        },
        sendUndoDeregister: function(sirid){
            console.log('Sending undo deregistration for SIR ID: ' + sirid);
            let view = this;
            let url = '/api/sec/employee/socialinsuranceregistration/deregister/undo';
            url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/sec/employee/socialinsuranceregistration/deregister/undo');
            return this.axios({
                method: 'post',
                headers: {
                    'AUTHORIZATION': "Bearer "+this.$store.state.jwt
                },
                url: url,
                data: {employeeId: view.employee.id,socialInsuranceRegistrationId: sirid}
            }).catch(function (error){
                console.log(error);
                view.$helpers.error("Error", "Fehler beim Entfernen des Abmeldedatums");
            });

        },
        sendRegister: function(){
            let view = this;
            let url = '/api/sec/employee/socialinsuranceregistration/register';
            url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/sec/employee/socialinsuranceregistration/register');

            return this.axios({
                method: 'post',
                headers: {
                    'AUTHORIZATION': "Bearer "+this.$store.state.jwt
                },
                url: url,
                data: {employeeId: view.employee.id,registrationDate: view.register}
            });
/*
            let url = row.item.url+'/api/file?id='+row.item.fileId+'&accessToken='+row.item.accessToken;
            window.open(url, '_blank');*/
        },
        async uploadFile(file, sirid) {

          const formData = new FormData();
          formData.append('file', file, file.name);
          formData.append('companyId', `${ this.$store.state.companyId }`);
          formData.append('uploadType', 'SOCIALINSURANCEREGISTRATION');
          formData.append('employeeId', this.employee.id);
          formData.append('socialInsuranceRegistrationId', sirid);

          try {
            const response = await axios.post('/api/sec/file/upload', formData, {
              headers: {
                Authorization: 'Bearer ' + this.$store.state.jwt,
                'Content-Type': 'multipart/form-data',
              },
            });
            console.log(response.data);
            this.refresh();
          } catch (error) {
            this.showErrorAlert(`Upload von ${ file.name } fehlgeschlagen: ${ error.message }`);
          }
        },
        urltoFile(url, filename, mimeType){
          return (fetch(url)
              .then(function(res){return res.arrayBuffer();})
              .then(function(buf){return new File([buf], filename,{type:mimeType});})
          );
        },


        /*
         * ELDA
         */

        // prepare form
        setEldaStateDataEntries(key, requestType) {
          let view = this;

          console.log(view.employee);
          console.log(view.registrations);

          if (requestType.type === 'registration'
            && view.registrations.length > 0
            && view.registrations.filter(reg => reg.deregistrationDate === null).length > 0) {
            view.$helpers.error(
              "Fehler",
              "Es ist bereits eine aktive Anmeldung vorhanden. Bitte melden Sie den Mitarbeiter zuerst ab."
            );
            return;
          } else if (requestType.type === 'deregistration'
            && view.registrations.filter(reg => reg.deregistrationDate === null).length === 0) {
            view.$helpers.error(
              "Fehler",
              "Es ist keine aktive Anmeldung vorhanden. Bitte melden Sie den Mitarbeiter zuerst an."
            );
            return;
          }

          view.eldaStateDataEntries = {};
          let tempEntries = [];
          Object.keys(view.requestFields[key]).forEach((field) => {
              let currentField = view.requestFields[key][field];
              let currentEntry = {key: field, label: currentField.label_de, type: currentField.type, required: currentField.required};

              let currentDate = this.$helpers.getMoment();
              let socialsecurity = view.employee.socialsecurity != null ? view.employee.socialsecurity : '0000';
              let svnumber = socialsecurity.trim().length == 4 ? socialsecurity + '' + view.$helpers.extractDateString(view.employee.dob, 'DDMMYY') : socialsecurity;

              // prepare fields per key
              switch (field) {
                // creator data
                case 'creatorId':
                  if (view.eldaCreator.length > 0) {
                    currentEntry.type = "dropdownsearch";
                    currentField.items = [];
                    view.eldaCreator.forEach(creator => {
                      currentField.items.push({label: creator.name, value: creator.id});
                    });
                    if(view.eldaCreator.length === 1) {
                      currentEntry.disabled = true;
                      currentEntry.selectedItem = currentField.items[0];
                      currentEntry.value = currentField.items[0].value;
                    }
                  } else {
                    currentEntry.disabled = true;
                    currentEntry.value = view.missingCreatorIdText;
                  }
                  break;
                // employer data
                case 'dienstgeberName':
                  currentEntry.hidden = view.eldaEmployerName !== '';
                  currentEntry.value = view.eldaEmployerName;
                  break;
                case 'dienstgeberEmail':
                  currentEntry.hidden = view.eldaEmployerEmail !== '';
                  currentEntry.value = view.eldaEmployerEmail;
                  break;
                case 'dienstgeberTelefonnr':
                  currentEntry.hidden = view.eldaEmployerPhoneNumber !== '';
                  currentEntry.value = view.eldaEmployerPhoneNumber;
                  break;
                case 'beitragskontonummer':
                  // text if no settings available
                  // hidden if exactly one is available from settings
                  // computed by selected insurer if more that one is available from settings
                  if (view.eldaEmployerInsurer.length === 1) {
                    currentEntry.hidden = true;
                    currentEntry.value = view.eldaEmployerInsurer[0]['contribution_accountnumber'];
                  } else if (view.eldaEmployerInsurer.length > 1) {
                    currentEntry.disabled = true;
                    currentEntry.computed = (datafill, lastVal, entryBeforeUpdate) => {
                      let insurer = datafill.entries.find(entry => entry.key === 'zustaendigerVersicherungstraeger');
                      if (insurer.value !== undefined) {
                        entryBeforeUpdate.value = view.eldaEmployerInsurer.find(entry => entry.insurer === insurer.value).contribution_accountnumber;
                      }
                      return entryBeforeUpdate;
                    }
                  }
                  break;
                case 'zustaendigerVersicherungstraeger':
                  // any insurer selectable if no settings available
                  // hidden if exactly one is available from settings
                  // dropdown minimized to insurer configured in settings if more than 1 is available
                  if (view.eldaEmployerInsurer.length === 1) {
                    currentEntry.hidden = true;
                    currentEntry.type = 'text';
                    currentEntry.value = view.eldaEmployerInsurer[0]['insurer'];
                  } else if (view.eldaEmployerInsurer.length > 1) {
                    let tempItems = [];
                    Object.values(view.eldaEmployerInsurer).forEach((insurer) => {
                      tempItems.push(insurer.insurer);
                    });
                    currentField.items = currentField.items.filter(i => tempItems.includes(i.value));
                  }
                  break;
                case 'projekt':
                    if(view.eldaCreator.length === 1) {
                      currentEntry.selectedItem = currentField.items.find(item => item.value === view.eldaCreator[0].defaultProjekt);
                      currentEntry.value = view.eldaCreator[0].defaultProjekt;
                    }
                  break;
                case 'geburtsdatum':
                  currentEntry.value = view.employee.dob;
                  break;
                case 'versicherungsnummer':
                  currentEntry.value = svnumber;
                  break;
                case 'vorname':
                  currentEntry.value = view.employee.firstName;
                  break;
                case 'familienname':
                  currentEntry.value = view.employee.lastName;
                  break;
                case 'wohnortLand':
                  currentEntry.selectedItem = view.getKFZCountryById(view.employee.countryId);
                  break;
                case 'wohnortPlz':
                  currentEntry.value = view.employee.postalCode;
                  break;
                case 'wohnortOrt':
                  currentEntry.value = view.employee.city;
                  break;
                case 'wohnortStrasse':
                  currentEntry.value = view.employee.address;
                  break;
                case 'geschlecht':
                  currentEntry.selectedItem = view.getGenderById(view.employee.genderId);
                  break;
                case 'staatsangehoerigkeit':
                  currentEntry.selectedItem = view.getISO3CountryById(view.employee.countryId);
                  break;
                case 'anmeldedatum':
                  let lastDeregDate = null;
                  currentEntry.value = currentDate;
                  if (view.registrations.length > 0) {
                    let lastReg = view.registrations.sort((a,b) => { return b.deregistrationTimestamp - a.deregistrationTimestamp})[0];
                    lastDeregDate = this.$helpers.getMomentFromString(lastReg.deregistrationDate, "YYYY-MM-DD").add(1, 'days');
                    currentEntry.value = lastDeregDate;
                  }
                  let employeeContractFromDate = null;
                  if (view.employeeContractFromDate !== undefined && view.employeeContractFromDate != null) {
                    employeeContractFromDate = view.employeeContractFromDate;
                    currentEntry.value = employeeContractFromDate;
                  }
                  if (lastDeregDate != null && employeeContractFromDate != null) {
                    currentEntry.value = lastDeregDate.isAfter(employeeContractFromDate) ? lastDeregDate : employeeContractFromDate;
                  }
                  break;
                case 'abmeldedatum':
                  let lastRegDate = null;
                  currentEntry.value = currentDate;
                  if (view.registrations.filter(regs => regs.deregistrationDate === null).length > 0) {
                    let lastReg = view.registrations.sort((a,b) => { return b.registrationTimestamp - a.registrationTimestamp})[0];
                    lastRegDate = this.$helpers.getMomentFromString(lastReg.registrationDate, "YYYY-MM-DD");
                    currentEntry.value = lastRegDate.isAfter(currentDate) ? lastRegDate : currentDate;
                  }
                  break;
                case 'betrieblicheVorsorgeAB':
                  if (view.employeeContractFromDate === undefined || view.employeeContractFromDate == null) {
                    view.employeeContractFromDate = currentDate;
                  }
                  if (view.employeeContragsCount === 1) {
                    let date = new Date(view.employeeContractFromDate);
                    let newDate = new Date(new Date(view.employeeContractFromDate).setMonth(date.getMonth() + 1));
                    // get last day of previous month if rollover happened
                    if (((date.getMonth() + 1) % 12) !== newDate.getMonth()) {
                      newDate = new Date(newDate.setDate(0));
                    }
                    currentEntry.value = newDate.toISOString();
                  } else {
                    currentEntry.value = view.employeeContractFromDate;
                  }
                  break;
              }

              // prepare fields per type
              switch (currentEntry.type) {
                case 'date':
                  if (currentEntry.value === undefined || currentEntry.value == null) {
                    currentEntry.value = currentDate;
                  }
                  break;
                case 'value':
                  currentEntry.value = currentField.value;
                  currentEntry.hidden = true;
                  break;
                case 'bool':
                  currentEntry.value = false;
                  break;
                case 'dropdownsearch':
                  currentEntry.selectMultiple = false;
                  currentEntry.dropdownSearchState = {
                    toggled: false,
                    displayedItems: [],
                    input: '',
                    items: currentField.items,
                    placeholder: currentEntry.label + ' auswählen',
                    selectedItem: currentEntry.selectedItem,
                    styleLight: true,
                  };
                  break;
              }

              tempEntries.push(currentEntry);
          });
          view.eldaStateDataEntries = tempEntries;

          view.requestTitle = requestType.label_de;
          view.currentRequest = requestType;
          view.creatingEldaRequestPhase = 2
        },
        getEldaState(){
          let view = this;

          let dataEntries = view.eldaStateDataEntries;

          return {
            entries: dataEntries,
            errorCheck: [],
            description: "",
            displayOnly: view.displayOnly,
            positiveButtonLabel: "Mitarbeitermeldung absenden",
            selectCallback: function (item) {
              view.displayOnly = true;
              console.log(view.currentRequest.type);

              let error = false;
              dataEntries.forEach((entry) => {
                if (entry.key === "creatorId" && entry.value === view.missingCreatorIdText) {
                  view.$helpers.error(
                    "Fehlende Daten",
                    "Bitte erstellen Sie einen Eintrag in Ihren Elda Hersteller Einstellungen."
                  );
                  error = true;
                }
                if (entry.required && (entry.value == undefined || entry.value == null || entry.value === '')) {
                  view.$helpers.error(
                    "Fehlende Daten",
                    "Bitte befüllen Sie das Feld " + entry.label
                  );
                  error = true;
                }
              });
              if (error) {
                view.resetOptionalDateFields(view.eldaStateDataEntries);
                view.displayOnly = false;
                return;
              }

              if (view.currentRequest.type === 'registration') {
                view.sendRegisterElda(item);
              } else if (view.currentRequest.type === 'deregistration') {
                view.sendDeregisterElda(item);
              }
            },
          };
        },
        loadEmployeeContracts() {
          let view = this;
          let url = '/api/sec/proxy/md/employee/contracts';

          url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/sec/proxy/md/employee/contracts');
          url = this.$helpers.appendParam("employeeId", view.employee.id, url, '/api/sec/proxy/md/employee/contracts');

          view.employeeContractFromDate = undefined;
          view.employeeContragsCount = 0;

          this.axios({
            method: 'get',
            headers: {
              'AUTHORIZATION': "Bearer "+this.$store.state.jwt
            },
            url: url
          }).then(response => {
            if (response.data !== undefined && response.data[0] !== undefined) {
              view.employeeContractFromDate = response.data[0].from;
              view.employeeContragsCount = response.data.length;
            }
          });
        },

        // Registration
        validateRegisterElda: function(reg){
          let view = this;
          let url = '/api/sec/elda/register/validate';
          url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/sec/elda/register/validate');

          return this.axios({
            method: 'post',
            headers: {
              'AUTHORIZATION': "Bearer "+this.$store.state.jwt
            },
            url: url,
            data: reg
          });
        },
        sendRegisterElda: function(reg){
          let view = this;
          let url = '/api/sec/elda/register';
          url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/sec/elda/register');

          view.register = reg.anmeldedatum.format("YYYY-MM-DD");
          reg.anmeldedatum = reg.anmeldedatum.format('DD.MM.YYYY');

          view.validateRegisterElda(reg)
            .then((response) => {
              view.sendRegister()
                .then((response) => {
                  reg.socialInsuranceRegistrationsId = response.data.id;

                  console.log(reg);

                  this.axios({
                    method: 'post',
                    headers: {
                      'AUTHORIZATION': "Bearer "+this.$store.state.jwt
                    },
                    url: url,
                    data: reg
                  }).then((response) => {
                    console.log(response);

                    view.$parent.creatingEldaRequest = false;

                    view.refresh();
                  }).catch(function (error){
                    view.resetOptionalDateFields(view.eldaStateDataEntries);
                    view.displayOnly = false;
                    console.log(error);
                  });

                })
                .catch((error) => {
                  console.log(error);
                  view.resetOptionalDateFields(view.eldaStateDataEntries);
                  view.displayOnly = false;
                  this.$helpers.error(
                    "Serverfehler",
                    "Bitte versuchen Sie die Anmeldung später erneut."
                  );
                });
            }).catch((error) => {
              console.log(error.response);
              view.resetOptionalDateFields(view.eldaStateDataEntries);
              view.displayOnly = false;
              // response has no data when there is an error on ML backend side
              if (error.response.data.data === undefined) {
                this.$helpers.error(
                  "Serverfehler",
                  "Bitte versuchen Sie die Anmeldung später erneut."
                );
              }
              try {
                // "cause" is an array for functional errors returned by elda
                error.response.data.data.cause.forEach((cause) => {
                  this.$helpers.error(
                    "Fehlerhafte Daten",
                    cause
                  );
                });
              } catch (e) {
                // catches errors in case "cause" was not an array (other errors like missing auth or other errors on elda service)
                this.$helpers.error(
                  "Fehlerhafte Daten",
                  error.response.data.data.cause
                );
              }
            });
        },

        // Deregistration
        validateDeregisterElda: function(dereg){
          let view = this;
          let url = '/api/sec/elda/deregister/validate';
          url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/sec/elda/deregister/validate');

          return this.axios({
            method: 'post',
            headers: {
              'AUTHORIZATION': "Bearer "+this.$store.state.jwt
            },
            url: url,
            data: dereg
          });
        },
        sendDeregisterElda: function(dereg){
          let view = this;
          let url = '/api/sec/elda/deregister';
          url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/sec/elda/deregister');

          view.deregister = dereg.abmeldedatum.format("YYYY-MM-DD");
          dereg.abmeldedatum = dereg.abmeldedatum.format('DD.MM.YYYY');

          view.validateDeregisterElda(dereg)
            .then((response) => {
              let currentOpenRegistration = view.registrations.find((reg) => reg.deregistrationDate === null);
              if (currentOpenRegistration === undefined) {
                this.$helpers.error(
                  "Fehler",
                  "Es ist keine aktive Anmeldung vorhanden. Bitte melden Sie den Mitarbeiter zuerst an."
                );
                return;
              }
              view.sendDeregister(currentOpenRegistration.id)
                .then((response) => {
                  console.log(response);
                  dereg.socialInsuranceRegistrationsId = response.data.id;

                  console.log(dereg);

                  this.axios({
                    method: 'post',
                    headers: {
                      'AUTHORIZATION': "Bearer "+this.$store.state.jwt
                    },
                    url: url,
                    data: dereg
                  }).then((response) => {
                    console.log(response);

                    view.$parent.creatingEldaRequest = false;

                    view.refresh();
                  }).catch(function (error){
                    view.resetOptionalDateFields(view.eldaStateDataEntries);
                    view.displayOnly = false;
                    console.log(error);
                  });

                })
                .catch((error) => {
                  console.log(error);
                  console.log(error.response);
                  view.resetOptionalDateFields(view.eldaStateDataEntries);
                  view.displayOnly = false;
                  this.$helpers.error(
                    "Serverfehler",
                    error.response.data
                  );
                });
            }).catch((error) => {
              console.log(error);
              console.log(error.response);
              view.resetOptionalDateFields(view.eldaStateDataEntries);
              view.displayOnly = false;
              // response has no data when there is an error on ML backend side
              if (error.response.data.data === undefined) {
                this.$helpers.error(
                  "Serverfehler",
                  "Bitte versuchen Sie die Abmeldung später erneut."
                );
              }
              try {
                // "cause" is an array for functional errors returned by elda
                error.response.data.data.cause.forEach((cause) => {
                  this.$helpers.error(
                    "Fehlerhafte Daten",
                    cause
                  );
                });
              } catch (e) {
                // catches errors in case "cause" was not an array (other errors like missing auth or other errors on elda service)
                this.$helpers.error(
                  "Fehlerhafte Daten",
                  error.response.data.data.cause
                );
              }
            });
        },

        // Request Status
        loadSocialInsuranceRegistrationEldaStatus: function(socialInsuranceRegistrationsId){
          let view = this;
          let url = '/api/sec/elda/request';

          url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/sec/elda/request');
          url = this.$helpers.appendParam("socialInsuranceRegistrationsId", socialInsuranceRegistrationsId, url, '/api/sec/elda/request');

          return this.axios({
            method: 'get',
            headers: {
              'AUTHORIZATION': "Bearer "+this.$store.state.jwt
            },
            url: url
          });
        },
        // remove for now: this was used to prepare the status in DB
        // sendSocialInsuranceRegistrationEldaStatus: function(socialInsuranceRegistrationsId){
        //   let view = this;
        //   let url = '/api/sec/elda/request';

        //   url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/sec/elda/request');

        //   let payload = {
        //     socialInsuranceRegistrationsId: socialInsuranceRegistrationsId,
        //   }

        //   return this.axios({
        //     method: 'post',
        //     headers: {
        //       'AUTHORIZATION': "Bearer "+this.$store.state.jwt
        //     },
        //     url: url,
        //     data: payload
        //   }).catch(function (error){
        //     console.log(error);
        //   });
        // },

        // Request Types & Fields
        loadEldaRequestTypes: function() {
          let view = this;
          let url = '/api/sec/elda/request/types';
          url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/sec/elda/request/types');

          this.axios({
            method: 'get',
            headers: {
              'AUTHORIZATION': "Bearer "+this.$store.state.jwt
            },
            url: url
          })
            .then((response) => {
                view.requestTypes = response.data;
                Object.keys(view.requestTypes).forEach((requestType) => {
                    view.loadEldaRequestFields(requestType)
                        .then((response) => {
                            view.requestFields[requestType] = response.data;
                        });
                });
                view.prepareEldaRequestTypes(response.data);
            });
        },
        prepareEldaRequestTypes(data) {
            let view = this;
            let requestTypes = {};

            let registrations = [];
            let registrationsCount = 0;
            let deregistrations = [];
            let deregistrationsCount = 0;
            let others = [];
            let othersCount = 0;

            Object.entries(data).forEach(([requestType, requestObject]) => {
                if(requestObject.type == "registration") {
                    registrations[requestType] = requestObject;
                    registrationsCount++;
                } else if(requestObject.type == "deregistration") {
                    deregistrations[requestType] = requestObject;
                    deregistrationsCount++;
                } else {
                    others[requestType] = requestObject;
                    othersCount++;
                }
            });
            if(registrationsCount > 0) {
                requestTypes["Anmeldungen"] = registrations;
            }
            if(deregistrationsCount > 0) {
                requestTypes["Abmeldungen"] = deregistrations;
            }
            if(othersCount > 0) {
                requestTypes["Andere Meldungen"] = others;
            }
            view.requestTypes = requestTypes;
        },
        loadEldaRequestFields: function(requestType) {
            let view = this;
            let url = '/api/sec/elda/request/fields';
            url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/sec/elda/request/fields');
            url = this.$helpers.appendParam("requestType", requestType, url, '/api/sec/elda/request/fields');

            return this.axios({
              method: 'get',
              headers: {
                'AUTHORIZATION': "Bearer "+this.$store.state.jwt
              },
              url: url
            });
        },
        proxyEldaRequestStatus: function(status) {
            console.log(status);
            let view = this;
            let url = '/api/sec/elda/proxy/request';
            url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/sec/elda/proxy/request');
            url = this.$helpers.appendParam("reqId", status.requestId, url, '/api/sec/elda/proxy/request');

            this.axios({
              method: 'get',
              headers: {
                'AUTHORIZATION': "Bearer "+this.$store.state.jwt
              },
              url: url
            })
            .then((response) => {
              view.$helpers.success("Erfolg", "Status wurde erfolgreich abgerufen.");
              if (response.data && response.data.data) {
                status.api_state = response.data.data.api_state;
                status.status = response.data.data.status;
                status.updated_at = response.data.data.updated_at;
                status.file = response.data.data.file;
              }
            })
            .catch((error) => {
              console.log(error);
              view.$helpers.error("Fehler", "Status konnte nicht abgerufen werden.");
            });
        },

        // Elda Settings
        loadEldaEmployerSettings: function () {
          let view = this;
          let url = "/api/sec/elda/settings/employer";
          const params = {
            companyId: this.$store.state.companyId,
          };
          this.axios({
            method: "get",
            headers: {
              AUTHORIZATION: "Bearer " + this.$store.state.jwt,
            },
            url: url,
            params,
          })
            .then((response) => {
              let data = response.data;

              console.log(data);

              if(data !== undefined) {
                view.eldaEmployerName = data.name;
                view.eldaEmployerPhoneNumber = data.phone_number;
                view.eldaEmployerEmail = data.email;
                view.eldaEmployerInsurer = data.insurer;
              } else {
                view.eldaEmployerName = '';
                view.eldaEmployerPhoneNumber = '';
                view.eldaEmployerEmail = '';
                view.eldaEmployerInsurer = [];
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        },
        loadEldaCreatorSettings: function () {
          let view = this;
          let url = "/api/sec/elda/settings/creator";
          const params = {
            companyId: this.$store.state.companyId,
          };
          this.axios({
            method: "get",
            headers: {
              AUTHORIZATION: "Bearer " + this.$store.state.jwt,
            },
            url: url,
            params,
          })
            .then((response) => {
              let data = response.data;

              console.log("eldaCreator", data);

              if(data !== undefined) {
                view.eldaCreator = data;
              } else {
                view.eldaCreator = [];
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        },

        // Elda Print Service
        printEldaDocument(reqId, order = "dmprotocol,protocol,employer,employee"){
          let view = this;
          let url = '/api/sec/elda/print';

          url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/sec/elda/print');
          url = this.$helpers.appendParam("reqId", reqId, url, '/api/sec/elda/print');
          url = this.$helpers.appendParam("order", order, url, '/api/sec/elda/print');

          return this.axios({
            method: 'get',
            headers: {
              'AUTHORIZATION': "Bearer "+this.$store.state.jwt
            },
            url: url
          });
        },

        // Helper
        eldaCallBack: function(e){
          console.log("RECEIVED EVENT EldaCallbackEvent:update");
          this.refresh();
        },
        openEldaDocument(row){
          this.openLoadingEldaDocumentModal();
          this.printEldaDocument(row.requestId)
            .then((response) => {
              let docUrl = 'data:application/pdf;base64,' + encodeURI(response.data.pdf);

              this.urltoFile('data:application/pdf;base64,' + encodeURI(response.data.pdf), 'socialinsurance.pdf','application/pdf')
                .then(function(file){
                  window.open(URL.createObjectURL(file), '_blank');
                });
              this.closeLoadingEldaDocumentModal();
            })
            .catch((error) => {
              this.closeLoadingEldaDocumentModal();
              console.log(error);
              this.$helpers.error("Fehler", "Dokument konnte nicht geöffnet werden.");
            });
        },
        getEldaStatus(status, api_state) {
            switch (api_state) {
                case "NEW": return "neu";
                case "RUNNING": return "in Bearbeitung";
                case "SENT": return "gesendet";
                case "FAILED": return "Service steht nicht zur Verfügung.";
                case "PROCESSED":
                default:
                    switch (status) {
                        case "uebernommen": return "Meldung übernommen";
                        case "nicht_uebernommen": return "Meldung nicht übernommen";
                        case "teilweise_uebernommen": return "Meldung teilweise übernommen";
                        default: return "Status unbekannt.";
                    }
            }
        },
        hasEldaRetryStatus(status, api_state) {
          if (["Service steht nicht zur Verfügung.", "Meldung nicht übernommen", "Status unbekannt."].includes(this.getEldaStatus(status, api_state))) {
            return true;
          }
          return false;
        },
        hasEldaCheckStatus(status, api_state) {
          if (api_state !== "PROCESSED") {
            return true;
          }
          return false;
        },
        isLatestEldaStatus(status_list, status) {
          if (status_list.length > 0) {
            return JSON.stringify(status_list[status_list.length - 1]) === JSON.stringify(status);
          }
          return false;
        },
        getGenderById(genderId) {
          let selected = {};
          this.genders.forEach(function (item, index) {
            if (item.value == genderId) {
              selected.value = item.value;
              selected.label = item.label;
            }
          });
          return selected;
        },
        getISO3CountryById(countryId) {
          let selected = {};
          this.countriesISO3.forEach(function (item, index) {
            if (item.id == countryId) {
              selected.id = item.id;
              selected.value = item.value;
              selected.label = item.label;
            }
          });
          return selected;
        },
        getKFZCountryById(countryId) {
          let selected = {};
          this.countriesKFZ.forEach(function (item, index) {
            if (item.id == countryId) {
              selected.id = item.id;
              selected.value = item.value;
              selected.label = item.label;
            }
          });
          return selected;
        },
        setCreatingEldaRequest(bool) {
            this.creatingEldaRequestPhase = 1;
            this.$parent.creatingEldaRequest = bool;
        },
        getCreatingEldaRequest() {
            return this.$parent.creatingEldaRequest;
        },
        resetOptionalDateFields(list) {
          list.forEach((entry) => {
            if (entry.type == 'date' && entry.required !== undefined && !entry.required && !entry.showDateField) {
              entry.value = this.$helpers.getMoment();
            }
          });
        },

        openLoadingEldaDocumentModal() {
          this.$refs["modal-loadingEldaDocument"].show();
        },
        closeLoadingEldaDocumentModal() {
          this.$refs["modal-loadingEldaDocument"].hide();
        },

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.dropdown-search.filters {
  height: 18px !important;
  margin-top: -4px;
  display: block;
}

.btn-success {
  color: #fff;
  background-color: var(--ml);
  border-color: var(--ml);
}
.btn-success:focus,
.btn-success.focus {
  color: #fff;
  background-color: var(--ml);
  border-color: var(--ml);
  box-shadow: 0 0 0 0.2rem rgba(162, 16, 57, 0.5);
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: var(--ml);
  border-color: rgba(162, 16, 57, 0.5);
}

.btn-outline-success {
  color: var(--ml);
  border-color: var(--ml);
}
.btn-outline-success:hover {
  color: #fff;
  background-color: var(--ml);
  border-color: var(--ml);
}
.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(162, 16, 57, 0.5);
}
.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: var(--ml);
  border-color: rgba(162, 16, 57, 0.5);
}

.elda-icon-paragraph {
  margin-bottom: 9px;
}

/*
.content *:not(.main-settings)>.half {
    height: 105px;
}
*/
</style>
