<template>
  <div class="main-view-padding has-header">
      <div class="header main" v-header-position-lock>
          <div class="align-left">
          </div>
          <div class="align-middle">
                <headerbuttonstack :state="headerButtons"/>
          </div>
          <div class="align-right">
          </div>
      </div>
    <div class="page-header">
      Kollektivverträge
    </div>

    <div v-if="loading">
      <i class="fas fa-circle-notch fa-spin"></i> Bitte warten<br />
      Die Kollektivverträge werden geladen<br />
      <div style="width: 100%; clear: both; border-bottom: 1px solid #ccc; margin-top: 25px"></div>
    </div>
    <div v-if="!loading" class ="tablecontainer scrollbar-container">
      <!-- LIST OF COLLECTIVE AGREEMENTS -->
      <div class="collective-agreement-list">
        <div class="collective-agreement list-header">
          Kollektivverträge
        </div>
        <div class="collective-agreement-list-overflow" style="overflow: auto">
          <div v-for="collectiveAgreementCountry,index in Object.entries(contractCollectiveAgreements).sort((a,b)=>Object.values(a)[0].countryId - Object.values(b)[0].countryId)" v-bind:key="'countryIndex_' + index" class="collective-agreement-country-container">
              <div class="collective-agreement-country-title" >
                {{ countryEnumMap[Object.values(collectiveAgreementCountry[1])[0].countryId].valueGER }}
              </div>
              <div v-for="collectiveAgreement in Object.values(collectiveAgreementCountry[1])" class="collective-agreement" v-on:click="selectedCollectiveAgreement=collectiveAgreement; setCurrentValidFromDate()"
                  v-bind:class="{'selected': selectedCollectiveAgreement.id===collectiveAgreement.id}" v-bind:key="collectiveAgreement.id">
                  {{ collectiveAgreement.name }}
              </div>
          </div>
        </div>
      </div>
      <!-- LIST OF COLLECTIVE AGREEMENTS SETTINGS -->
      <div class="collective-agreement-settings">
        <div class="collective-agreement-setting list-header">
          Einstellungen
        </div>
        <div class="collective-agreement-setting" v-on:click="selectedSettings='wageGroups'" v-bind:class="{'selected': selectedSettings==='wageGroups'}">
          Tarifgruppen
        </div>
        <div class="collective-agreement-setting" v-on:click="selectedSettings='wageTable'" v-bind:class="{'selected': selectedSettings==='wageTable'}">
          Lohntabelle
        </div>
        <div class="collective-agreement-setting" v-on:click="selectedSettings='update'" v-bind:class="{'selected': selectedSettings==='update'}">
          Kollektivvertrag bearbeiten
        </div>
      </div>
      <!-- ACTUAL SETTINGS -->
      <div class="collective-agreement-content-container">
        <div v-if="selectedSettings==='wageGroups'" class="collective-agreement-content">

          <div>
            <b-button
                style="width: 485px"
                @click="openCreateNewContractWageGroupPopup(selectedCollectiveAgreement)"
                v-tooltip="'neu anlegen'"
                squared
                variant="outline-success"
            >Neue Tarifgruppe anlegen</b-button>
          </div>
          <div>
            <b-button
                style="width: 485px"
                @click="openImportContractWageGroupPopup(selectedCollectiveAgreement)"
                v-tooltip="'neu anlegen'"
                squared
                variant="outline-success"
            >Tarifgruppen importieren</b-button>
          </div>
          <div style="padding-bottom:20px;">
            <b-button
                style="width: 485px"
                @click="openExportContractWageGroupPopup(selectedCollectiveAgreement)"
                v-tooltip="'neu anlegen'"
                squared
                variant="outline-success"
            >Tarifgruppen exportieren</b-button>
          </div>

          <div style="width: 100%;">
            <table style="width: 100%">
              <tr>
                <th>Tarifgruppe</th>
                <th>Bundesland</th>
                <th>Bearbeiten</th>
              </tr>
              <tr v-for="contractWageGroup in contractWageGroups[selectedCollectiveAgreement.id] ? contractWageGroups[selectedCollectiveAgreement.id].sort((a,b) => a.stateIds[0] - b.stateIds[0]) : []" v-bind:key="contractWageGroup.id">
                <td>{{ contractWageGroup.name }}</td>
                <td>{{ contractWageGroup.stateIds.map(stateId => countryStateEnumMapByCountry[selectedCollectiveAgreement.countryId].find(e => e.stateId == stateId).valueGER).join(', ') }}</td>
                <td>
                  <i class="fa fa-pen" style="cursor: pointer" v-on:click="openUpdateContractWageGroupPopup(contractWageGroup)" />
                  &nbsp;
                  <i class="fa fa-trash" style="cursor: pointer" v-on:click="openDeleteContractWageGroupPopup(contractWageGroup)" />
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div v-else-if="selectedSettings==='wageTable'" class="collective-agreement-content">
          <div class="collective-agreement-wagetable-container">
            <div class="collective-agreement-wagetable-date-list">
              <div class="collective-agreement-wagetable-date list-header">
                Gültig ab
              </div>
              <div class="collective-agreement-wagetable-date" v-on:click="openCreateNewTimeFramePopup()">
                neue Gültigkeitsperiode
              </div>
              <div v-for="validFromDate in getValidFromDates()" class="collective-agreement-wagetable-date" v-on:click="selectedValidFromDate=validFromDate"
                   v-bind:class="{'selected': selectedValidFromDate===validFromDate}" v-bind:key="validFromDate">
                {{ printValidFromDate(validFromDate) }}
              </div>
            </div>
            <div class="collective-agreement-wagetable-content">
              <table v-show="selectedValidFromDate != null" style="width: 100%">
                <tr>
                  <th>Tarifgruppe</th>
                  <th>Betrag</th>
                  <th>Zahlungstyp</th>
                  <th>Lohntyp</th>
                  <th>Bundesländer</th>
                  <th>Bearbeiten</th>
                </tr>
                <tr v-for="wg in getNormalizedWageGroupsByValidFromDate(selectedValidFromDate)" v-bind:key="wg.id + '_' + wg.amountId + '_' + wg.header + '_' + wg.empty">
                  <td v-if="wg.header && wg.empty" colspan="5">!! Noch kein Lohn für {{ wg.name }} ({{ wg.stateIds.map(stateId => countryStateEnumMapByCountry[selectedCollectiveAgreement.countryId].find(e => e.stateId == stateId).valueGER).join(', ') }}) hinterlegt !!</td>
                  <td v-if="wg.header && !wg.empty" colspan="5">
                    <div style="height: 20px"></div>
                    <!-- <b-button
                        style="width: calc(100% - 10px); margin: 0px 5px 20px 5px;"
                        @click="openCreateNewContractWageAmountPopup(wg.id, selectedValidFromDate)"
                        v-tooltip="'anlegen'"
                        squared
                        variant="outline-success"
                    >Neuen {{ wg.name }} Lohn anlegen</b-button> -->
                  </td>
                  <td v-if="!wg.header">{{ wg.name }}</td>
                  <td v-if="!wg.header">{{ wg.amount ? (wg.amount / 100.0).toFixed(2) : "-" }}</td>
                  <td v-if="!wg.header">{{ wg.paymentType ? paymentTypeArray[wg.paymentType].label : "-" }}</td>
                  <td v-if="!wg.header">{{ wg.wageType ? wageTypeArray[wg.wageType].label : "-" }}</td>
                  <td v-if="!wg.header">{{ wg.stateIds ? wg.stateIds.map(stateId => countryStateEnumMapByCountry[selectedCollectiveAgreement.countryId].find(e => e.stateId == stateId).valueGER).join(', ') : "-" }}</td>
                  <td v-if="!wg.header">
                    <i class="fa fa-trash" style="cursor: pointer" v-on:click="openDeleteWagePopup(wg)" />
                  </td>
                </tr>
                <tr>
                  <td colspan="5">
                    <b-button
                          style="width: calc(100% - 10px); margin: 0px 5px 5px 5px;"
                          @click="openCreateNewContractWageAmountPopup(selectedValidFromDate, 1)"
                          v-tooltip="'anlegen'"
                          squared
                          variant="outline-success"
                      >Neue Gehälter anlegen</b-button>
                  </td>
                </tr>
                <tr>
                  <td colspan="5">
                    <b-button
                          style="width: calc(100% - 10px); margin: 0px 5px 5px 5px;"
                          @click="openCreateNewContractWageAmountPopup(selectedValidFromDate, 2, 4)"
                          v-tooltip="'anlegen'"
                          squared
                          variant="outline-success"
                      >Neue Monatslöhne anlegen</b-button>
                  </td>
                </tr>
                <tr>
                  <td colspan="5">
                    <b-button
                          style="width: calc(100% - 10px); margin: 0px 5px 5px 5px;"
                          @click="openCreateNewContractWageAmountPopup(selectedValidFromDate, 2, 3)"
                          v-tooltip="'anlegen'"
                          squared
                          variant="outline-success"
                      >Neue Wochenlöhne anlegen</b-button>
                  </td>
                </tr>
                <tr>
                  <td colspan="5">
                    <b-button
                          style="width: calc(100% - 10px); margin: 0px 5px 5px 5px;"
                          @click="openCreateNewContractWageAmountPopup(selectedValidFromDate, 2, 2)"
                          v-tooltip="'anlegen'"
                          squared
                          variant="outline-success"
                      >Neue Tageslöhne anlegen</b-button>
                  </td>
                </tr>
                <tr>
                  <td colspan="5">
                    <b-button
                          style="width: calc(100% - 10px); margin: 0px 5px 5px 5px;"
                          @click="openCreateNewContractWageAmountPopup(selectedValidFromDate, 2, 1)"
                          v-tooltip="'anlegen'"
                          squared
                          variant="outline-success"
                      >Neue Stundenlöhne anlegen</b-button>
                  </td>
                </tr>
              </table>
              <div v-show="selectedValidFromDate == null">
                <div style="width: 100%; text-align: center; color: #222222">
                  Bitte legen Sie eine Gültigkeitsperiode an.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="selectedSettings==='update'" class="collective-agreement-content">
          <div style="width: 100%">
            <b-button
                style="width: 442px"
                @click="openUpdateContractCollectiveAgreementPopup(selectedCollectiveAgreement)"
                v-tooltip="'Kollektivvertrag bearbeiten'"
                squared
                variant="outline-success"
            >Kollektivvertrag bearbeiten</b-button>
          </div>
          <div style="width: 100%">
            <b-button
                style="width: 442px"
                @click="openDeleteContractCollectiveAgreementPopup(selectedCollectiveAgreement)"
                v-tooltip="'Kollektivvertrag löschen'"
                squared
                variant="outline-success"
            >Kollektivvertrag löschen</b-button>
          </div>
        </div>
      </div>
    </div>


  </div>

</template>

<script>
import { mapState, mapGetters } from 'vuex';
import button from '../uicomponents/button.vue';
export default {
 components: { button },
  name: 'superadminCollectiveAgreements',
  data () {
    return {
      loading: true,
      selectedCollectiveAgreement: null,
      selectedSettings: 'wageGroups',
      selectedValidFromDate: null,

      contractCollectiveAgreements: {},
      contractWageGroups: {},
      countryStateEnumMapByCountry: {},

      wageTypeArray: [],
      paymentTypeArray: []
    }
  },
  created: function () {
    //console.log("CREATED");
  },
  mounted: function () {
    if(this.$store.state.jwt != undefined && this.$store.state.companyId != undefined){
        this.refresh();
    }
  },
  computed: {
    ...mapGetters(['companyId', 'isSuperUser', 'uiDataMap', 'countryEnumMap', 'countryStateEnumMap']),
    dateDisplay: function() {
      return this.$helpers.getMomentTZ().format('YYYY-MM-DD');
    },
    headerButtons() {
        let view = this;
        let state = {
          buttons: [
            {
              type: "block",
              icon: "fa-refresh",
              click: () => {
                this.refresh();
              },
              show: true,
              disabled: false,
              tooltip: "Daten neu laden",
              title: "Daten neu laden"
            },
            {
              type: "block green",
              icon: "fa-plus",
              click: () => {
                this.openCreateNewContractCollectiveAgreementPopup();
              },
              show: true,
              disabled: false,
              tooltip: "Neuen Kollektivvertrag anlegen",
              title: "Neuen Kollektivvertrag anlegen"
            },
          ]
        };
        return state;
    },
  },
  watch: {
    companyId: function(val, oldVal) {
      this.refresh();
    },
    selectedCollectiveAgreement: function (newVal, oldVal) {
      // console.log("selectedCollectiveAgreement", newVal, oldVal);
    }
  },
  // methods that implement data logic.
  methods: {
    refresh: function(force = false){
      let view = this;
      view.loading = true;

      view.selectedSettings = 'wageGroups'

      view.setupUIDataMaps();
      view.contractCollectiveAgreements = {};
      view.contractWageGroups = {};

      let promises = [];
      promises.push(view.getContractCollectiveAgreements());
      promises.push(view.$helpers.GetCountryStateEnum());
      // promises.push(view.getContractWageGroups()); // this is not necessary anymore -> wage groups are loaded with collective agreements

      Promise.all(promises)
      .then((responses) => {
        view.setCurrentValidFromDate();

        view.countryStateEnumMapByCountry = {};
        Object.values(view.countryStateEnumMap).forEach(c => {
          if (!view.countryStateEnumMapByCountry.hasOwnProperty(c.countryId)) {
            view.countryStateEnumMapByCountry[c.countryId] = [];
          }
          view.countryStateEnumMapByCountry[c.countryId].push(c);
        });
        // console.log("countryStateEnumMapByCountry", view.countryStateEnumMapByCountry);
        view.loading = false;
      });
    },

    // helpers
    setupUIDataMaps() {
      this.uiDataMap.wageTypes.forEach(item => {
        item.label = item.label_de;
        item.value = item.id;
        this.wageTypeArray[item.id] = item;
      });
      this.uiDataMap.paymentTypes.forEach(item => {
        item.label = item.label_de;
        item.value = item.id;
        this.paymentTypeArray[item.id] = item;
      });
    },
    printValidFromDate(validFrom) {
      let date = new Date(validFrom);
      let year = new Intl.DateTimeFormat('de', { year: 'numeric' }).format(date);
      let month = new Intl.DateTimeFormat('de', { month: '2-digit' }).format(date);
      let day = new Intl.DateTimeFormat('de', { day: '2-digit' }).format(date);
      return `${day}.${month}.${year}`;
    },
    getNormalizedWageGroupsByValidFromDate(validFrom) {
      let view = this;
      let normalizedWageGroups = [];

      if (view.contractWageGroups[view.selectedCollectiveAgreement.id] == undefined) {
        return normalizedWageGroups;
      }

      view.contractWageGroups[view.selectedCollectiveAgreement.id]
        .sort((a,b) => a.stateIds[0] - b.stateIds[0])
        .forEach(wg => {
        let currentWageGroup = [];
        wg.wage_amounts.filter(wa => wa.validFrom === validFrom).forEach(wa => {
          let wageGroup = {};
          wageGroup.name = wg.name;
          wageGroup.id = wg.id;
          wageGroup.paymentType = wa.paymentType;
          wageGroup.wageType = wa.wageType;
          wageGroup.amount = wa.amount;
          wageGroup.amountId = wa.id;
          wageGroup.stateIds = wg.stateIds;
          currentWageGroup.push(wageGroup);
        });
        if (currentWageGroup.length == 0)
          currentWageGroup.push({name: wg.name, id: wg.id, stateIds: wg.stateIds, header: true, empty: true});
        currentWageGroup.push({name: wg.name, id: wg.id, header: true, empty: false});
        normalizedWageGroups = normalizedWageGroups.concat(currentWageGroup);
      });
      return normalizedWageGroups;
    },
    getValidFromDates() {
      let view = this;
      let validFromDates = [];

      // console.log(view.selectedCollectiveAgreement);

      if (view.contractWageGroups[view.selectedCollectiveAgreement.id] == undefined) {
        return validFromDates;
      }

      // get all from dates
      view.contractWageGroups[view.selectedCollectiveAgreement.id].forEach(wg => {
        wg.wage_amounts.forEach(wa => {
          if (!(validFromDates.includes(wa.validFrom))) {
            validFromDates.push(wa.validFrom.toString());
          }
        });
      });
      validFromDates = validFromDates.sort((a,b) => {
        return new Date(b) - new Date(a);
      });
      return validFromDates;
    },
    setCurrentValidFromDate() {
      let view = this;
      let validFromDates = view.getValidFromDates();
      if (validFromDates.length > 0) {
        view.selectedValidFromDate = validFromDates[0];
      } else {
        view.selectedValidFromDate = null;
      }
    },


    // collective agreements
    getContractCollectiveAgreements() {
      let view = this;

      let url = '/api/sec/contract/collectiveAgreement';
      const params = {
        companyId: this.$store.state.companyId,
      };

      return this.axios({
        method: 'get',
        headers: { 'AUTHORIZATION': "Bearer "+this.$store.state.jwt },
        url: url,
        params
      }).then((response) => {
        view.contractCollectiveAgreements = {};
        view.contractWageGroups = {};
        response.data.forEach(r => {
          if (!view.contractCollectiveAgreements.hasOwnProperty(r.countryId)) {
            view.contractCollectiveAgreements[r.countryId] = {};
          }
          view.contractCollectiveAgreements[r.countryId][r.id] = r;
          if (!view.selectedCollectiveAgreement) {
            view.selectedCollectiveAgreement = r;
          }
          view.contractWageGroups[r.id] = r.wage_groups;
        });
        // console.log(view.selectedCollectiveAgreement);
        // console.log(view.contractCollectiveAgreements);
      }).catch((error) => {
        console.log(error);
        view.$helpers.error("Fehler beim Laden der Kollektivverträge", "Die Kollektivverträge konnten nicht geladen werden. Bitte versuchen Sie es erneut.");
      });
    },
    storeContractCollectiveAgreement(collectiveAgreement) {
      let view = this;

      let url = '/api/sec/contract/collectiveAgreement';
      const params = {
        companyId: this.$store.state.companyId,
      };

      return this.axios({
        method: 'post',
        headers: { 'AUTHORIZATION': "Bearer "+this.$store.state.jwt },
        url: url,
        data: collectiveAgreement,
        params
      }).then((response) => {
        if (!view.contractCollectiveAgreements.hasOwnProperty(response.data.countryId)) {
          view.contractCollectiveAgreements[response.data.countryId] = {};
        }
        view.contractCollectiveAgreements[response.data.countryId][response.data.id] = response.data;
        view.selectedCollectiveAgreement = view.contractCollectiveAgreements[response.data.countryId][response.data.id];
        view.$forceUpdate();
        // view.refresh();
      }).catch((error) => {
        console.log(error);
        view.$helpers.error("Fehler beim Anlegen des Kollektivvertrags", "Der Kollektivvertrag konnten nicht angelegt werden. Bitte versuchen Sie es erneut.");
      });
    },
    updateContractCollectiveAgreement(updatedCollectiveAgreement) {
      let view = this;

      let url = '/api/sec/contract/collectiveAgreement/' + updatedCollectiveAgreement.id;
      const params = {
        companyId: this.$store.state.companyId,
      };

      return this.axios({
        method: 'put',
        headers: { 'AUTHORIZATION': "Bearer "+this.$store.state.jwt },
        url: url,
        data: updatedCollectiveAgreement,
        params
      }).then((response) => {
        let cca = view.contractCollectiveAgreements[updatedCollectiveAgreement.countryId].find(cca => cca.id === updatedCollectiveAgreement.id);
        cca.name = response.data.name;
      }).catch((error) => {
        console.log(error);
        view.$helpers.error("Fehler beim Speichern des Kollektivvertrags", "Der Kollektivvertrag konnten nicht gespeichert werden. Bitte versuchen Sie es erneut.");
      });
    },
    deleteContractCollectiveAgreement(collectiveAgreement) {
      let view = this;

      let url = '/api/sec/contract/collectiveAgreement/' + collectiveAgreement.id;
      const params = {
        companyId: this.$store.state.companyId,
      };

      return this.axios({
        method: 'delete',
        headers: { 'AUTHORIZATION': "Bearer "+this.$store.state.jwt },
        url: url,
        params
      }).then((response) => {
        // remove deleted contract from list
        let element = Object.values(view.contractCollectiveAgreements[collectiveAgreement.countryId]).find(cca => (cca && cca.id === collectiveAgreement.id));
        let index = Object.values(view.contractCollectiveAgreements[collectiveAgreement.countryId]).indexOf(element);
        Object.values(view.contractCollectiveAgreements[collectiveAgreement.countryId]).splice(index, 1);

        // select first contract
        if (view.contractCollectiveAgreements[collectiveAgreement.countryId].length > 0) {
          view.selectedCollectiveAgreement = Object.values(view.contractCollectiveAgreements[collectiveAgreement.countryId])[0];
        } else {
          view.selectedCollectiveAgreement = Object.values(Object.values(view.contractCollectiveAgreements)[0])[0];
          delete view.contractCollectiveAgreements[collectiveAgreement.countryId];
        }
        view.$forceUpdate();
      }).catch((error) => {
        console.log(error);
        view.$helpers.error("Fehler beim Löschen des Kollektivvertrags", "Der Kollektivvertrag konnten nicht gelöscht werden. Bitte versuchen Sie es erneut.");
      });
    },

    openCreateNewContractCollectiveAgreementPopup() {
      let view = this;

      Object.values(view.countryEnumMap).forEach(country => {
        country.value = country.id;
        country.label = country.valueGER;
      });

      let dataEntries = [
        {label: 'Name: ', key: 'name', hidden: false, type: 'text', value: ''},
        {label: 'Land: ', key: 'countryId', hidden: false,
          type: "dropdownsearch",
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: "",
            items: Object.values(view.countryEnumMap),
            selectMultiple: false,
            selectedItems: [],
            selectedItem: null,
            onceSelected: function ($event, item, searchbar) {},
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
        },
      ];
      this.$helpers.OpenPopup({
        elRelative: event.currentTarget,
        width: '500px',
        type: 'datafill',
        pointerDirection: 0,
        componentState: {
          entries: dataEntries,
          selectCallback: function (newCollectiveAgreement) {
            newCollectiveAgreement.name = newCollectiveAgreement.name.trim();
            view.storeContractCollectiveAgreement(newCollectiveAgreement);
          },
          errorCheck: {
            'name': (entries) => {
              if (entries.hasOwnProperty('name') && view.contractCollectiveAgreements[entries.countryId] && Object.values(view.contractCollectiveAgreements[entries.countryId]).find(f => f && f.name === entries.name.trim()))
                return {
                  key: 'name',
                  type: 'error',
                  text: 'Name wird in diesem Land bereits verwendet'
                }
              else return null
            }
          }
        },
        header: 'Neuer Kollektivvertrag',
        remainAfterSelect: false,
        expanded: true,
        centered: true,
      });
    },
    openUpdateContractCollectiveAgreementPopup(collectiveAgreement) {
      let view = this;

      Object.values(view.countryEnumMap).forEach(country => {
        country.value = country.id;
        country.label = country.valueGER;
      });

      let dataEntries = [
        {label: 'Name: ', key: 'name', hidden: false, type: 'text', value: collectiveAgreement.name},
        {label: 'Land: ', key: 'countryId', hidden: false,
          type: "dropdownsearch",
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: "",
            items: Object.values(view.countryEnumMap),
            selectMultiple: false,
            selectedItems: [],
            selectedItem: Object.values(view.countryEnumMap).find(c => c.id === collectiveAgreement.countryId),
            onceSelected: function ($event, item, searchbar) {},
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
        },
      ];
      this.$helpers.OpenPopup({
        elRelative: event.currentTarget,
        width: '500px',
        type: 'datafill',
        pointerDirection: 0,
        componentState: {
          entries: dataEntries,
          selectCallback: function (updatedCollectiveAgreement) {
            updatedCollectiveAgreement.name = updatedCollectiveAgreement.name.trim();
            view.updateContractCollectiveAgreement(updatedCollectiveAgreement);
          },
          errorCheck: {
            'name': (entries) => {
              if (entries.hasOwnProperty('name') && entries.name.trim() !== collectiveAgreement.name && view.contractCollectiveAgreements[entries.countryId] && Object.values(view.contractCollectiveAgreements[entries.countryId]).find(f => f && f.name === entries.name.trim()))
                return {
                  key: 'name',
                  type: 'error',
                  text: 'Name wird in diesem Land bereits verwendet'
                }
              else return null
            }
          }
        },
        header: 'Kollektivvertrag bearbeiten',
        remainAfterSelect: false,
        expanded: true,
        centered: true,
      });
    },
    openDeleteContractCollectiveAgreementPopup(collectiveAgreement) {
      let view = this;

      let dataEntries = [];

      let description = "Bitte stellen Sie sicher, dass der Kollektivvertrag wirklich gelöscht werden soll!<br>" +
          "Der Kollektivvertrag ist möglicherweise mit einem Kollektivvertag in MD verknüpft!";

      this.$helpers.OpenPopup({
        elRelative: event.currentTarget,
        width: '500px',
        type: 'datafill',
        pointerDirection: 0,
        componentState: {
          description: description,
          entries: dataEntries,
          selectCallback: function (updatedCollectiveAgreement) {
            view.deleteContractCollectiveAgreement(collectiveAgreement);
          },
          errorCheck: {
            'name': (entries) => {
              if (entries.hasOwnProperty('name') && entries.name.trim() !== collectiveAgreement.name && view.contractCollectiveAgreements[entries.countryId] && Object.values(view.contractCollectiveAgreements[entries.countryId]).find(f => f && f.name === entries.name.trim()))
                return {
                  key: 'name',
                  type: 'error',
                  text: 'Name wird in diesem Land bereits verwendet'
                }
              else return null
            }
          }
        },
        header: 'Kollektivvertrag unwiderruflich löschen?',
        remainAfterSelect: false,
        expanded: true,
        centered: true,
      });
    },

    // wage groups
    // getContractWageGroups() {
    //   let view = this;

    //   view.contractWageGroups = {};

    //   let url = '/api/sec/contract/wageGroup';
    //   const params = {
    //     companyId: this.$store.state.companyId,
    //   };

    //   return this.axios({
    //     method: 'get',
    //     headers: { 'AUTHORIZATION': "Bearer "+this.$store.state.jwt },
    //     url: url,
    //     params
    //   }).then((response) => {
    //     if (response.data && Array.isArray(response.data)) {
    //       response.data.forEach(r => {
    //         if (!view.contractWageGroups.hasOwnProperty(r.contractCollectiveAgreementId)) {
    //           view.contractWageGroups[r.contractCollectiveAgreementId] = [];
    //         }
    //         view.contractWageGroups[r.contractCollectiveAgreementId].push(r);
    //       });
    //     }
    //     console.log(view.contractWageGroups);
    //   }).catch((error) => {
    //     console.log(error);
    //     view.$helpers.error("Fehler beim Laden der Tarifgruppen", "Die Tarifgruppen konnten nicht geladen werden. Bitte versuchen Sie es erneut.");
    //   });
    // },
    storeContractWageGroups(newWageGroup) {
      let view = this;

      let url = '/api/sec/contract/wageGroup';
      const params = {
        companyId: this.$store.state.companyId,
      };
      return this.axios({
        method: 'post',
        headers: { 'AUTHORIZATION': "Bearer "+this.$store.state.jwt },
        url: url,
        data: newWageGroup,
        params
      }).then((response) => {
        if (!view.contractWageGroups.hasOwnProperty(newWageGroup.contractCollectiveAgreementId)) {
          view.contractWageGroups[newWageGroup.contractCollectiveAgreementId] = [];
        }
        view.contractWageGroups[newWageGroup.contractCollectiveAgreementId].push(response.data);
        view.$forceUpdate();
      }).catch((error) => {
        console.log(error);
        view.$helpers.error("Fehler beim Anlegen der Tarifgruppe", "Die Tarifgruppe konnte nicht angelegt werden. Bitte versuchen Sie es erneut.");
      });
    },
    updateContractWageGroups(changedWageGroup) {
      let view = this;

      let url = '/api/sec/contract/wageGroup/' + changedWageGroup.id;
      const params = {
        companyId: this.$store.state.companyId,
      };
      return this.axios({
        method: 'put',
        headers: { 'AUTHORIZATION': "Bearer "+this.$store.state.jwt },
        url: url,
        data: changedWageGroup,
        params
      }).then((response) => {
        let wg = Object.values(view.contractWageGroups[changedWageGroup.contractCollectiveAgreementId]).find(wg => wg.id === changedWageGroup.id);
        wg.stateIds = response.data.stateIds;
        wg.name = response.data.name;
        view.$forceUpdate();
      }).catch((error) => {
        console.log(error);
        view.$helpers.error("Fehler beim Speichern der Tarifgruppe", "Die Tarifgruppe konnte nicht gespeichert werden. Bitte versuchen Sie es erneut.");
      });
    },
    importContractWageGroups(importData) {
      let view = this;

      let url = '/api/sec/contract/wageGroup/import';
      const params = {
        companyId: this.$store.state.companyId,
      };
      return this.axios({
        method: 'post',
        headers: { 'AUTHORIZATION': "Bearer "+this.$store.state.jwt },
        url: url,
        data: {wageGroups: importData, contractCollectiveAgreementId: view.selectedCollectiveAgreement.id},
        params
      }).then((response) => {
        response.data.forEach(wg => {
          if (!view.contractWageGroups.hasOwnProperty(wg.contractCollectiveAgreementId)) {
            view.contractWageGroups[wg.contractCollectiveAgreementId] = [];
          }
          let existing = view.contractWageGroups[wg.contractCollectiveAgreementId].find(f => f.id === wg.id);
          if (!existing) {
            existing = wg;
            view.contractWageGroups[wg.contractCollectiveAgreementId].push(wg);
          }
          wg.wage_amounts.forEach(wa => {
            let existingWa = existing.wage_amounts.find(f => f.id === wa.id);
            if (!existingWa) {
              existing.wage_amounts.push(wa);
            } else {
              existingWa.amount = wa.amount;
            }
          });
        });
        view.$helpers.success("Tarifgruppen importiert", "Die Tarifgruppen wurden erfolgreich importiert.");
        view.$forceUpdate();
      }).catch((error) => {
        console.log(error);
        view.$helpers.error("Fehler beim Speichern der Tarifgruppe", "Die Tarifgruppe konnte nicht gespeichert werden. Bitte versuchen Sie es erneut.");
      });
    },
    deleteContractWageGroup(contractWageGroup) {
      let view = this;

      let url = '/api/sec/contract/wageGroup/' + contractWageGroup.id;
      const params = {
        companyId: this.$store.state.companyId,
      };
      return this.axios({
        method: 'delete',
        headers: { 'AUTHORIZATION': "Bearer "+this.$store.state.jwt },
        url: url,
        params
      }).then((response) => {
        let index = view.contractWageGroups[contractWageGroup.contractCollectiveAgreementId].indexOf(wg => wg.id === contractWageGroup.id);
        view.contractWageGroups[contractWageGroup.contractCollectiveAgreementId].splice(index, 1);
        view.$forceUpdate();
      }).catch((error) => {
        console.log(error);
        view.$helpers.error("Fehler beim Löschen der Tarifgruppe", "Die Tarifgruppe konnte nicht gelöscht werden. Bitte versuchen Sie es erneut.");
      });
    },
    openCreateNewContractWageGroupPopup(contractCollectiveAgreement) {
      let view = this;
      console.log(view.countryStateEnumMapByCountry);

      let items = [];
      if (view.countryStateEnumMapByCountry[contractCollectiveAgreement.countryId] == undefined) {
        items.push({'value': 1, 'label': 'Keine Bundesländer eingetragen', 'selected': false});
      } else {
        Object.values(view.countryStateEnumMapByCountry[contractCollectiveAgreement.countryId]).forEach((state) => {
          items.push({'value': state.stateId, 'label': state.valueGER, 'selected': false});
        });
      }

      let dataEntries = [
        {label: 'Name: ', key: 'name', hidden: false, type: 'text', value: ''},
        {label: 'Bundesland: ', key: 'stateIds', hidden: false,
          type: "dropdownsearch",
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: "",
            items: items,
            selectMultiple: true,
            onceSelected: function ($event, item, searchbar) {
              searchbar.$parent.entries.find((i) => i.key === "allStateIds").value = false;
            },
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let allStateIds = datafill.entries.find((i) => i.key === "allStateIds").value === true;

            items.forEach((state) => {
              if (allStateIds) {
                state.selected = true;
              }
            });
            return entryBeforeUpdate;
          },
        },
        {label: 'Alle Bundesländer: ', key: 'allStateIds', hidden: false, type: "bool", value: false},
        {label: 'Kollektivvertrag: ', key: 'contractCollectiveAgreementId', hidden: true, type: "text", value: contractCollectiveAgreement.id},
      ];
      this.$helpers.OpenPopup({
        elRelative: event.currentTarget,
        width: '500px',
        type: 'datafill',
        pointerDirection: 0,
        componentState: {
          entries: dataEntries,
          selectCallback: function (newWageGroup) {
            view.storeContractWageGroups(newWageGroup);
          },
          errorCheck: {
            'name': (entries) => {
              let set1 = new Set(entries.stateIds);
              if (entries.hasOwnProperty('name')
                && view.contractWageGroups[contractCollectiveAgreement.id]
                && Object.values(view.contractWageGroups[contractCollectiveAgreement.id])
                  .find(f => f.name === entries.name && f.stateIds.filter(item => set1.has(item)).length > 0))
                return {
                  key: 'name',
                  type: 'error',
                  text: 'Der gewählte Name existiert bereits für mindestens eines der ausgewählten Bundesländer'
                }
              else return null
            }
          }
        },
        header: 'Neue Tarifgruppe',
        remainAfterSelect: false,
        expanded: true,
        centered: true,
      });
    },
    openImportContractWageGroupPopup(contractCollectiveAgreement) {
      let view = this;

      let dataEntries = [
        {label: 'Import JSON: ', key: 'importString', hidden: false, type: 'text', value: ''},
        {label: 'Kollektivvertrag: ', key: 'contractCollectiveAgreementId', hidden: true, type: "text", value: contractCollectiveAgreement.id},
      ];
      this.$helpers.OpenPopup({
        elRelative: event.currentTarget,
        width: '500px',
        type: 'datafill',
        pointerDirection: 0,
        componentState: {
          entries: dataEntries,
          selectCallback: function (importEntries) {
            let importData = JSON.parse(importEntries.importString);
            let promises = [];
            importData.forEach(wg => {
              wg.contractCollectiveAgreementId = importEntries.contractCollectiveAgreementId;

              // let set1 = new Set(wg.stateIds);
              // if (view.contractWageGroups[contractCollectiveAgreement.id]
              //   && Object.values(view.contractWageGroups[contractCollectiveAgreement.id])
              //     .find(f => f.name === wg.name && f.stateIds.filter(item => set1.has(item)).length > 0)) {
              //   // wagegroup exists, skip
              //   console.log("skip");
              // }
              // else {
              //   promises.push(view.storeContractWageGroups(wg));
              // }
            });
            view.importContractWageGroups(importData);
            // console.log(importData);
            // Promise.all(promises).then(responses => {
            //   let wageAmounts = [];
            //   importData.forEach(wg => {
            //     let wageGroup = Object.values(view.contractWageGroups[contractCollectiveAgreement.id]).find(wg1 => wg1.name === wg.name && wg1.stateIds.includes(wg.stateIds[0]));
            //     if (wageGroup != undefined) {
            //       wg.id = wageGroup.id;
            //       wg.wage_amounts.forEach(wa => {
            //         wa.groupId = wg.id;
            //         let wageAmount = wageGroup.wage_amounts.find(wa1 => wa1.validFrom === wa.validFrom && wa1.paymentType === wa.paymentType && wa1.wageType === wa.wageType);
            //         console.log(wageAmount);
            //         if (wageAmount != undefined) {
            //           wa.id = wageAmount.id;
            //         }
            //         wageAmounts.push(wa);
            //       });
            //     }
            //   });
            //   view.storeOrUpdateWageAmountsBulk(wageAmounts);
            // });
          },
          errorCheck: {
          }
        },
        header: 'Tarifgruppen importieren',
        remainAfterSelect: false,
        expanded: true,
        centered: true,
      });
    },
    openExportContractWageGroupPopup(contractCollectiveAgreement) {
      let view = this;

      var cloned = JSON.parse(JSON.stringify(view.contractWageGroups[contractCollectiveAgreement.id]));
      cloned.forEach(c => {
        delete c.id;
        delete c.contractCollectiveAgreementId;
        delete c.created_at;
        delete c.updated_at;
        c.wage_amounts.forEach(wa => {
          delete wa.id;
          delete wa.groupId;
          delete wa.created_at;
          delete wa.updated_at;
        });
      });

      let dataEntries = [
        {label: 'Export JSON: ', key: 'exportString', hidden: false, type: 'text', value: JSON.stringify(cloned),
          copyButton: true,
          copyButtonTooltip: "Export String in die Zwischenablage kopieren",
        },
      ];
      this.$helpers.OpenPopup({
        elRelative: event.currentTarget,
        width: '500px',
        type: 'datafill',
        pointerDirection: 0,
        componentState: {
          entries: dataEntries,
          selectCallback: function (importEntries) {
          },
          errorCheck: {
          }
        },
        header: 'Tarifgruppen exportieren',
        remainAfterSelect: false,
        expanded: true,
        centered: true,
      });
    },
    openUpdateContractWageGroupPopup(oldWageGroup) {
      let view = this;

      let items = [];
      let all_selected = true;
      Object.values(view.countryStateEnumMapByCountry[view.selectedCollectiveAgreement.countryId]).forEach((state) => {
        let selected = Object.values(oldWageGroup.stateIds).includes(state.stateId);
        all_selected = all_selected && selected;
        items.push({'value': state.stateId, 'label': state.valueGER, 'selected': selected});
      });

      let dataEntries = [
        {label: 'ID: ', key: 'id', hidden: true, type: 'text', value: oldWageGroup.id},
        {label: 'Name: ', key: 'name', hidden: false, type: 'text', value: oldWageGroup.name},
        {label: 'Bundesland: ', key: 'stateIds', hidden: false,
          type: "dropdownsearch",
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: "",
            items: items,
            selectMultiple: true,
            onceSelected: function ($event, item, searchbar) {
              searchbar.$parent.entries.find((i) => i.key === "allStateIds").value = false;
            },
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let allStateIds = datafill.entries.find((i) => i.key === "allStateIds").value === true;

            items.forEach((state) => {
              if (allStateIds) {
                state.selected = true;
              }
            });
            return entryBeforeUpdate;
          },
        },
        {label: 'Alle Bundesländer: ', key: 'allStateIds', hidden: false, type: "bool", value: all_selected},
        {label: '', key: 'contractCollectiveAgreementId', hidden: true, type: 'text', value: oldWageGroup.contractCollectiveAgreementId},
      ];
      this.$helpers.OpenPopup({
        elRelative: event.currentTarget,
        width: '500px',
        type: 'datafill',
        pointerDirection: 0,
        componentState: {
          entries: dataEntries,
          selectCallback: function (changedWageGroup) {
            view.updateContractWageGroups(changedWageGroup);
          },
          errorCheck: {
            'name': (entries) => {
              let set1 = new Set(entries.stateIds);
              if (entries.hasOwnProperty('name')
                && Object.values(view.contractWageGroups[oldWageGroup.contractCollectiveAgreementId])
                  .find(f => (f.name === entries.name && f.stateIds.filter(item => set1.has(item)).length > 0 && f.id !== oldWageGroup.id)))
                return {
                  key: 'name',
                  type: 'error',
                  text: 'Der gewählte Name existiert bereits für mindestens eines der ausgewählten Bundesländer'
                }
              else return null
            }
          }
        },
        header: 'Tarifgruppe ändern',
        remainAfterSelect: false,
        expanded: true,
        centered: true,
      });
    },
    openDeleteContractWageGroupPopup(wageGroup) {
      let view = this;

      let description = "Soll die Tarifgruppe '" + wageGroup.name + "' wirklich gelöscht werden?<br>" +
          "Die Tarifgruppe ist möglicherweise mit einem Vertag in MD verknüpft!";

      this.$helpers.OpenPopup({
        elRelative: event.currentTarget,
        width: '500px',
        type: 'datafill',
        pointerDirection: 0,
        componentState: {
          entries: [],
          description: description,
          positiveButtonLabel: 'Löschen',
          selectCallback: function (form) {
            view.deleteContractWageGroup(wageGroup);
          },
          negativeButtonLabel: 'Abbrechen',
          deleteCallback: function (form) {
          }
        },
        header: 'Tarifgruppe löschen',
        remainAfterSelect: false,
        expanded: true,
        centered: true,
      });
    },

    // wage amounts
    storeWageAmount(newWageAmount) {
      let view = this;

      let url = '/api/sec/contract/wageGroup/wageAmount';
      const params = {
        companyId: this.$store.state.companyId,
      };
      return this.axios({
        method: 'post',
        headers: { 'AUTHORIZATION': "Bearer "+this.$store.state.jwt },
        url: url,
        data: newWageAmount,
        params
      }).then((response) => {
        view.contractWageGroups[view.selectedCollectiveAgreement.id].find(wg => wg.id === newWageAmount.groupId).wage_amounts.push(response.data);
        view.$forceUpdate();
      }).catch((error) => {
        console.log(error);
        view.$helpers.error("Fehler beim Speichern des Lohns", "Der Lohn konnte nicht gespeichert werden. Bitte versuchen Sie es erneut.");
      });
    },
    storeOrUpdateWageAmountsBulk(newWageAmounts) {
      let view = this;

      let url = '/api/sec/contract/wageGroup/wageAmount/bulk';
      const params = {
        companyId: this.$store.state.companyId,
      };
      return this.axios({
        method: 'post',
        headers: { 'AUTHORIZATION': "Bearer "+this.$store.state.jwt },
        url: url,
        data: {wageAmounts: newWageAmounts},
        params
      }).then((response) => {
        let validFrom = null;
        response.data.forEach(newWa => {
          validFrom = newWa.validFrom;
          let wageGroup = view.contractWageGroups[view.selectedCollectiveAgreement.id].find(wg => wg.id === newWa.groupId);
          let wageAmount = wageGroup.wage_amounts.find(wa => wa.id === newWa.id);
          if (wageAmount) {
            wageAmount.amount = newWa.amount;
          } else {
            wageGroup.wage_amounts.push(newWa);
          }
        });
        // console.log(view.getValidFromDates());
        // console.log(validFrom.toString());
        view.selectedValidFromDate = validFrom.toString();
        view.$forceUpdate();
      }).catch((error) => {
        console.log(error);
        view.$helpers.error("Fehler beim Speichern der Löhne", "Die Löhne konnten nicht gespeichert werden. Bitte versuchen Sie es erneut.");
      });
    },
    deleteWageAmount(wageGroup) {
      let view = this;

      let url = '/api/sec/contract/wageGroup/wageAmount/' + wageGroup.amountId;
      const params = {
        companyId: this.$store.state.companyId,
      };
      return this.axios({
        method: 'delete',
        headers: { 'AUTHORIZATION': "Bearer "+this.$store.state.jwt },
        url: url,
        params
      }).then((response) => {
        let wageAmounts = view.contractWageGroups[view.selectedCollectiveAgreement.id].find(wg => wg.id === wageGroup.id).wage_amounts;
        let index = wageAmounts.indexOf(wageAmounts.find(wa => wa.id === wageGroup.amountId));
        wageAmounts.splice(index, 1);
        let validFromDates = view.getValidFromDates();
        let currentValidFromDate = validFromDates.find(vfd => vfd === view.selectedValidFromDate);
        if (validFromDates.length === 0) {
          view.selectedValidFromDate = null;
        } else if (!currentValidFromDate) {
          view.selectedValidFromDate = validFromDates[0];
        }
        view.$forceUpdate();
      }).catch((error) => {
        console.log(error);
        view.$helpers.error("Fehler beim Löschen des Lohns", "Der Lohn konnte nicht gelöscht werden. Bitte versuchen Sie es erneut.");
      });
    },
    openCreateNewContractWageAmountPopup(selectedValidFromDate, paymentType, wageType = null) {
      let view = this;

      let stateIdItems = [{'value': 0, 'label': "Alle"}];
      Object.values(view.countryStateEnumMapByCountry[view.selectedCollectiveAgreement.countryId]).forEach((state) => {
        stateIdItems.push({'value': state.stateId, 'label': state.valueGER});
      });

      let dataEntries = [
        {label: 'Zahlungstyp: ', key: 'paymentType', hidden: false, type: "text", value: view.paymentTypeArray[paymentType].label, disabled: true},
        {label: 'Lohntyp: ', key: 'wageType', hidden: paymentType === 1, type: "text", value: wageType ? view.wageTypeArray[wageType].label : "", disabled: true},
        {label: 'Gültig ab: ', key: 'validFrom', hidden: false, type: 'date', value: selectedValidFromDate, disabled: true, hideDateText: false},
        {label: 'Bundesland filtern: ', key: 'stateId', hidden: false,
          type: "dropdownsearch",
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: "",
            items: stateIdItems,
            selectMultiple: false,
            selectedItems: [],
            selectedItem: stateIdItems[0],
            onceSelected: function ($event, item, searchbar) {},
            styleLight: true,
            placeholder: "Bitte auswählen...",
            value: 0,
          },
        },
      ];

      view.contractWageGroups[view.selectedCollectiveAgreement.id].forEach(wg => {
        dataEntries.push({label: wg.name, key: wg.id, hidden: false, type: "text", value: '', disabled: false,
        computed: (datafill, lastVal, entryBeforeUpdate) => {
            let stateId = datafill.entries.find((i) => i.key === "stateId").value;
            entryBeforeUpdate.hidden = stateId === undefined || stateId === 0 ? false : !wg.stateIds.includes(stateId);
            entryBeforeUpdate.value = entryBeforeUpdate.hidden ? '' : entryBeforeUpdate.value;
            return entryBeforeUpdate;
          },
        });
      });

      let errorCheck = {};
      view.contractWageGroups[view.selectedCollectiveAgreement.id].forEach(wg => {
        errorCheck[wg.id] = (entries) => {
          entries[wg.id] = entries[wg.id].replace(',', '.');
          if (entries.hasOwnProperty(wg.id) && isNaN(entries[wg.id])) {
            return {
              key: wg.id,
              type: 'error',
              text: view.contractWageGroups[view.selectedCollectiveAgreement.id].find(wgroup => wgroup.id === wg.id).name + ' muss einen gültigen Lohnwert enthalten'
            }
          }
          else return null
        }
      });

      this.$helpers.OpenPopup({
        elRelative: event.currentTarget,
        type: 'datafill',
        pointerDirection: 0,
        componentState: {
          entries: dataEntries,
          selectCallback: function (newWageAmounts) {
            newWageAmounts.paymentType = view.paymentTypeArray.find(pt => pt != null && pt.label === newWageAmounts.paymentType).value;
            if (newWageAmounts.paymentType === 1) {
              newWageAmounts.wageType = null;
            } else {
              newWageAmounts.wageType = view.wageTypeArray.find(wt => wt != null && wt.label === newWageAmounts.wageType).value;
            }

            newWageAmounts.validFrom = newWageAmounts.validFrom.startOf('day').toISOString();

            let newWageAmountsArray = [];
            view.contractWageGroups[view.selectedCollectiveAgreement.id].forEach(wg => {
              newWageAmounts[wg.id] = newWageAmounts[wg.id] != "" ? newWageAmounts[wg.id].trim() : "0";
              newWageAmounts[wg.id] = newWageAmounts[wg.id].replace(',', '.');
              newWageAmounts[wg.id] = newWageAmounts[wg.id] * 100;

              let newWageAmount = {
                groupId: wg.id,
                validFrom: newWageAmounts.validFrom,
                paymentType: newWageAmounts.paymentType,
                wageType: newWageAmounts.wageType,
                amount: newWageAmounts[wg.id]
              };
              if (newWageAmount.amount > 0) {
                newWageAmountsArray.push(newWageAmount);
              }
            });
            view.storeOrUpdateWageAmountsBulk(newWageAmountsArray);
          },
          errorCheck: errorCheck
        },
        header: 'Neue Zahlungsbeträge anlegen',
        remainAfterSelect: false,
        expanded: true,
        centered: true,
      });
    },
    openDeleteWagePopup(wg) {
      let view = this;

      let description = "Soll der Lohn wirklich gelöscht werden?<br>" +
          "Der Lohn wird möglicherweise bereits in MD genutzt!";

      this.$helpers.OpenPopup({
        elRelative: event.currentTarget,
        width: '500px',
        type: 'datafill',
        pointerDirection: 0,
        componentState: {
          entries: [],
          description: description,
          positiveButtonLabel: 'Löschen',
          selectCallback: function (form) {
            view.deleteWageAmount(wg);
          },
          negativeButtonLabel: 'Abbrechen',
          deleteCallback: function (form) {
          }
        },
        header: 'Lohn löschen',
        remainAfterSelect: false,
        expanded: true,
        centered: true,
      });
    },

    // add new collective agreement period
    openCreateNewTimeFramePopup() {
      let view = this;

      if (!view.contractWageGroups[view.selectedCollectiveAgreement.id] || view.contractWageGroups[view.selectedCollectiveAgreement.id].length === 0) {
        view.$helpers.error("Keine Tarifgruppen", "Es sind keine Tarifgruppen vorhanden. Bitte legen Sie zuerst eine Tarifgruppe an.");
        return;
      }

      let stateIdItems = [{'value': 0, 'label': "Alle"}];
      Object.values(view.countryStateEnumMapByCountry[view.selectedCollectiveAgreement.countryId]).forEach((state) => {
        stateIdItems.push({'value': state.stateId, 'label': state.valueGER});
      });
      let paymentTypeItems = [];
      view.paymentTypeArray.forEach((wt, index) => {
        paymentTypeItems.push({'value': index, 'label': wt.label});
      });
      let wageTypeItems = [];
      view.wageTypeArray.forEach((wt, index) => {
        wageTypeItems.push({'value': index, 'label': wt.label});
      });

      let dataEntries = [
        {label: 'Zahlungstyp: ', key: 'paymentType', hidden: false,
          type: "dropdownsearch",
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: "",
            items: paymentTypeItems,
            selectMultiple: false,
            selectedItems: [],
            selectedItem: null,
            onceSelected: function ($event, item, searchbar) {},
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
        },
        {label: 'Lohntyp: ', key: 'wageType', hidden: false,
          type: "dropdownsearch",
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: "",
            items: wageTypeItems,
            selectMultiple: false,
            selectedItems: [],
            selectedItem: null,
            onceSelected: function ($event, item, searchbar) {},
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let salary = datafill.entries.find((i) => i.key === "paymentType").value === 1;
            entryBeforeUpdate.hidden = salary;
            entryBeforeUpdate.value = salary ? 0 : 4;
            return entryBeforeUpdate;
          },
        },
        {label: 'Bundesland filtern: ', key: 'stateId', hidden: false,
          type: "dropdownsearch",
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: "",
            items: stateIdItems,
            selectMultiple: false,
            selectedItems: [],
            selectedItem: stateIdItems[0],
            onceSelected: function ($event, item, searchbar) {},
            styleLight: true,
            placeholder: "Bitte auswählen...",
            value: 0,
          },
        },
        {label: 'Gültig ab: ', key: 'validFrom', hidden: false, type: 'date', value: new Date(), hideDateText: false},
      ];

      view.contractWageGroups[view.selectedCollectiveAgreement.id].forEach(wg => {
        dataEntries.push({label: wg.name, key: wg.id, hidden: false, type: "text", value: '', disabled: false,
        computed: (datafill, lastVal, entryBeforeUpdate) => {
            let stateId = datafill.entries.find((i) => i.key === "stateId").value;
            entryBeforeUpdate.hidden = stateId === undefined || stateId === 0 ? false : !wg.stateIds.includes(stateId);
            entryBeforeUpdate.value = entryBeforeUpdate.hidden ? '' : entryBeforeUpdate.value;
            return entryBeforeUpdate;
          },
        });
      });

      let errorCheck = {};
      view.contractWageGroups[view.selectedCollectiveAgreement.id].forEach(wg => {
        errorCheck[wg.id] = (entries) => {
          entries[wg.id] = entries[wg.id].replace(',', '.');
          if (entries.hasOwnProperty(wg.id) && isNaN(entries[wg.id])) {
            return {
              key: wg.id,
              type: 'error',
              text: view.contractWageGroups[view.selectedCollectiveAgreement.id].find(wgroup => wgroup.id === wg.id).name + ' muss einen gültigen Lohnwert enthalten'
            }
          }
          else return null
        }
      });
      errorCheck['validFrom'] = (entries) => {
        let validFromDates = view.getValidFromDates().map(vfd => new Date(vfd).toISOString());
        let validFrom = entries.validFrom.startOf('day').toISOString();

        if (validFromDates.includes(validFrom)) {
          return {
            key: 'validFrom',
            type: 'error',
            text: 'Gültigkeitsdatum bereits vorhanden'
          }
        }
        else return null
      }

      this.$helpers.OpenPopup({
        elRelative: event.currentTarget,
        type: 'datafill',
        pointerDirection: 0,
        componentState: {
          entries: dataEntries,
          selectCallback: function (newWageAmounts) {
            if (newWageAmounts.paymentType === 1) {
              newWageAmounts.wageType = null;
            }
            newWageAmounts.validFrom = newWageAmounts.validFrom.startOf('day').toISOString();

            let newWageAmountsArray = [];
            view.contractWageGroups[view.selectedCollectiveAgreement.id].forEach(wg => {
              newWageAmounts[wg.id] = newWageAmounts[wg.id] != "" ? newWageAmounts[wg.id].trim() : "0";
              newWageAmounts[wg.id] = newWageAmounts[wg.id].replace(',', '.');
              newWageAmounts[wg.id] = newWageAmounts[wg.id] * 100;

              let newWageAmount = {
                groupId: wg.id,
                validFrom: newWageAmounts.validFrom,
                paymentType: newWageAmounts.paymentType,
                wageType: newWageAmounts.wageType,
                amount: newWageAmounts[wg.id]
              };
              if (newWageAmount.amount > 0) {
                newWageAmountsArray.push(newWageAmount);
              }
            });
            view.storeOrUpdateWageAmountsBulk(newWageAmountsArray);
          },
          errorCheck: errorCheck
        },
        header: 'Neue Gültigkeitsperiode anlegen',
        remainAfterSelect: false,
        expanded: true,
        centered: true,
      });
    },

  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.main-view-padding {
  padding-left: 0px;
}

.page-header {
  font-size: 32px;
  text-align: left;
  color: #e51550;
  border-bottom:1px solid #ccc;
  font-weight:400;
  padding: 5px 5px 5px 20px;
}

.has-header > .scrollbar-container{
  position: initial;
  height: calc(100% - var(--header-height) - 20px) !important;
  top: var(--header-height);
  overflow-x: hidden;
}

.tablecontainer {
  display: flex;
}

.list-header {
  font-weight:400;
  padding: 5px 5px 5px 20px;
  border-bottom: thick solid var(--bg);
}

.collective-agreement-list {
  height: 100%;
  width: 250px;
  text-align: left;
}
.collective-agreement-list > .collective-agreement,
.collective-agreement-list > .collective-agreement-list-overflow > .collective-agreement-country-container > .collective-agreement{
  min-height: 40px;
  width: 100%;
  padding-left: 20px;
  display: flex;
  align-items: center;
  background: var(--contrast-2);
}
.collective-agreement-list > .collective-agreement:not(.list-header):hover,
.collective-agreement-list > .collective-agreement-list-overflow > .collective-agreement-country-container > .collective-agreement:not(.list-header):hover,
.selected {
  color: var(--bg);
  background: var(--ml) !important;
}
.collective-agreement-list > .collective-agreement:nth-child(odd),
.collective-agreement-list > .collective-agreement-list-overflow > .collective-agreement-country-container > .collective-agreement:nth-child(odd){
  background: var(--contrast-1);
}
.collective-agreement-list-overflow {
  overflow: auto;
  height: calc(100% - 40px);
}
.collective-agreement-country-title {
  font-weight: bold;
  padding-left: 10px;
  /* padding-top: 20px; */
  /* background: var(--contrast-1); */
}
.collective-agreement-settings {
  height: 100%;
  width: 250px;
  text-align: left;
}
.collective-agreement-settings > .collective-agreement-setting {
  min-height: 40px;
  width: 100%;
  padding-left: 20px;
  display: flex;
  align-items: center;
  background: var(--contrast-2);
}
.collective-agreement-settings > .collective-agreement-setting:not(.list-header):hover,.selected {
  color: var(--bg);
  background: var(--ml) !important;
}
.collective-agreement-settings > .collective-agreement-setting:nth-child(even){
  background: var(--contrast-1);
}

.collective-agreement-wagetable-container {
  height: 100%;
  display: flex;
}
.collective-agreement-wagetable-content {
  width: calc(100% - 250px);
  overflow: auto;
}
.collective-agreement-wagetable-date-list {
  height: 100%;
  width: 250px;
  text-align: left;
}
.collective-agreement-wagetable-date-list > .collective-agreement-wagetable-date {
  min-height: 40px;
  width: 100%;
  padding-left: 20px;
  display: flex;
  align-items: center;
  background: var(--contrast-2);
}
.collective-agreement-wagetable-date-list > .collective-agreement-wagetable-date:not(.list-header):hover,.selected {
  color: var(--bg);
  background: var(--ml) !important;
}
.collective-agreement-wagetable-date-list > .collective-agreement-wagetable-date:nth-child(odd){
  background: var(--contrast-1);
}

.collective-agreement-content-container {
  height: 100%;
  width: calc(100% - 500px);
  overflow: auto;
}
.collective-agreement-content {
  height: 100%;
}


.btn-outline-success {
  color: var(--ml);
  border-color: var(--ml);
  margin-bottom: 2px;
  margin-right: 2px;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: var(--ml);
  border-color: var(--ml);
}
.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(162, 16, 57, 0.5);
}

</style>
